import { useState } from 'react';
import useUpdateEffect from './useUpdateEffect';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

const useDebouncedState = (initValue, optionalDelay) => {
    const [value, setValue] = useState(initValue);
    const [debouncedValue, setDebouncedValue] = useState(initValue);

    const delay = optionalDelay || DEFAULT_DEBOUNCE_TIMEOUT;

    useUpdateEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [value, delay]);

    return [value, setValue, debouncedValue];
};

export default useDebouncedState;
