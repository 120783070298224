export const productConstants = {
    GET: 'GET_PRODUCTS',
    ADD: 'ADD_PRODUCT',
    DELETE: 'DELETE_PRODUCT',
    EDIT: 'EDIT_PRODUCT',
    GET_CATEGORIES: 'GET_PRODUCT_CATEGORIES',
    LOADING_CATEGORIES: 'LOADING_PRODUCT_CATEGORIES',
    PATCH_SCHEMA: 'PATCH_SCHEMA',
    GET_ARCHIVE: 'GET_ARCHIVE_PRODUCTS',
};
