import React, { Component } from 'react';
import Select from 'react-select';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TodayIcon from '@material-ui/icons/Today';
import HistoryIcon from '@material-ui/icons/History';
import Divider from '@material-ui/core/Divider';
import { Button } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import RequiredFieldIndicator from '../RequiredFieldIndicator';
import '../../Styles/ControlPanel.css';
import { getClientFromOrder, haveRole } from '../../Utils/commons';
import { ROLES } from '../../Constants/roles';

Moment.locale('pl');

Moment.locale('pl', {
    months: 'Styczeń.Luty.Marzec.Kwiecień.Maj.Czerwiec.Lipiec.Sierpień.Wrzesień.Październik.Listopad.Grudzień'.split('.'),
    monthsShort: 'STY,LUT,MAR,KWI,MAJ,CZE,LIP,SIE,WRZ,PAŹ,LIS,GRU'.split(','),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'Pn.Wt.Śr.Czw.Pt.Sb.Nd'.split('.'),
    weekdaysMin: 'Nd.Pn.Wt.Śr.Czw.Pt.Sb'.split('.'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
    },
});
momentLocalizer();

class ReservationPanel extends Component {
    render() {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const tooLateForChanges = haveRole(this.props.user, ROLES.ADMIN)
            ? false
            : this.props.fromDate
            ? this.props.fromDate < yesterday
            : false;

        return (
            <div className='panel-div'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        fromDate: this.props.fromDate,
                        toDate: this.props.toDate,
                        assignedOrders: this.props.assignedOrders,
                        assignedWorkers: this.props.assignedWorkers,
                        assignedTruck: this.props.assignedTruck,
                        assignedTrailer: this.props.assignedTrailer,
                        description: this.props.description,
                    }}
                    validationSchema={Yup.object().shape({
                        fromDate: Yup.mixed().required('To pole jest wymagane'),
                        toDate: Yup.mixed().required('To pole jest wymagane'),
                        assignedOrders: Yup.mixed(),
                        assignedWorkers: Yup.mixed(),
                        assignedTruck: Yup.mixed(),
                        assignedTrailer: Yup.mixed(),
                        description: Yup.mixed(),
                    })}
                    onSubmit={(fields, { resetForm }) => {
                        if (this.props.isRowClicked) {
                            this.props.handleEditClick(fields);
                        } else {
                            this.props.handleSubmit(fields);
                        }
                        resetForm();
                    }}
                    render={({ errors, status, touched, fields, values, setFieldValue, resetForm }) => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <button
                                    className={`btn ${this.props.showCalendar ? 'btn-secondary' : 'btn-primary'}`}
                                    type='button'
                                    style={{ flex: 1, marginRight: '6%' }}
                                    onClick={this.props.toggleCalendar}
                                >
                                    <TodayIcon />
                                </button>
                                <button
                                    className={`btn ${this.props.showArchiveTasks ? 'btn-secondary' : 'btn-primary'}`}
                                    type='button'
                                    style={{ flex: 1, marginRight: '6%' }}
                                    onClick={this.props.fetchArchiveTasks}
                                >
                                    <HistoryIcon />
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    style={{ flex: 1 }}
                                    onClick={e => this.props.handleDownloadClick(e)}
                                >
                                    <CloudDownloadIcon />
                                </button>
                            </div>
                            <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
                            <Form>
                                <div className='form-group'>
                                    <label htmlFor='fromDate'>Data od</label>
                                    <RequiredFieldIndicator />

                                    <DateTimePicker
                                        name='fromDate'
                                        value={values['fromDate']}
                                        messages={{ dateButton: 'Wybierz datę' }}
                                        culture='pl'
                                        placeholder='Wybierz datę'
                                        time={false}
                                        onChange={e => {
                                            if (e > values['toDate']) {
                                                this.props.handleToDateChange(e);
                                                setFieldValue('toDate', e);
                                            }
                                            setFieldValue('fromDate', e);
                                            this.props.handleFromDateChange(e);
                                            this.props.getAvailableResources(e, values['toDate']);
                                        }}
                                        min={today}
                                        disabled={tooLateForChanges}
                                        inputProps={{
                                            component: props => <input {...props} readOnly />,
                                        }}
                                    />
                                    <ErrorMessage name='fromDate' component='div' style={{ color: 'red', fontSize: '80%' }} />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='toDate'>Data do</label>
                                    <RequiredFieldIndicator />

                                    <DateTimePicker
                                        name='toDate'
                                        value={values['toDate'] ? values['toDate'] : values['fromDate']}
                                        culture='pl'
                                        messages={{ dateButton: 'Wybierz datę' }}
                                        placeholder='Wybierz datę'
                                        time={false}
                                        disabled={tooLateForChanges}
                                        onChange={e => {
                                            setFieldValue('toDate', e);
                                            this.props.handleToDateChange(e);
                                            this.props.getAvailableResources(values['fromDate'], e);
                                        }}
                                        min={values['fromDate'] ? values['fromDate'] : today}
                                        inputProps={{
                                            component: props => <input {...props} readOnly />,
                                        }}
                                    />
                                    <ErrorMessage name='toDate' component='div' style={{ color: 'red', fontSize: '80%' }} />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='assignedOrders'>Zamówienia</label>
                                    <Select
                                        isSearchable={true}
                                        value={values['assignedOrders']}
                                        isMulti={true}
                                        noOptionsMessage={() => 'Brak zamówień'}
                                        onChange={e => {
                                            setFieldValue('assignedOrders', e);
                                            this.props.handlePickOrders(e);
                                        }}
                                        isDisabled={tooLateForChanges}
                                        placeholder='Wybierz zamówienia'
                                        options={this.props.availableOrders.map(order => {
                                            const client = getClientFromOrder(order);
                                            return {
                                                value: order.id,
                                                label: client + ' - ' + order.description,
                                            };
                                        })}
                                    />
                                </div>

                                {this.props.isRowClicked && (
                                    <div className='form-group'>
                                        <Button
                                            variant={this.props.showPackageModal ? 'secondary' : 'primary'}
                                            style={{ width: '100%' }}
                                            onClick={this.props.handleTogglePackageModal}
                                            disabled={tooLateForChanges}
                                        >
                                            Zarządzanie paczkami
                                        </Button>
                                    </div>
                                )}

                                <div className='form-group'>
                                    <label htmlFor='assignedWorkers'>Pracownicy</label>
                                    <Select
                                        isSearchable={true}
                                        value={values['assignedWorkers']}
                                        isMulti={true}
                                        onChange={e => {
                                            setFieldValue('assignedWorkers', e);
                                            this.props.handlePickWorkers(e);
                                        }}
                                        isDisabled={!this.props.enableComboboxes || tooLateForChanges}
                                        placeholder='Wybierz pracowników'
                                        noOptionsMessage={() => 'Brak pracowników'}
                                        options={this.props.availableWorkers.map(worker => ({
                                            value: worker.id,
                                            label: worker.name.concat(' ', worker.surname),
                                        }))}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='assignedTruck'>Pojazd</label>

                                    <Select
                                        name='assignedTruck'
                                        value={values['assignedTruck']}
                                        onChange={e => {
                                            setFieldValue('assignedTruck', e);
                                            this.props.handlePickTruck(e);
                                        }}
                                        placeholder='Wybierz pojazd'
                                        noOptionsMessage={() => 'Brak pojazdów'}
                                        isDisabled={!this.props.enableComboboxes || tooLateForChanges}
                                        isSearchable={true}
                                        options={this.props.availableTrucks.map(truck => ({
                                            value: truck.id,
                                            label: truck.registrationNumber.concat(' - ', truck.model),
                                        }))}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='assignedTrailer'>Przyczepa</label>

                                    <Select
                                        name='assignedTrailer'
                                        value={values['assignedTrailer']}
                                        onChange={e => {
                                            setFieldValue('assignedTrailer', e);
                                            this.props.handlePickTrailer(e);
                                        }}
                                        placeholder='Wybierz przyczepę'
                                        noOptionsMessage={() => 'Brak przyczep'}
                                        isDisabled={!this.props.enableComboboxes || tooLateForChanges}
                                        isSearchable={true}
                                        options={this.props.availableTrailers.map(trailer => ({
                                            value: trailer.id,
                                            label: trailer.registrationNumber.concat(' - ', trailer.description),
                                        }))}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='description'>Opis</label>
                                    <Field
                                        name='description'
                                        placeholder='Wprowadź opis'
                                        value={values['description']}
                                        onChange={e => {
                                            setFieldValue('description', e.target.value);
                                            this.props.handleDescriptionChange(e);
                                        }}
                                        type='text'
                                        component='textarea'
                                        rows='3'
                                        disabled={tooLateForChanges}
                                        style={{ resize: 'none' }}
                                        className='form-control'
                                    />
                                </div>
                                {!this.props.isRowClicked && (
                                    <div style={{ marginBottom: '10px' }}>
                                        <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                            Dodaj
                                        </button>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                this.props.handleCancelClick();
                                            }}
                                            className='btn btn-secondary'
                                            style={{ width: '47%' }}
                                        >
                                            Wyczyść
                                        </button>
                                    </div>
                                )}
                                {this.props.isRowClicked && (
                                    <div style={{ marginBottom: '10px' }}>
                                        <button
                                            type='submit'
                                            className='btn btn-primary'
                                            disabled={tooLateForChanges || this.props.showPackageModal}
                                            style={{ marginRight: '5%', width: '30%' }}
                                        >
                                            Zapisz
                                        </button>
                                        <Button
                                            onClick={() => this.props.handleCancelClick()}
                                            variant='secondary'
                                            style={{ marginRight: '5%', width: '30%' }}
                                            disabled={this.props.showPackageModal}
                                        >
                                            Anuluj
                                        </Button>
                                        <Button
                                            onClick={this.props.handleDeleteClick}
                                            variant='danger'
                                            style={{ width: '30%' }}
                                            disabled={tooLateForChanges || this.props.showPackageModal}
                                        >
                                            Usuń
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        </>
                    )}
                />
            </div>
        );
    }
}

export default ReservationPanel;
