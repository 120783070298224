import { css, keyframes } from 'styled-components';

export const productItemDisabledStyles = css`
    opacity: 0.5;
    pointer-events: none;
`;

export const colorShift = keyframes`
    0% {
      background-color: white;
    }
    100% {
      background-color: peachpuff;
    }
`;

export const productItemHighlightedStyles = css`
    animation: ${colorShift} 0.5s infinite linear alternate;
`;
