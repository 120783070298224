import triangle from './icons/triangle.svg';
import circle from './icons/circle.svg';
import hexagon from './icons/hexagon.svg';
import star from './icons/star.svg';
import rectangle from './icons/rectangle.svg';

export const categorySymbols = {
    NOZZLE: triangle,
    LAMP: circle,
    OUTFLOW: hexagon,
    COUNTERFLOW: star,
    SKIMMER: rectangle,
};

export const categoryPriority = {
    NOZZLE: 0,
    LAMP: 1,
    SKIMMER: 3,
    COUNTERFLOW: 4,
    OUTFLOW: 5,
    POOL_OTHER: 6,
};

export const getIconOffset = event => {
    const { clientX, clientY } = event;

    const { x: iconX, y: iconY } = event.target.getBoundingClientRect();

    const iconOffsetX = clientX - iconX;
    const iconOffsetY = clientY - iconY;

    return [iconOffsetX, iconOffsetY];
};
