import axios from 'axios';
import { clientConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';

export const getClients = archive => async dispatch => {
    try {
        const url = archive ? `${API.client}?active=false` : API.client;
        const { data } = await axios.get(url);
        const type = archive ? clientConstants.GET_ARCHIVE : clientConstants.GET;
        return dispatch({
            type: type,
            payload: data.sort((a, b) =>
                a.name.localeCompare(b.name) === 0 ? a.shortName.localeCompare(b.shortName) : a.name.localeCompare(b.name)
            ),
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const addClient = (client, refresh) => dispatch => {
    axios
        .post(API.client, client)
        .then(_ => {
            refresh();
            NotificationManager.success('Odbiorca został dodany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteClient = (client, refresh, literal) => dispatch => {
    axios
        .patch(API.toggleClient, client)
        .then(_ => {
            refresh();
            NotificationManager.success(`Odbiorca został ${literal}`);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editClient = (client, refresh) => dispatch => {
    axios
        .patch(API.client, client)
        .then(_ => {
            refresh();
            NotificationManager.success('Odbiorca został zapisany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
