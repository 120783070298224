import { clientConstants } from '../../Constants';

const initialState = {
    clients: [],
    archiveClients: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case clientConstants.GET:
            return {
                ...state,
                clients: action.payload,
            };
        case clientConstants.GET_ARCHIVE:
            return {
                ...state,
                archiveClients: action.payload,
            };
        default:
            return state;
    }
}
