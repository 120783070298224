import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';

import { toRem } from '../../../Styles/theme';
import { HeaderWrapper } from '../styles';
import { FormItem, FieldsRow, VerticalDivider, NoBuilder } from './styles';
import PoolBuilder from '../PoolBuilder/pool-builder';
import FilterBuilder from '../FilterBuilder/filter-builder';
import { getProductProviders } from '../PoolBuilder/pool-builder.utils';
import { NotificationManager } from 'react-notifications';

const emptyAddon = { product: -1, provider: -1, providers: [], description: '' };
const emptyOtherAddon = {
    product: -1,
    description: '',
    category: 'POOL_OTHER',
};

const getCounter = json => {
    let i = 1;
    while (true) {
        const includes = json.some(record => {
            return parseInt(record.counter) === i;
        });
        if (!includes) {
            break;
        }
        i++;
    }
    return i;
};

const ProductConfigurator = ({ orderId, handleOnBackClick, handleOnSaveClick, saveLoading, selectedProduct }) => {
    const recentlyAlerted = useRef(false);
    const { categories, loadingCategories, mainCategories, subCategories, products } = useSelector(store => store.product);

    const [category, setCategory] = useState(-1);
    const [subCategory, setSubCategory] = useState(-1);
    const [product, setProduct] = useState(
        selectedProduct
            ? {
                  value: selectedProduct.productSchema.id,
                  label: selectedProduct.productSchema.name,
              }
            : -1
    );
    const [providers, setProviders] = useState(selectedProduct ? selectedProduct.productSchema.providers : []);
    const [provider, setProvider] = useState(
        selectedProduct
            ? {
                  value: selectedProduct.provider.id,
                  label: selectedProduct.provider.name,
              }
            : -1
    );
    const [amount, setAmount] = useState(selectedProduct ? selectedProduct.amount : 1);
    const [description, setDescription] = useState(selectedProduct ? selectedProduct.description : '');

    const initJsonData = selectedProduct ? JSON.parse(selectedProduct.poolStructure) : [];

    const filteredJsonData =
        selectedProduct && selectedProduct.productSchema.category.id === 'POOL'
            ? initJsonData.filter(record => record.category === 'POOL_OTHER')
            : [];

    const [addons, setAddons] = useState(
        selectedProduct && selectedProduct.productSchema.category.id === 'FILTER_ROOM'
            ? selectedProduct.filterRoomEquipment.map(addon => ({
                  id: addon.id,
                  product: {
                      value: addon.productSchema.id,
                      label: addon.productSchema.name,
                  },
                  provider: {
                      value: addon.provider.id,
                      label: addon.provider.name,
                  },
                  providers: addon.productSchema.providers.map(provider => ({
                      id: provider.id,
                      name: provider.name,
                      active: provider.active,
                  })),
                  description: addon.description,
              }))
            : selectedProduct && selectedProduct.productSchema.category.id === 'POOL'
            ? selectedProduct.poolEquipment
                  .filter(addon => addon.productSchema.category.id === 'POOL_OTHER')
                  .map((addon, index) => ({
                      id: addon.id,
                      product: {
                          value: addon.productSchema.id,
                          label: addon.productSchema.name,
                      },
                      provider: {
                          value: addon.provider.id,
                          label: addon.provider.name,
                      },
                      providers: addon.productSchema.providers.map(provider => ({
                          id: provider.id,
                          name: provider.name,
                          active: provider.active,
                      })),
                      description: addon.description,
                      category: 'POOL_OTHER',
                      counter: filteredJsonData[index].counter,
                  }))
            : []
    );
    const [jsonData, setJsonData] = useState(initJsonData);

    const [nozzle, setNozzle] = useState(-1);
    const [nozzleProvider, setNozzleProvider] = useState(-1);
    const [nozzleProviders, setNozzleProviders] = useState([]);
    const [nozzleDescription, setNozzleDescription] = useState('');
    const [nozzleAddonId, setNozzleAddonId] = useState(undefined);
    const [lamp, setLamp] = useState(-1);
    const [lampProvider, setLampProvider] = useState(-1);
    const [lampProviders, setLampProviders] = useState([]);
    const [lampDescription, setLampDescription] = useState('');
    const [lampAddonId, setLampAddonId] = useState(undefined);
    const [outflow, setOutflow] = useState(-1);
    const [outflowProvider, setOutflowProvider] = useState(-1);
    const [outflowProviders, setOutflowProviders] = useState([]);
    const [outflowDescription, setOutflowDescription] = useState('');
    const [outflowAddonId, setOutflowAddonId] = useState(undefined);
    const [counterflow, setCounterflow] = useState(-1);
    const [counterflowProvider, setCounterflowProvider] = useState(-1);
    const [counterflowProviders, setCounterflowProviders] = useState([]);
    const [counterflowDescription, setCounterflowDescription] = useState('');
    const [counterflowAddonId, setCounterflowAddonId] = useState(undefined);
    const [skimmer, setSkimmer] = useState(-1);
    const [skimmerProvider, setSkimmerProvider] = useState(-1);
    const [skimmerProviders, setSkimmerProviders] = useState([]);
    const [skimmerDescription, setSkimmerDescription] = useState('');
    const [skimmerAddonId, setSkimmerAddonId] = useState(undefined);

    const initCounter = jsonData && jsonData.length > 0 ? getCounter(jsonData) : 1;

    const [counter, setCounter] = useState(initCounter);

    useEffect(() => {
        selectedProduct &&
            selectedProduct.poolEquipment.forEach(record => {
                switch (record.productSchema.category.id) {
                    case 'NOZZLE':
                        setNozzle({
                            value: record.productSchema.id,
                            label: record.productSchema.name,
                        });
                        setNozzleProvider({ value: record.provider.id, label: record.provider.name });
                        setNozzleProviders(
                            record.productSchema.providers
                                .filter(x => x.active)
                                .map(x => ({ value: x.id, label: x.name, active: x.active }))
                        );
                        setNozzleDescription(record.description);
                        setNozzleAddonId(record.id);
                        break;
                    case 'LAMP':
                        setLamp({
                            value: record.productSchema.id,
                            label: record.productSchema.name,
                        });
                        setLampProvider({ value: record.provider.id, label: record.provider.name });
                        setLampProviders(
                            record.productSchema.providers
                                .filter(x => x.active)
                                .map(x => ({ value: x.id, label: x.name, active: x.active }))
                        );
                        setLampDescription(record.description);
                        setLampAddonId(record.id);
                        break;
                    case 'OUTFLOW':
                        setOutflow({
                            value: record.productSchema.id,
                            label: record.productSchema.name,
                        });
                        setOutflowProvider({ value: record.provider.id, label: record.provider.name });
                        setOutflowProviders(
                            record.productSchema.providers
                                .filter(x => x.active)
                                .map(x => ({ value: x.id, label: x.name, active: x.active }))
                        );
                        setOutflowDescription(record.description);
                        setOutflowAddonId(record.id);
                        break;
                    case 'COUNTERFLOW':
                        setCounterflow({
                            value: record.productSchema.id,
                            label: record.productSchema.name,
                        });
                        setCounterflowProvider({ value: record.provider.id, label: record.provider.name });
                        setCounterflowProviders(
                            record.productSchema.providers
                                .filter(x => x.active)
                                .map(x => ({ value: x.id, label: x.name, active: x.active }))
                        );
                        setCounterflowDescription(record.description);
                        setCounterflowAddonId(record.id);
                        break;
                    case 'SKIMMER':
                        setSkimmer({
                            value: record.productSchema.id,
                            label: record.productSchema.name,
                        });
                        setSkimmerProvider({ value: record.provider.id, label: record.provider.name });
                        setSkimmerProviders(
                            record.productSchema.providers
                                .filter(x => x.active)
                                .map(x => ({ value: x.id, label: x.name, active: x.active }))
                        );
                        setSkimmerDescription(record.description);
                        setSkimmerAddonId(record.id);
                        break;

                    default:
                        break;
                }
            });
    }, []);

    useEffect(() => {
        if (selectedProduct && !loadingCategories && categories.length > 0) {
            if (mainCategories.some(mainCategory => mainCategory.id === selectedProduct.productSchema.category.id)) {
                setCategory({
                    value: selectedProduct.productSchema.category.id,
                    label: selectedProduct.productSchema.category.label,
                });
            } else {
                const found = categories.find(c => c.id === selectedProduct.productSchema.category.id);
                setCategory({
                    value: found.overcategory.id,
                    label: found.overcategory.label,
                });
            }
            setSubCategory({
                value: selectedProduct.productSchema.category.id,
                label: selectedProduct.productSchema.category.label,
            });
        }
    }, [loadingCategories]);

    const resetConfiguration = () => {
        setCounter(1);
        setAddons([]);
        setAmount(1);
        setDescription('');
        setAddons([]);
        setJsonData([]);
        setNozzle(-1);
        setNozzleDescription('');
        setLamp(-1);
        setLampDescription('');
        setOutflow(-1);
        setOutflowDescription('');
        setCounterflow(-1);
        setCounterflowDescription('');
        setSkimmer(-1);
        setSkimmerDescription('');
    };

    const handleSetCategory = e => {
        if (category.value && category.value !== e.value) {
            setProduct(-1);
            setProviders([]);
            setProvider(-1);
            setSubCategory(-1);
            resetConfiguration();
        }
        setCategory(e);
        if (!subCategories[e.value]) {
            setSubCategory(e);
        }
        if (e.value === 'POOL') {
            setAmount(1);
        }
    };

    const handleSetSubCategory = e => {
        if (subCategory.value && subCategory.value !== e.value) {
            setProduct(-1);
            setProviders([]);
            setProvider(-1);
            resetConfiguration();
        }
        setSubCategory(e);
    };

    const handleSaveClick = () => {
        const combinedAddons = [
            ...addons,
            ...[
                { id: nozzleAddonId, product: nozzle, provider: nozzleProvider, description: nozzleDescription, category: 'NOZZLE' },
                { id: lampAddonId, product: lamp, provider: lampProvider, description: lampDescription, category: 'LAMP' },
                {
                    id: outflowAddonId,
                    product: outflow,
                    provider: outflowProvider,
                    description: outflowDescription,
                    category: 'OUTFLOW',
                },
                {
                    id: counterflowAddonId,
                    product: counterflow,
                    provider: counterflowProvider,
                    description: counterflowDescription,
                    category: 'COUNTERFLOW',
                },
                {
                    id: skimmerAddonId,
                    product: skimmer,
                    provider: skimmerProvider,
                    description: skimmerDescription,
                    category: 'SKIMMER',
                },
            ],
        ];

        if (category.value === 'POOL') {
            combinedAddons.sort((a, b) => a.category.localeCompare(b.category));
        }

        const sortedJsonData = jsonData ? [...jsonData].sort((a, b) => a.category.localeCompare(b.category)) : null;

        const configuration = {
            category: subCategory,
            product,
            amount,
            description,
            provider,
            addons: combinedAddons.filter(addon => addon.product !== -1),
            jsonData: sortedJsonData,
        };

        handleOnSaveClick(configuration);
    };

    const calculateCounter = updated => setCounter(getCounter(updated));

    const renderBuilder = () => {
        switch (category.value) {
            case 'POOL':
                return (
                    <PoolBuilder
                        product={products.find(p => p.id === product.value)}
                        {...{ addons }}
                        {...{ handleAddAddon }}
                        {...{ handleAddonProductChange }}
                        {...{ handleAddonProviderChange }}
                        {...{ handleAddonDescriptionChange }}
                        {...{ jsonData }}
                        {...{ handleAddJsonData }}
                        {...{ handleChangeJsonData }}
                        {...{ handleDeleteJsonData }}
                        {...{ disableNozzle }}
                        {...{ disableLamp }}
                        {...{ disableOutflow }}
                        {...{ disableCounterflow }}
                        {...{ disableSkimmer }}
                        {...{ nozzle }}
                        {...{ nozzleProvider }}
                        {...{ nozzleProviders }}
                        {...{ nozzleDescription }}
                        {...{ handleNozzleChange }}
                        {...{ handleNozzleProviderChange }}
                        {...{ handleNozzleDescriptionChange }}
                        {...{ lamp }}
                        {...{ handleLampProviderChange }}
                        {...{ lampProvider }}
                        {...{ lampProviders }}
                        {...{ handleLampChange }}
                        {...{ lampDescription }}
                        {...{ handleLampDescriptionChange }}
                        {...{ outflow }}
                        {...{ outflowProvider }}
                        {...{ outflowProviders }}
                        {...{ handleOutflowChange }}
                        {...{ handleOutflowProviderChange }}
                        {...{ outflowDescription }}
                        {...{ handleOutflowDescriptionChange }}
                        {...{ counterflow }}
                        {...{ counterflowProvider }}
                        {...{ counterflowProviders }}
                        {...{ handleCounterflowChange }}
                        {...{ handleCounterflowProviderChange }}
                        {...{ counterflowDescription }}
                        {...{ handleCounterflowDescriptionChange }}
                        {...{ skimmer }}
                        {...{ skimmerProvider }}
                        {...{ skimmerProviders }}
                        {...{ handleSkimmerChange }}
                        {...{ handleSkimmerProviderChange }}
                        {...{ skimmerDescription }}
                        {...{ handleSkimmerDescriptionChange }}
                        {...{ counter }}
                        {...{ calculateCounter }}
                        {...{ description }}
                        {...{ orderId }}
                        disableEditing={disableEditingAddons}
                    />
                );
            case 'FILTER_ROOM':
                return (
                    <FilterBuilder
                        products={products.filter(product => product.category.id === 'POOL_TECH')}
                        {...{ addons }}
                        {...{ handleAddAddonClick }}
                        {...{ handleAddonProductChange }}
                        {...{ handleAddonProviderChange }}
                        {...{ handleAddonDescriptionChange }}
                        {...{ handleAddonClearClick }}
                        {...{ handleAddonDeleteClick }}
                        disableEditing={disableEditingMeta}
                    />
                );
            default:
                return <NoBuilder>Dodatkowa konfiguracja jest dostępna tylko dla wybranych kategorii</NoBuilder>;
        }
    };

    const setProvidersAfterProductSet = found => {
        setProviders(found && found.providers.length > 1 ? found.providers : []);
        setProvider(found.providers.length === 1 ? { value: found.providers[0].id, label: found.providers[0].name } : -1);
    };

    const handleSetProduct = picked => {
        if (product.value) {
            if (product.value !== picked.value) {
                const found = products.find(p => p.id === picked.value);
                resetConfiguration();
                setProvidersAfterProductSet(found);
            }
        } else {
            const found = products.find(p => p.id === picked.value);
            setProvidersAfterProductSet(found);
        }
        setProduct(picked);
    };

    const handleAddAddonClick = () => {
        setAddons(prev => [...prev, emptyAddon]);
    };

    const handleAddonProductChange = (index, e) => {
        const updatedAddons = [...addons];
        const selectedProduct = products.find(product => product.id === e.value);
        const selectedProvider =
            selectedProduct.providers.length > 1
                ? -1
                : { value: selectedProduct.providers[0].id, label: selectedProduct.providers[0].name };
        updatedAddons[index] = { ...updatedAddons[index], product: e, provider: selectedProvider, providers: selectedProduct.providers };
        setAddons(updatedAddons);
    };

    const handleAddonProviderChange = (index, e) => {
        const updatedAddons = [...addons];
        updatedAddons[index] = { ...updatedAddons[index], provider: e };
        setAddons(updatedAddons);
    };

    const handleAddonDescriptionChange = (index, e) => {
        const updatedAddons = [...addons];
        updatedAddons[index] = {
            ...updatedAddons[index],
            description: e.target.value,
        };
        setAddons(updatedAddons);
    };

    const handleAddonClearClick = () => {
        setAddons([]);
    };

    const handleAddonDeleteClick = index => {
        const updatedAddons = [...addons];
        updatedAddons.splice(index, 1);
        setAddons(updatedAddons);
    };

    const disableSaveButton = category === -1 || product === -1 || provider === -1;

    const handleAddAddon = addon => {
        setAddons(prev => [...prev, addon]);
    };

    const handleAddJsonData = newJson => {
        setJsonData(prev => [...prev, newJson]);
        if (newJson.category === 'POOL_OTHER') {
            setAddons(prev => [...prev, { ...emptyOtherAddon, counter: newJson.counter }]);
        }
    };

    const handleChangeJsonData = (index, newData) => {
        setJsonData(prev => {
            const updated = [...prev];
            updated[index] = newData;
            return updated;
        });
    };

    const handleDeleteJsonData = index => {
        const deletedRecord = { ...jsonData[index] };
        const updatedJson = jsonData.filter((_, i) => index !== i);
        const anyLeft = updatedJson.some(record => record.category === deletedRecord.category);

        if (deletedRecord.category === 'POOL_OTHER') {
            const updatedAddons = addons.filter(record => record.counter !== deletedRecord.counter);
            setAddons(updatedAddons);
        }

        if (!anyLeft) {
            switch (deletedRecord.category) {
                case 'NOZZLE':
                    setNozzle(-1);
                    setNozzleProvider(-1);
                    setNozzleDescription('');
                    break;
                case 'LAMP':
                    setLamp(-1);
                    setLampProvider(-1);
                    setLampDescription('');
                    break;
                case 'OUTFLOW':
                    setOutflow(-1);
                    setOutflowProvider(-1);
                    setOutflowDescription('');
                    break;
                case 'COUNTERFLOW':
                    setCounterflow(-1);
                    setCounterflowProvider(-1);
                    setCounterflowDescription('');
                    break;
                case 'SKIMMER':
                    setSkimmer(-1);
                    setSkimmerProvider(-1);
                    setSkimmerDescription('');
                    break;
                default:
                    break;
            }
        }
        setJsonData(updatedJson);
    };

    const disableEditingMeta = selectedProduct && selectedProduct.packageInfos.some(x => x.updatable === false);
    const disableEditingAddons = selectedProduct && selectedProduct.packageInfos.some(x => x.updatableTooling === false);

    const disableNozzle = (jsonData && jsonData.filter(record => record.category === 'NOZZLE').length === 0) || disableEditingAddons;
    const disableLamp = (jsonData && jsonData.filter(record => record.category === 'LAMP').length === 0) || disableEditingAddons;
    const disableOutflow = (jsonData && jsonData.filter(record => record.category === 'OUTFLOW').length === 0) || disableEditingAddons;
    const disableCounterflow =
        (jsonData && jsonData.filter(record => record.category === 'COUNTERFLOW').length === 0) || disableEditingAddons;
    const disableSkimmer = (jsonData && jsonData.filter(record => record.category === 'SKIMMER').length === 0) || disableEditingAddons;

    const handleNozzleChange = e => {
        const providers = getProductProviders(products, e);
        const provider = providers.length === 1 ? { value: providers[0].value, label: providers[0].label } : -1;

        setNozzle(e);
        setNozzleProvider(provider);
        setNozzleProviders(providers);
    };

    const handleNozzleProviderChange = e => {
        setNozzleProvider(e);
    };

    const handleNozzleDescriptionChange = e => {
        setNozzleDescription(e.target.value);
    };

    const handleLampChange = e => {
        const providers = getProductProviders(products, e);
        const provider = providers.length === 1 ? { value: providers[0].value, label: providers[0].label } : -1;

        setLamp(e);
        setLampProvider(provider);
        setLampProviders(providers);
    };

    const handleLampProviderChange = e => {
        setLampProvider(e);
    };

    const handleLampDescriptionChange = e => {
        setLampDescription(e.target.value);
    };

    const handleOutflowChange = e => {
        const providers = getProductProviders(products, e);
        const provider = providers.length === 1 ? { value: providers[0].value, label: providers[0].label } : -1;

        setOutflow(e);
        setOutflowProvider(provider);
        setOutflowProviders(providers);
    };

    const handleOutflowProviderChange = e => {
        setOutflowProvider(e);
    };

    const handleOutflowDescriptionChange = e => {
        setOutflowDescription(e.target.value);
    };

    const handleCounterflowChange = e => {
        const providers = getProductProviders(products, e);
        const provider = providers.length === 1 ? { value: providers[0].value, label: providers[0].label } : -1;

        setCounterflow(e);
        setCounterflowProvider(provider);
        setCounterflowProviders(providers);
    };

    const handleCounterflowProviderChange = e => {
        setCounterflowProvider(e);
    };

    const handleCounterflowDescriptionChange = e => {
        setCounterflowDescription(e.target.value);
    };

    const handleSkimmerChange = e => {
        const providers = getProductProviders(products, e);
        const provider = providers.length === 1 ? { value: providers[0].value, label: providers[0].label } : -1;

        setSkimmer(e);
        setSkimmerProvider(provider);
        setSkimmerProviders(providers);
    };

    const handleSkimmerProviderChange = e => {
        setSkimmerProvider(e);
    };

    const handleSkimmerDescriptionChange = e => {
        setSkimmerDescription(e.target.value);
    };

    const minProductAmount =
        selectedProduct && selectedProduct.packageInfos.length > 0
            ? selectedProduct.packageInfos.map(pack => pack.amount).reduce((result, curr) => result + curr, 0)
            : -1;

    const handleSetAmount = e => {
        const { value } = e.target;
        if (value < minProductAmount && minProductAmount !== -1) {
            if (!recentlyAlerted.current) {
                recentlyAlerted.current = true;
                NotificationManager.warning(
                    `Nie można zmniejszyć ilości tego produktu poniżej ${minProductAmount}, ponieważ tyle umieszczono w paczkach.`
                );
                setTimeout(() => {
                    recentlyAlerted.current = false;
                }, 1000);
            }
        } else {
            setAmount(value);
        }
    };

    return (
        <>
            <HeaderWrapper>
                <Button onClick={handleOnBackClick} variant='secondary' style={{ width: toRem(100) }}>
                    Powrót
                </Button>
                Konfigurator produktu
                {saveLoading ? (
                    <CircularProgress />
                ) : (
                    <Button onClick={handleSaveClick} style={{ width: toRem(100) }} disabled={disableSaveButton}>
                        Zapisz
                    </Button>
                )}
            </HeaderWrapper>
            <FieldsRow>
                <FormItem>
                    <label>Kategoria</label>
                    <Select
                        name='category'
                        value={category}
                        onChange={e => handleSetCategory(e)}
                        placeholder='Wybierz kategorię'
                        isSearchable={true}
                        isDisabled={selectedProduct}
                        options={mainCategories.map(category => ({
                            value: category.id,
                            label: category.label,
                        }))}
                    />
                </FormItem>
                {category !== -1 && subCategories[category.value] && (
                    <FormItem>
                        <label>Podkategoria</label>
                        <Select
                            name='subCategory'
                            value={subCategory}
                            onChange={e => handleSetSubCategory(e)}
                            placeholder='Wybierz podkategorię'
                            isDisabled={selectedProduct}
                            isSearchable={true}
                            options={subCategories[category.value].map(category => ({
                                value: category.id,
                                label: category.label,
                            }))}
                        />
                    </FormItem>
                )}
                <FormItem flexGrow={2}>
                    <label>Produkt</label>
                    <Select
                        name='product'
                        value={product}
                        onChange={e => handleSetProduct(e)}
                        placeholder='Wybierz produkt'
                        isSearchable={true}
                        noOptionsMessage={() => 'Brak produktów'}
                        isDisabled={category === -1 || subCategory === -1 || disableEditingMeta}
                        options={products
                            .filter(product => product.category.id === subCategory.value)
                            .map(product => ({
                                value: product.id,
                                label: product.name,
                            }))}
                    />
                </FormItem>
                <FormItem>
                    <label>Dostawca</label>
                    <Select
                        name='provider'
                        value={provider}
                        isDisabled={category === -1 || subCategory === -1 || product === -1 || providers.length < 2 || disableEditingMeta}
                        onChange={e => setProvider(e)}
                        placeholder='Wybierz dostawce'
                        isSearchable={true}
                        options={providers
                            .filter(x => x.active)
                            .map(provider => ({
                                value: provider.id,
                                label: provider.name,
                            }))}
                    />
                </FormItem>
                <div>
                    <label>Ilość</label>
                    <Form.Control
                        placeholder='Wprowadź Ilość'
                        type='number'
                        min={1}
                        value={amount}
                        onChange={handleSetAmount}
                        onKeyDown={e => {
                            e.preventDefault();
                        }}
                        disabled={['POOL', 'POLI_POOL'].includes(category.value)}
                        style={{ width: toRem(200) }}
                    />
                </div>
            </FieldsRow>
            <FieldsRow>
                <FormItem>
                    <label>Opis</label>
                    <Form.Control
                        as='textarea'
                        rows='2'
                        placeholder='Wprowadź opis'
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        width='100%'
                        style={{ resize: 'none' }}
                    />
                </FormItem>
            </FieldsRow>
            <VerticalDivider />
            {renderBuilder()}
        </>
    );
};

export default ProductConfigurator;
