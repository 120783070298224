import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class DeletionModal extends Component {
    render() {
        let deletionText = 'Usuń';
        if (this.props.deletionText) {
            deletionText = this.props.deletionText;
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdź operację</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.text}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={this.props.onHide}>
                        Anuluj
                    </Button>
                    <Button variant={this.props.variant || 'danger'} onClick={this.props.handleRemoveClick}>
                        {deletionText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeletionModal;
