import { reservationsConstants } from '../../Constants';

const initialState = {
    reservations: [],
    notifications: [],
    archiveTasks: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case reservationsConstants.GET:
            return {
                ...state,
                reservations: action.payload,
            };
        case reservationsConstants.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case reservationsConstants.GET_ARCHIVE_TASKS:
            return {
                ...state,
                archiveTasks: action.payload,
            };
        default:
            return state;
    }
}
