import { productConstants } from '../../Constants';
import { preprocessProductResponse } from '../../Utils/PreprocessResponse';

const initialState = {
    products: [],
    archiveProducts: [],
    loadingCategories: false,
    categories: [],
    mainCategories: [],
    subCategories: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case productConstants.LOADING_CATEGORIES:
            return {
                ...state,
                loadingCategories: true,
            };
        case productConstants.GET:
            return {
                ...state,
                products: preprocessProductResponse(action.payload),
            };
        case productConstants.GET_ARCHIVE:
            return {
                ...state,
                archiveProducts: preprocessProductResponse(action.payload),
            };
        case productConstants.GET_CATEGORIES:
            const mainCategories = [];
            const subCategories = {};

            action.payload.forEach(category => {
                if (category.overcategory) {
                    if (subCategories[category.overcategory.id]) {
                        subCategories[category.overcategory.id] = [...subCategories[category.overcategory.id], category];
                    } else {
                        subCategories[category.overcategory.id] = [category];
                    }
                    const alreadyExists = mainCategories.some(c => c.id === category.overcategory.id);
                    if (!alreadyExists) {
                        mainCategories.push(category.overcategory);
                    }
                } else {
                    mainCategories.push(category);
                }
            });

            return {
                ...state,
                categories: action.payload,
                mainCategories,
                subCategories,
                loadingCategories: false,
            };
        default:
            return state;
    }
}
