import styled, { css } from 'styled-components';
import { toRem } from '../../Styles/theme';

export const PicklistContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${toRem(20)};

    height: 90%;

    margin-top: ${toRem(20)};
`;

export const PicklistHeadersWrapper = styled.div`
    display: flex;

    width: 100%;
`;

export const PicklistColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    width: 100%;
    height: 100%;

    border-radius: 5px;
    border: solid 1px lightgrey;
    padding: ${toRem(5)};

    overflow-y: auto;
`;

export const PicklistButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};
    justify-content: center;

    height: 80vh;
`;

const productItemDisabledStyles = css`
    opacity: 0.5;
    pointer-events: none;
`;

export const ProductItem = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px ${props => props.borderColor || 'lightgrey'};

    cursor: pointer;

    ${props => props.picked && 'background: whitesmoke'};

    &:hover {
        background: whitesmoke;
    }

    ${props => props.disabled && productItemDisabledStyles}
`;

export const AmountWrapper = styled.div`
    margin-bottom: ${toRem(20)};

    text-align: center;
`;

export const PackageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${toRem(10)};

    width: 100%;
    height: 90%;

    margin-top: ${toRem(20)};

    overflow-y: auto;
`;

export const PackageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center;
    gap: ${toRem(10)};

    width: 100%;

    border-radius: 5px;
    border: solid 1px ${props => props.borderColor || 'lightgrey'};
    padding: ${toRem(5)};
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 80vh;
`;

export const PackageTitle = styled.div`
    text-size: ${toRem(20)};
    font-weight: bold;
    margin-left: ${toRem(10)};
`;

export const OrderTitle = styled(PackageTitle)`
    font-weight: 600;
`;
