import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ChangePasswordForm from '../Components/ChangePasswordForm/ChangePasswordForm';
import { changePassword } from '../Redux/Actions/AuthActions';
import { authConstants } from '../Constants';
import { getViewFromPath } from './login/login.utils';
import { getClients } from '../Redux/Actions/client';
import { getLicenseTypes } from '../Redux/Actions/LicenseTypesActions';
import { getProductCategories, getProducts } from '../Redux/Actions/product';
import { getProviders } from '../Redux/Actions/provider';
import { getRoles } from '../Redux/Actions/RolesActions';
import { getTrailers } from '../Redux/Actions/TrailersActions';
import { getTrucks } from '../Redux/Actions/TrucksActions';
import { getUsers } from '../Redux/Actions/UsersActions';
import { getWorkers } from '../Redux/Actions/WorkersActions';
import { prepareUrl } from '../Components/routing/routing-utils';

const ChangePasswordPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { id } = useSelector(store => store.auth.user);

    const getRedirectUrl = roles =>
        roles.map(x => x.name).includes('ROLE_TEMP_PASSWORD')
            ? { pathname: prepareUrl('/changePassword') }
            : location.state && location.state.from
            ? { pathname: `${location.state.from.pathname}` }
            : { pathname: prepareUrl('/') };

    const handleSubmit = fields => {
        dispatch(
            changePassword(
                {
                    id,
                    password: fields.password,
                },
                redirectBackToSource
            )
        );
    };

    const initLoadForAdmin = async () => {
        await Promise.all([
            dispatch(getClients()),
            dispatch(getLicenseTypes()),
            dispatch(getProductCategories()),
            dispatch(getProducts()),
            dispatch(getProviders()),
            dispatch(getRoles()),
            dispatch(getTrailers()),
            dispatch(getTrucks()),
            dispatch(getUsers()),
            dispatch(getWorkers()),
        ]);
    };

    const initLoadForUser = async () => {
        await Promise.all([dispatch(getClients())]);
    };

    const redirectBackToSource = async userRoles => {
        const roles = userRoles.map(role => role.name);

        if (roles.includes('ROLE_ADMIN')) {
            await initLoadForAdmin();
        } else if (roles.includes('ROLE_USER')) {
            await initLoadForUser();
        }

        const redirectUrl = getRedirectUrl(userRoles);

        dispatch({ type: authConstants.CHANGE_VIEW, payload: getViewFromPath(redirectUrl.pathname) });

        history.replace(redirectUrl);
    };

    return <ChangePasswordForm handleSubmit={handleSubmit} />;
};

export default ChangePasswordPage;
