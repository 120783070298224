import { notificationsConstants } from '../../Constants';

const initialState = {
    manager: {},
    notifications: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case notificationsConstants.SET:
            return {
                ...state,
                manager: action.payload,
            };
        case notificationsConstants.CLEAR:
            return {
                ...state,
                manager: {},
            };
        case notificationsConstants.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        default:
            return state;
    }
}
