import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import '../../Styles/ControlPanel.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import '../../Styles/ControlPanel.css';
import RequiredFieldIndicator from '../RequiredFieldIndicator';
import Select from 'react-select';
import { haveRole } from '../../Utils/commons';
import { ROLES } from '../../Constants/roles';

Moment.locale('pl');

Moment.locale('pl', {
    months: 'Styczeń.Luty.Marzec.Kwiecień.Maj.Czerwiec.Lipiec.Sierpień.Wrzesień.Październik.Listopad.Grudzień'.split('.'),
    monthsShort: 'STY,LUT,MAR,KWI,MAJ,CZE,LIP,SIE,WRZ,PAŹ,LIS,GRU'.split(','),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'Pn.Wt.Śr.Czw.Pt.Sb.Nd'.split('.'),
    weekdaysMin: 'Nd.Pn.Wt.Śr.Czw.Pt.Sb'.split('.'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD-MM-YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
    },
});
momentLocalizer();

class OrdersPanel extends Component {
    render() {
        const tooLateForChanges = haveRole(this.props.user, ROLES.ADMIN) ? false : this.props.dueDate < new Date();

        return (
            <div className='panel-div'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        oldClient: this.props.oldClient,
                        client: this.props.client,
                        dueDate: this.props.dueDate,
                        hidden: this.props.hidden,
                        description: this.props.description,
                    }}
                    validationSchema={Yup.object().shape({
                        client: Yup.mixed(),
                        dueDate: Yup.string(),
                        hidden: Yup.boolean(),
                        description: Yup.string().required('To pole jest wymagane'),
                    })}
                    onSubmit={(fields, { resetForm }) => {
                        if (this.props.isRowClicked) {
                            this.props.handleEditClick(fields);
                        } else {
                            this.props.handleSubmit(fields);
                        }
                        resetForm();
                    }}
                    render={({ errors, status, touched, fields, values, setFieldValue }) => (
                        <Form>
                            <div className='form-group'>
                                <label htmlFor='client'>Klient</label>
                                <Field
                                    name='oldClient'
                                    placeholder='Wprowadź klienta'
                                    type='text'
                                    className='form-control'
                                    disabled={true}
                                />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='client'>Odbiorca</label>
                                <Select
                                    name='client'
                                    value={values['client']}
                                    onChange={e => setFieldValue('client', e)}
                                    placeholder='Wybierz odbiorcę'
                                    isSearchable={true}
                                    isDisabled={tooLateForChanges}
                                    options={this.props.clients.map(client => ({
                                        value: client.id,
                                        label: client.shortName,
                                    }))}
                                />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='dueDate'>Termin</label>

                                <DateTimePicker
                                    name='dueDate'
                                    value={values['dueDate']}
                                    culture='pl'
                                    placeholder='Wybierz termin'
                                    messages={{ dateButton: 'Wybierz termin' }}
                                    time={false}
                                    disabled={tooLateForChanges}
                                    onChange={e => setFieldValue('dueDate', e)}
                                    min={new Date()}
                                    inputProps={{
                                        component: props => <input {...props} readOnly />,
                                    }}
                                />
                            </div>

                            <div className='form-group'>
                                <Field
                                    name='hidden'
                                    type='checkbox'
                                    checked={values['hidden']}
                                    disabled={tooLateForChanges}
                                    style={{ marginLeft: 5, marginRight: 10 }}
                                />
                                Zamówienie zostało już zaplanowane
                            </div>

                            {this.props.isRowClicked && (
                                <div className='form-group'>
                                    <Button
                                        variant={this.props.showProductsModal ? 'secondary' : 'primary'}
                                        style={{ width: '100%' }}
                                        onClick={this.props.handleToggleProductsModal}
                                        disabled={tooLateForChanges}
                                    >
                                        Produkty w zamówieniu
                                    </Button>
                                </div>
                            )}

                            <div className='form-group'>
                                <label htmlFor='description'>Opis</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='description'
                                    placeholder='Wprowadź opis'
                                    type='text'
                                    component='textarea'
                                    rows='3'
                                    disabled={tooLateForChanges}
                                    style={{ resize: 'none' }}
                                    className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='description' component='div' className='invalid-feedback' />
                            </div>

                            {!this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                        Dodaj
                                    </button>
                                    <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                        Wyczyść
                                    </button>
                                </>
                            )}
                            {this.props.isRowClicked && (
                                <>
                                    <div>
                                        <button
                                            type='submit'
                                            className='btn btn-primary'
                                            style={{ marginRight: '5%', width: '30%' }}
                                            disabled={tooLateForChanges || this.props.showProductsModal}
                                        >
                                            Zapisz
                                        </button>
                                        <Button
                                            onClick={this.props.handleCancelClick}
                                            variant='secondary'
                                            style={{ marginRight: '5%', width: '30%' }}
                                            disabled={this.props.showProductsModal}
                                        >
                                            Anuluj
                                        </Button>

                                        <Button
                                            onClick={this.props.handleDeleteClick}
                                            variant='danger'
                                            style={{ width: '30%' }}
                                            disabled={this.props.showProductsModal}
                                        >
                                            Zakończ
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    )}
                />
            </div>
        );
    }
}

export default OrdersPanel;
