import axios from 'axios';
import qs from 'qs';
import { checkStatus } from '../../../Utils/CheckStatus';
import { FAILED_RECEIVED_REPORT, REQUEST_REPORT, RESET_REPORT, SUCCESSFUL_RECEIVED_REPORT } from '../report.constants';

const requestReport = () => ({
    type: REQUEST_REPORT,
});

const successfulReceivedReport = reportData => ({
    type: SUCCESSFUL_RECEIVED_REPORT,
    payload: { reportData },
});

const failedReceivedReport = () => ({
    type: FAILED_RECEIVED_REPORT,
});

export const fetchReport = (url, params) => async dispatch => {
    dispatch(requestReport());
    try {
        const { data } = await axios.get(url, { params, paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }) });
        dispatch(successfulReceivedReport(data));
    } catch (e) {
        dispatch(failedReceivedReport());
        console.log(e);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const resetReport = () => dispatch => {
    dispatch({ type: RESET_REPORT });
};
