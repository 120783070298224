import React, { useState, useCallback } from 'react';
import Axios from 'axios';
import * as dateFns from 'date-fns';

import './CalendarContainer.css';
import MyCalendar from './MyCalendar';
import CalendarPanel from './CalendarPanel';
import CalendarFiltres from './CalendarFiltres';
import { API } from '../../Constants';

const CalendarContainer = React.memo(props => {
    const [selectedWorkers, setSelectedWorkers] = useState([]);
    const [selectedTruck, setSelectedTruck] = useState(-1);
    const [selectedTrailer, setSelectedTrailer] = useState(-1);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [resources, setResources] = useState([]);

    const handlePickWorkers = useCallback(e => {
        setSelectedWorkers(e);
    }, []);

    const handlePickTruck = useCallback(e => {
        setSelectedTruck(e);
    }, []);

    const handlePickTrailer = useCallback(e => {
        setSelectedTrailer(e);
    });

    const fetchWorkersAvailability = month => {
        selectedWorkers.forEach(async worker => {
            const result = await Axios(API.calendarWorkers, {
                params: {
                    workerId: worker.value,
                    date_from: dateFns.format(dateFns.startOfMonth(month), 'dd-MM-yyyy'),
                    date_to: dateFns.format(dateFns.endOfMonth(month), 'dd-MM-yyyy'),
                },
            });

            const preprocesedResult = preprocessAvailabilityForCalendar(result.data, worker.label, month, 'worker');

            setResources(prev => prev.concat(preprocesedResult));
        });
    };

    const fetchTruckAvailability = async month => {
        const result = await Axios(API.calendarTrucks, {
            params: {
                truckId: selectedTruck.value,
                date_from: dateFns.format(dateFns.startOfMonth(month), 'dd-MM-yyyy'),
                date_to: dateFns.format(dateFns.endOfMonth(month), 'dd-MM-yyyy'),
            },
        });

        const preprocesedResult = preprocessAvailabilityForCalendar(result.data, selectedTruck.label, month, 'truck');

        setResources(prev => prev.concat(preprocesedResult));
    };

    const fetchTrailerAvailability = async month => {
        const result = await Axios(API.calendarTrailers, {
            params: {
                trailerId: selectedTrailer.value,
                date_from: dateFns.format(dateFns.startOfMonth(month), 'dd-MM-yyyy'),
                date_to: dateFns.format(dateFns.endOfMonth(month), 'dd-MM-yyyy'),
            },
        });

        const preprocesedResult = preprocessAvailabilityForCalendar(
            result.data,
            `Przyczepa - ${selectedTrailer.label.split('-')[0]}`,
            month,
            'trailer'
        );

        setResources(prev => prev.concat(preprocesedResult));
    };

    const handleSearchClick = useCallback(
        month => {
            setResources([]);

            if (selectedWorkers.length > 0) {
                fetchWorkersAvailability(month);
            }

            if (selectedTruck !== -1) {
                fetchTruckAvailability(month);
            }

            if (selectedTrailer !== -1) {
                fetchTrailerAvailability(month);
            }
        },
        [fetchWorkersAvailability, fetchTruckAvailability, fetchTrailerAvailability, selectedWorkers, selectedTruck, selectedTrailer]
    );

    const handleClearClick = useCallback(() => {
        setSelectedWorkers([]);
        setSelectedTruck(-1);
        setSelectedTrailer(-1);
        setResources([]);
    }, []);

    const handleNextMonthClick = useCallback(() => {
        const newMonth = dateFns.addMonths(currentMonth, 1);
        setCurrentMonth(newMonth);
        handleSearchClick(newMonth);
    }, [currentMonth, handleSearchClick]);

    const handlePrevMonthClick = useCallback(() => {
        const newMonth = dateFns.subMonths(currentMonth, 1);
        setCurrentMonth(newMonth);
        handleSearchClick(newMonth);
    }, [currentMonth, handleSearchClick]);

    const preprocessAvailabilityForCalendar = (data, name, month, type) => {
        const monthStart = dateFns.startOfMonth(month);
        const monthEnd = dateFns.endOfMonth(monthStart);

        const availability = [];

        let day = monthStart;
        const dateFormat = 'MM-dd';

        while (day <= monthEnd) {
            let isAvailable = true;
            data.forEach(dateRange => {
                const dateFrom = new Date(dateRange[0]);
                const dateTo = new Date(dateRange[1]);
                const formattedDay = dateFns.format(day, dateFormat);
                const formattedDateFrom = dateFns.format(dateFrom, dateFormat);
                const formattedDateTo = dateFns.format(dateTo, dateFormat);

                if (formattedDay >= formattedDateFrom && formattedDay <= formattedDateTo) isAvailable = false;
            });

            if (isAvailable) {
                availability.push(day);
            }
            day = dateFns.addDays(day, 1);
        }

        let color = null;

        switch (type) {
            case 'worker':
                color = 'rgb(75,25,25)';
                break;
            case 'truck':
                color = 'rgb(25,75,25)';
                break;
            case 'trailer':
                color = 'rgb(25,25,75)';
                break;
            default:
                break;
        }

        const result = { name, availability, color };

        return result;
    };

    return (
        <div className={`${props.hasPanel ? 'calendar-container' : 'calendar-container-with-filtres'} ${props.slide ? 'slide' : ''} `}>
            {props.hasPanel ? null : (
                <CalendarFiltres
                    {...{ currentMonth }}
                    {...{ selectedWorkers }}
                    {...{ handlePickWorkers }}
                    {...{ selectedTruck }}
                    {...{ handlePickTruck }}
                    {...{ selectedTrailer }}
                    {...{ handlePickTrailer }}
                    {...{ handleSearchClick }}
                    {...{ handleClearClick }}
                />
            )}
            <MyCalendar
                hasPanel={props.hasPanel}
                {...{ resources }}
                numberOfSelectedResources={resources.length}
                {...{ currentMonth }}
                {...{ handleNextMonthClick }}
                {...{ handlePrevMonthClick }}
            />

            {props.hasPanel ? (
                <CalendarPanel
                    {...{ currentMonth }}
                    {...{ selectedWorkers }}
                    {...{ handlePickWorkers }}
                    {...{ selectedTruck }}
                    {...{ handlePickTruck }}
                    {...{ selectedTrailer }}
                    {...{ handlePickTrailer }}
                    {...{ handleSearchClick }}
                    {...{ handleClearClick }}
                />
            ) : null}
        </div>
    );
});

export default CalendarContainer;
