import React, { Component } from 'react';

import CustomTable from '../Components/CustomTable';
import ReservationPanel from '../Components/ControlPanels/ReservationsPanel';
import { connect } from 'react-redux';

import { ColumnsConfig } from '../Utils/ColumnsConfig';
import {
    getReservations,
    addReservation,
    deleteReservation,
    editReservation,
    downloadReservations,
    getReservationNotifications,
    getArchiveTasks,
} from '../Redux/Actions/ReservationsActions';
import { getNotHiddenOrders } from '../Redux/Actions/OrdersActions';
import { getAvailableWorkers, getAvailableTrucks, getAvailableTrailers } from '../Redux/Actions/AvailableResourcesActions';
import DeletionModal from '../Components/DeletionModal';
import CalendarContainer from '../Components/MyCalendar/CalendarContainer';
import PackageModal, { availableModes } from '../Components/packages/package-modal';
import { CONTENT_REFRESH_INTERVAL } from '../Constants/common';

class ReservationsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: undefined,
            toDate: undefined,
            description: '',
            assignedWorkers: [],
            assignedTruck: -1,
            assignedTrailer: -1,
            assignedOrders: [],
            availableWorkers: [],
            availableTrucks: [],
            availableTrailers: [],
            enableComboboxes: false,
            id: -1,
            isRowClicked: false,
            showModal: false,
            showCalendar: false,
            showArchiveTasks: false,
            showPackageModal: false,
            mode: availableModes.LIST,
            editedPack: undefined,
        };
        this.columns = ColumnsConfig.ReservationsColumns;
    }

    componentDidMount() {
        this.props.getReservations();
        this.props.getNotHiddenOrders();
        this.props.getReservationNotifications();
        this.timerId = setInterval(() => this.refresh(), CONTENT_REFRESH_INTERVAL);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    handleSubmit = fields => {
        let fromDate = this.prepareDateForGet(fields.fromDate);
        let toDate = this.prepareDateForGet(fields.toDate);
        let description = fields.description;
        let assignedWorkers = fields.assignedWorkers.map(worker => ({
            id: worker.value,
        }));
        let assignedTruck =
            fields.assignedTruck === -1
                ? null
                : {
                      id: fields.assignedTruck.value,
                  };
        let assignedTrailer =
            fields.assignedTrailer === -1
                ? null
                : {
                      id: fields.assignedTrailer.value,
                  };
        let orders = fields.assignedOrders.map(order => ({
            id: order.value,
        }));

        this.props.addReservation(
            {
                fromDate,
                toDate,
                description,
                assignedWorkers,
                assignedTruck,
                assignedTrailer,
                orders,
            },
            this.refresh
        );
        this.clearFields();
    };

    handleEditClick = fields => {
        this.setState({ isRowClicked: false });

        const fromDate = this.prepareDateForGet(fields.fromDate);
        const toDate = this.prepareDateForGet(fields.toDate);

        const description = fields.description;

        const assignedWorkers = fields.assignedWorkers
            ? fields.assignedWorkers.map(worker => ({
                  id: worker.value,
              }))
            : null;

        const assignedTruck =
            fields.assignedTruck === undefined || fields.assignedTruck === -1
                ? null
                : {
                      id: fields.assignedTruck.value,
                  };

        const assignedTrailer =
            fields.assignedTrailer === undefined || fields.assignedTrailer === -1
                ? null
                : {
                      id: fields.assignedTrailer.value,
                  };

        const orders = fields.assignedOrders
            ? fields.assignedOrders.map(order => ({
                  id: order.value,
              }))
            : null;

        const id = this.state.id;

        this.props.editReservation(
            {
                id,
                fromDate,
                toDate,
                description,
                assignedWorkers,
                assignedTruck,
                assignedTrailer,
                orders,
            },
            this.refresh
        );

        this.clearFields();
    };

    refresh = () => {
        this.props.getReservations();
        this.props.getNotHiddenOrders();
        this.props.getReservationNotifications();
    };

    clearFields = () => {
        this.setState({
            fromDate: undefined,
            toDate: undefined,
            description: '',
            assignedWorkers: [],
            assignedTruck: -1,
            assignedTrailer: -1,
            assignedOrders: [],
            enableComboboxes: false,
            id: -1,
        });
    };

    prepareDateForGet = date => {
        return '' + ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
    };

    getAvailableResources = (fromDate, toDate) => {
        if (fromDate && toDate && fromDate <= toDate) {
            const preparedFrom = this.prepareDateForGet(fromDate);
            const preparedTo = this.prepareDateForGet(toDate);

            this.props.getAvailableWorkers(preparedFrom, preparedTo, this.state.id, this.refreshAssignedWorkers);

            this.props.getAvailableTrucks(preparedFrom, preparedTo, this.state.id, this.refreshAssignedTruck);

            this.props.getAvailableTrailers(preparedFrom, preparedTo, this.state.id, this.refreshAssignedTrailer);
            this.enableComboboxes();
        }
    };

    refreshAssignedWorkers = workers => {
        this.setState({
            assignedWorkers: this.state.assignedWorkers.filter(worker => workers.map(avWorker => avWorker.id).includes(worker.value)),
        });
    };

    refreshAssignedTruck = trucks => {
        if (!trucks.map(truck => truck.id).includes(this.state.assignedTruck.value)) this.setState({ assignedTruck: -1 });
    };

    refreshAssignedTrailer = trailers => {
        if (!trailers.map(trailer => trailer.id).includes(this.state.assignedTrailer.value)) this.setState({ assignedTrailer: -1 });
    };

    enableComboboxes = () => {
        this.setState({ enableComboboxes: true });
    };

    handleFromDateChange = value => {
        this.setState({ fromDate: value }, function () {
            this.getAvailableResources(this.state.fromDate, this.state.toDate);
        });
    };

    handleToDateChange = value => {
        this.setState({ toDate: value }, function () {
            this.getAvailableResources(this.state.fromDate, this.state.toDate);
        });
    };

    handlePickOrders = e => {
        this.setState({ assignedOrders: e });
    };

    handlePickWorkers = e => {
        this.setState({ assignedWorkers: e });
    };

    handlePickTruck = e => {
        this.setState({ assignedTruck: e });
    };

    handlePickTrailer = e => {
        this.setState({ assignedTrailer: e });
    };

    handleDescriptionChange = e => {
        this.setState({ description: e.target.value });
    };

    prepareDate = date => {
        return '' + date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    };

    handleOnRowClick = rowInfo => {
        if (typeof rowInfo !== 'undefined') {
            const splitFromDate = rowInfo.original.fromDate.split('/');
            const splitToDate = rowInfo.original.toDate.split('/');

            this.setState(
                {
                    fromDate: new Date(splitFromDate[2], splitFromDate[1] - 1, splitFromDate[0]),
                    toDate: new Date(splitToDate[2], splitToDate[1] - 1, splitToDate[0]),
                    description: rowInfo.original.description,
                    enableComboboxes: true,
                    id: rowInfo.original.id,
                    isRowClicked: true,
                },
                function () {
                    this.getAvailableResources(this.state.fromDate, this.state.toDate);
                }
            );

            this.setState({
                assignedOrders: rowInfo.original.orderIds,
                assignedWorkers: rowInfo.original.assignedWorkerIds,
                assignedTruck: rowInfo.original.assignedTruckId ? rowInfo.original.assignedTruckId : -1,
                assignedTrailer: rowInfo.original.assignedTrailerId ? rowInfo.original.assignedTrailerId : -1,
            });
        }
    };

    handleOnDeleteClick = () => {
        this.setState({ showModal: true });
    };

    handleCancelClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    handleDownloadClick = e => {
        e.preventDefault();
        this.props.downloadReservations();
    };

    handleRemoveClick = () => {
        this.setState({ isRowClicked: false, showModal: false });
        this.clearFields();
        const id = this.state.id;
        this.props.deleteReservation({ id }, this.refresh);
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo && this.props.notifications.map(notification => notification.reservationId).includes(rowInfo.row.id)) {
            return {
                style: {
                    border: 'solid 1px red',
                    borderRadius: '5px',
                },
            };
        }

        return {};
    };

    handleToggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    };

    fetchArchiveTasks = async () => {
        if (!this.state.showArchiveTasks) {
            this.props.getArchiveTasks();
        }

        this.clearFields();
        this.setState({
            showArchiveTasks: !this.state.showArchiveTasks,
            isRowClicked: false,
            showPackageModal: false,
        });
    };

    handleTogglePackageModal = () => {
        this.setState({
            showPackageModal: !this.state.showPackageModal,
            mode: availableModes.LIST,
            editedPack: undefined,
        });
    };

    setMode = mode => {
        this.setState({
            mode,
        });
    };

    setEditedPack = editedPack => {
        this.setState({
            editedPack,
        });
    };

    render() {
        return (
            <>
                <div style={{ padding: 10 }}>
                    <DeletionModal
                        show={this.state.showModal}
                        onHide={this.handleModalClose}
                        handleRemoveClick={this.handleRemoveClick}
                        text='Czy na pewno chcesz usunąć to zadanie?'
                    />
                    <CustomTable
                        content={this.state.showArchiveTasks ? this.props.archiveTasks : this.props.reservations}
                        columns={this.columns}
                        className='reservationTable'
                        handleOnRowClick={this.handleOnRowClick}
                        getTrProps={this.getTrProps}
                    />
                    <ReservationPanel
                        handleSubmit={this.handleSubmit}
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        description={this.state.description}
                        assignedWorkers={this.state.assignedWorkers}
                        assignedTrailer={this.state.assignedTrailer}
                        assignedTruck={this.state.assignedTruck}
                        assignedOrders={this.state.assignedOrders}
                        availableOrders={this.props.orders}
                        availableWorkers={this.props.availableWorkers}
                        availableTrucks={this.props.availableTrucks}
                        availableTrailers={this.props.availableTrailers}
                        enableComboboxes={this.state.enableComboboxes}
                        handleFromDateChange={this.handleFromDateChange}
                        handleToDateChange={this.handleToDateChange}
                        handlePickOrders={this.handlePickOrders}
                        handlePickWorkers={this.handlePickWorkers}
                        handlePickTruck={this.handlePickTruck}
                        handlePickTrailer={this.handlePickTrailer}
                        handleDescriptionChange={this.handleDescriptionChange}
                        handleDeleteClick={this.handleOnDeleteClick}
                        handleEditClick={this.handleEditClick}
                        user={this.props.user}
                        isRowClicked={this.state.isRowClicked}
                        handleCancelClick={this.handleCancelClick}
                        handleDownloadClick={this.handleDownloadClick}
                        handleClearClick={this.clearFields}
                        getAvailableResources={this.getAvailableResources}
                        toggleCalendar={this.handleToggleCalendar}
                        showCalendar={this.state.showCalendar}
                        fetchArchiveTasks={this.fetchArchiveTasks}
                        showArchiveTasks={this.state.showArchiveTasks}
                        handleTogglePackageModal={this.handleTogglePackageModal}
                        showPackageModal={this.state.showPackageModal}
                    />
                </div>
                <CalendarContainer slide={this.state.showCalendar} />
                <PackageModal
                    show={this.state.showPackageModal}
                    reservationId={this.state.id}
                    handleTogglePackageModal={this.handleTogglePackageModal}
                    mode={this.state.mode}
                    setMode={this.setMode}
                    editedPack={this.state.editedPack}
                    setEditedPack={this.setEditedPack}
                    showArchiveTasks={this.state.showArchiveTasks}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    reservations: state.reservations.reservations,
    archiveTasks: state.reservations.archiveTasks,
    user: state.auth.user,
    csrf: state.auth.csrf,
    orders: state.orders.notHiddenOrders,
    availableWorkers: state.availableResources.workers,
    availableTrucks: state.availableResources.trucks,
    availableTrailers: state.availableResources.trailers,
    notifications: state.reservations.notifications,
});

const connected = connect(mapStateToProps, {
    getReservations,
    addReservation,
    deleteReservation,
    editReservation,
    downloadReservations,
    getNotHiddenOrders,
    getAvailableWorkers,
    getAvailableTrucks,
    getAvailableTrailers,
    getReservationNotifications,
    getArchiveTasks,
})(ReservationsPage);

export default connected;
