import axios from 'axios';

import { rolesConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getRoles = () => async dispatch => {
    try {
        const res = await axios.get(API.roles);
        return dispatch({
            type: rolesConstants.GET,
            payload: res.data,
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};
