import { packageConstants } from '../../Constants';

const initialState = {
    loadingPackages: false,
    packages: [],
    pickedPackageId: null,
    pickedProductId: null,
    highlightedProductId: null,
    packageInfo: null,
    stage: null,
    ifShowCalendarModal: false,
    todayProducts: [],
    today: new Date(),
};

const handleUpdateProductAmount = (state, action) => {
    const { productAmount, packageId } = action.payload;
    const updatedPackages = [...state.packages];
    const packageIndex = updatedPackages.findIndex(pack => pack.id === packageId);
    const updatedPackage = { ...updatedPackages[packageIndex] };
    const productAmountIndex = updatedPackage.products.findIndex(x => x.id === productAmount.id);

    if (productAmountIndex === -1) {
        updatedPackage.products.push(productAmount);
    } else {
        updatedPackage.products[productAmountIndex] = productAmount;
    }

    updatedPackages[packageIndex] = updatedPackage;

    return {
        ...state,
        packages: updatedPackages,
    };
};

const handleRemoveProductAmount = (state, action) => {
    const { productAmountId, packageId } = action.payload;
    const updatedPackages = [...state.packages];
    const packageIndex = updatedPackages.findIndex(pack => pack.id === packageId);
    const updatedPackage = { ...updatedPackages[packageIndex] };
    const productAmountIndex = updatedPackage.products.findIndex(x => x.id === productAmountId);

    updatedPackage.products.splice(productAmountIndex, 1);
    updatedPackages[packageIndex] = updatedPackage;

    return {
        ...state,
        packages: updatedPackages,
    };
};

const handleTogglePrinted = (state, action) => {
    const { packageId, productAmountId } = action.payload;
    const updatedPackages = [...state.packages];
    const packageIndex = updatedPackages.findIndex(pack => pack.id === packageId);
    const updatedPackage = { ...updatedPackages[packageIndex] };
    const productAmountIndex = updatedPackage.products.findIndex(productAmount => productAmount.id === productAmountId);
    const updatedProductAmounts = [...updatedPackage.products];
    const updatedProductAmount = { ...updatedProductAmounts[productAmountIndex] };
    updatedProductAmounts[productAmountIndex] = { ...updatedProductAmount, printed: !updatedProductAmount.printed };
    updatedPackages[packageIndex] = { ...updatedPackage, products: updatedProductAmounts };
    return {
        ...state,
        packages: updatedPackages,
    };
};

export default function (state = initialState, action) {
    switch (action.type) {
        case packageConstants.REQUEST_PACKAGES:
            return {
                ...state,
                loadingPackages: true,
                pickedPackageId: null,
                pickedProductId: null,
                highlightedProductId: null,
            };
        case packageConstants.SUCCESS_RECEIVE_PACKAGES:
            return {
                ...state,
                packages: action.payload.packages,
                stage: action.payload.stage,
                loadingPackages: false,
            };
        case packageConstants.FAIL_RECEIVE_PACKAGES:
            return {
                ...state,
                loadingPackages: false,
            };
        case packageConstants.PICK_PACKAGE:
            return {
                ...state,
                pickedPackageId: action.payload,
            };
        case packageConstants.UPDATE_PACKAGES:
            return {
                ...state,
                packages: action.payload,
            };
        case packageConstants.PICK_PRODUCT:
            return {
                ...state,
                pickedProductId: action.payload,
            };
        case packageConstants.HIGHLIGHT_PRODUCT:
            return {
                ...state,
                pickedProductId: action.payload.productId,
                highlightedProductId: action.payload.productId,
                pickedPackageId: action.payload.packageId,
            };
        case packageConstants.UNHIGHLIGHT_PRODUCT:
            return {
                ...state,
                highlightedProductId: null,
            };
        case packageConstants.UPDATE_PRODUCT_AMOUNT:
            return handleUpdateProductAmount(state, action);
        case packageConstants.REMOVE_PRODUCT_AMOUNT:
            return handleRemoveProductAmount(state, action);
        case packageConstants.RECEIVE_PACKAGE_INFO:
            return {
                ...state,
                packageInfo: action.payload,
            };
        case packageConstants.RESET:
            return {
                ...initialState,
            };
        case packageConstants.SHOW_CALENDAR_MODAL:
            return {
                ...state,
                ifShowCalendarModal: true,
                todayProducts: action.payload,
                today: new Date(action.payload[0].productionTerm),
            };
        case packageConstants.HIDE_CALENDAR_MODAL:
            return {
                ...state,
                ifShowCalendarModal: false,
                todayProducts: [],
                today: new Date(),
            };

        case packageConstants.TOGGLE_PRINTED:
            return handleTogglePrinted(state, action);
        default:
            return state;
    }
}
