import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import RequiredFieldIndicator from '../RequiredFieldIndicator';
import HistoryIcon from "@material-ui/icons/History";
import Divider from "@material-ui/core/Divider";

const ClientsPanel = props => {
    return (
        <div className='panel-div'>
            <Formik
                enableReinitialize
                initialValues={{
                    name: props.name,
                    shortName: props.shortName,
                    address: props.address,
                    phoneNumber: props.phoneNumber,
                    mail: props.mail,
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('To pole jest wymagane'),
                    shortName: Yup.string().required('To pole jest wymagane'),
                    address: Yup.string(),
                    phoneNumber: Yup.string(),
                    mail: Yup.string(),
                })}
                onSubmit={(fields, { resetForm }) => {
                    if (props.isRowClicked) {
                        props.handleEditClick(fields);
                    } else {
                        props.handleSubmit(fields);
                    }
                    resetForm();
                }}
                render={({ errors, status, touched, fields }) => (
                    <Form>
                        <div style={{display: "flex"}}>
                            <Button onClick={props.handleArchiveClick} style={{flex: 1}} variant={props.showArchive ? "secondary" : "primary"}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px"}}>
                                    <HistoryIcon />Odbiorcy zarchiwizowani
                                </div>
                            </Button>
                        </div>
                        <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
                        <div className='form-group'>
                            <label htmlFor='name'>Nazwa</label>
                            <RequiredFieldIndicator />
                            <Field
                                name='name'
                                placeholder='Wprowadź nazwę'
                                type='text'
                                className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='name' component='div' className='invalid-feedback' />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='name'>Nazwa skrócona</label>
                            <RequiredFieldIndicator />
                            <Field
                                name='shortName'
                                placeholder='Wprowadź nazwę skróconą'
                                type='text'
                                className={'form-control' + (errors.shortName && touched.shortName ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='shortName' component='div' className='invalid-feedback' />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='address'>Adres</label>
                            <Field name='address' placeholder='Wprowadź adres' type='text' className='form-control' />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='phoneNumber'>Numer telefonu</label>
                            <Field
                                name='phoneNumber'
                                placeholder='Wprowadź numer telefonu'
                                type='text'
                                className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='phoneNumber' component='div' className='invalid-feedback' />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='mail'>Adres e-mail</label>
                            <Field name='mail' placeholder='Wprowadź adres e-mail' type='text' className='form-control' />
                        </div>

                        {!props.isRowClicked && (
                            <>
                                <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                    Dodaj
                                </button>
                                <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                    Wyczyść
                                </button>
                            </>
                        )}
                        {props.isRowClicked && (
                            <>
                                <button type='submit' className='btn btn-primary' style={{ marginRight: '5%', width: '30%' }}>
                                    Zapisz
                                </button>
                                <Button onClick={props.handleCancelClick} variant='secondary' style={{ marginRight: '5%', width: '30%' }}>
                                    Anuluj
                                </Button>
                                <Button
                                    onClick={props.handleDeleteClick}
                                    variant={props.showArchive ? "success" : "danger"}
                                    style={{ width: '30%' }}
                                    disabled={!props.isRowClicked}
                                >
                                    {props.showArchive ? "Przywróć" : "Archiwizuj"}
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            />
        </div>
    );
};

export default ClientsPanel;
