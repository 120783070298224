import SortableHeader from '../sortable-header/sortable-header.component';

export const getProductColumns = sort => [
    {
        ID: 'productName',
        accessor: 'productName',
        Header: <SortableHeader id='productName' name='Nazwa' {...sort} />,
    },
    {
        ID: 'categoryName',
        Header: 'Kategoria',
        accessor: 'categoryName',
        sortable: false,
    },
    {
        ID: 'providers',
        Header: 'Dostawcy',
        accessor: 'providers',
        sortable: false,
    },
    {
        ID: 'amount',
        accessor: 'amount',
        Header: <SortableHeader id='amount' name='Ilość' {...sort} />,
    },
];
