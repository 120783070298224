import React from 'react';
import { Wrapper } from './tabs.styles';

const Tabs = ({ activeTab, onChange, children }) => {
    const tabs = children.map((tab, index) => {
        const tabProps = { ...tab.props, active: activeTab === index, onClick: () => onChange(index) };
        return { ...tab, props: tabProps };
    });

    return <Wrapper>{tabs}</Wrapper>;
};

export default Tabs;
