import React, { Component } from 'react';
import CustomTable from '../Components/CustomTable';
import TrailersPanel from '../Components/ControlPanels/TrailersPanel';
import { ColumnsConfig } from '../Utils/ColumnsConfig';

import { getTrailers, addTrailer, deleteTrailer, editTrailer } from '../Redux/Actions/TrailersActions';
import { connect } from 'react-redux';
import DeletionModal from '../Components/DeletionModal';

class TrailersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registrationNumber: '',
            description: '',
            isRowClicked: false,
            id: -1,
            showModal: false,
        };
        this.columns = ColumnsConfig.TrailerColumns;
    }

    refresh = () => {
        this.props.getTrailers();
    };

    handleSubmit = fields => {
        this.props.addTrailer(fields, this.refresh);
        this.clearFields();
    };

    clearFields = () => {
        this.setState({
            registrationNumber: '',
            description: '',
        });
    };

    handleFormChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    };

    handleOnRowClick = rowInfo => {
        if (typeof rowInfo !== 'undefined') {
            this.setState({
                registrationNumber: rowInfo.original.registrationNumber,
                description: rowInfo.original.description,
                id: rowInfo.original.id,
                isRowClicked: true,
            });
        }
    };

    handleCancelClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    handleDeleteClick = () => {
        this.setState({ showModal: true });
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    handleRemoveClick = () => {
        this.setState({ isRowClicked: false, showModal: false });
        this.clearFields();
        const { id } = this.state;
        this.props.deleteTrailer({ id }, this.refresh);
    };

    handleEditClick = fields => {
        this.setState({ isRowClicked: false });
        this.clearFields();
        const { id } = this.state;
        this.props.editTrailer({ id, ...fields }, this.refresh);
    };

    render() {
        return (
            <div style={{ padding: 10 }}>
                <DeletionModal
                    show={this.state.showModal}
                    onHide={this.handleModalClose}
                    handleRemoveClick={this.handleRemoveClick}
                    text='Czy na pewno chcesz usunąć tę przyczepę?'
                />
                <CustomTable
                    content={this.props.trailers}
                    columns={this.columns}
                    className='otherTables'
                    handleOnRowClick={this.handleOnRowClick}
                />
                <TrailersPanel
                    handleSubmit={this.handleSubmit}
                    registrationNumber={this.state.registrationNumber}
                    description={this.state.description}
                    handleFormChange={this.handleFormChange}
                    isRowClicked={this.state.isRowClicked}
                    handleCancelClick={this.handleCancelClick}
                    handleDeleteClick={this.handleDeleteClick}
                    handleEditClick={this.handleEditClick}
                    handleClearClick={this.clearFields}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    trailers: state.trailers.trailers,
});

const connected = connect(mapStateToProps, { getTrailers, addTrailer, deleteTrailer, editTrailer })(TrailersPage);

export default connected;
