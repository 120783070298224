import React, { useRef } from 'react';
import { AVAILABLE_STAGES } from '../../../../Constants/common';
import { useSelector } from 'react-redux';
import { NameWrapper } from '../notification/notification.styles';

const useNotificationsFactory = () => {
    const { products, archiveProducts } = useSelector(store => store.product);
    const { packages } = useSelector(store => store.packages);
    const currentPackages = useRef(null);

    currentPackages.current = packages;

    const createNotification = notification => {
        const { packageId, productAmountId, previousSchemaId, notificationTopic, stage, productionTerm, additionalMessage } = notification;
        const pack = currentPackages.current.find(x => x.id === packageId);

        if (packageId && !pack) return null;

        const product = pack ? pack.products.find(x => x.id === productAmountId) : undefined;
        const previousSchema = previousSchemaId && [...products, ...archiveProducts].find(x => x.id === previousSchemaId);
        const productName = product ? product.product.productSchema.name : undefined;
        const previousSchemaName = previousSchema ? previousSchema.name : undefined;

        const updatedNotification = { ...notification, pack, productName, previousSchemaName, productionTerm, additionalMessage };

        if (notificationTopic === AVAILABLE_TOPICS.ADD) {
            switch (stage) {
                case AVAILABLE_STAGES.PLANNING:
                    return getPlanningPoolAddedNotification(updatedNotification);
                case AVAILABLE_STAGES.PRODUCTION:
                    return getProductionPoolAddedNotification(updatedNotification);
                case AVAILABLE_STAGES.TECH:
                case AVAILABLE_STAGES.PACKING:
                    return getStandardProductAddedNotification(updatedNotification);
                default:
                    return null;
            }
        }

        if (notificationTopic === AVAILABLE_TOPICS.CHANGE) {
            switch (stage) {
                case AVAILABLE_STAGES.PLANNING:
                    if (previousSchemaId) {
                        return getPlanningPoolChangeNotification(updatedNotification);
                    }
                    return getStandardPackageChangeNotification(updatedNotification);
                case AVAILABLE_STAGES.PRODUCTION:
                    if (previousSchemaId) {
                        return getProductionPoolChangeNotification(updatedNotification);
                    }
                    return getStandardProductChangeNotification(updatedNotification);
                case AVAILABLE_STAGES.TOOLING:
                    return getStandardProductChangeNotification(updatedNotification);
                case AVAILABLE_STAGES.TECH:
                case AVAILABLE_STAGES.PACKING:
                    return getStandardPackageChangeNotification(updatedNotification);
                default:
                    return null;
            }
        }

        if (notificationTopic === AVAILABLE_TOPICS.REMOVE) {
            switch (stage) {
                case AVAILABLE_STAGES.PLANNING:
                case AVAILABLE_STAGES.PRODUCTION:
                    return getStandardProductRemovedNotification(notification);
                default:
                    return null;
            }
        }
    };

    return { createNotification };
};

export default useNotificationsFactory;

export const AVAILABLE_TOPICS = {
    ADD: 'ADD',
    CHANGE: 'CHANGE',
    REMOVE: 'REMOVE',
};

const PACKAGE_CHANGE_ALERT = 'Paczka została zmieniona';
const PRODUCT_CHANGE_ALERT = 'Produkt został zmieniony';
const PRODUCT_ADDED_ALERT = 'Produkt został dodany';
const PRODUCT_REMOVED_ALERT = 'Product został usunięty';

const getStandardPackageChangeMessage = name => (
    <>
        Paczka&nbsp;
        <NameWrapper>{name}</NameWrapper>
        &nbsp;została zmieniona.
    </>
);

const getStandardProductChangeMessage = name => (
    <>
        Produkt&nbsp;
        <NameWrapper>{name}</NameWrapper>
        &nbsp;został zmieniony.
    </>
);

const getPoolChangeMessage = (pack, productName, previousSchemaName) => {
    const packageData = `${pack.name} - ${pack.reservationDateTo} (${pack.deadline})`;
    return (
        <>
            Basen&nbsp;
            <NameWrapper>{previousSchemaName}</NameWrapper>
            &nbsp;z&nbsp;
            <NameWrapper>{packageData}</NameWrapper>
            &nbsp;zmieniono na&nbsp;
            <NameWrapper>{productName}</NameWrapper>.
        </>
    );
};

const getStandardProductAddedMessage = (pack, productName) => (
    <>
        Produkt&nbsp;
        <NameWrapper>{productName}</NameWrapper>
        &nbsp;został dodany do paczki&nbsp;
        <NameWrapper>{pack.name}</NameWrapper>.
    </>
);

const getPlanningPoolAddedMessage = (pack, productName, productionTerm) => (
    <>
        Basen&nbsp;
        <NameWrapper>{productName}</NameWrapper>
        &nbsp;został dodany do paczki&nbsp;
        <NameWrapper>{pack.name}</NameWrapper>
        &nbsp;na&nbsp;
        <NameWrapper>{productionTerm}</NameWrapper>.
    </>
);

const getProductionPoolAddedMessage = (productName, productionTerm) => (
    <>
        Basen&nbsp;
        <NameWrapper>{productName}</NameWrapper>
        &nbsp;został dodany na&nbsp;
        <NameWrapper>{productionTerm}</NameWrapper>.
    </>
);

const getStandardProductRemovedMessage = message => <>{message}</>;

const getStandardPackageChangeNotification = notification => ({
    ...notification,
    alert: PACKAGE_CHANGE_ALERT,
    message: getStandardPackageChangeMessage(notification.pack.name),
});

const getStandardProductChangeNotification = notification => ({
    ...notification,
    alert: PRODUCT_CHANGE_ALERT,
    message: getStandardProductChangeMessage(notification.productName),
});

const getPlanningPoolChangeNotification = notification => ({
    ...notification,
    alert: PACKAGE_CHANGE_ALERT,
    message: getPoolChangeMessage(notification.pack, notification.productName, notification.previousSchemaName),
});

const getProductionPoolChangeNotification = notification => ({
    ...notification,
    alert: PRODUCT_CHANGE_ALERT,
    message: getPoolChangeMessage(notification.pack, notification.productName, notification.previousSchemaName),
});

const getStandardProductAddedNotification = notification => ({
    ...notification,
    alert: PACKAGE_CHANGE_ALERT,
    message: getStandardProductAddedMessage(notification.pack, notification.productName),
});

const getPlanningPoolAddedNotification = notification => ({
    ...notification,
    alert: PACKAGE_CHANGE_ALERT,
    message: getPlanningPoolAddedMessage(notification.pack, notification.productName, notification.productionTerm),
});

const getProductionPoolAddedNotification = notification => ({
    ...notification,
    alert: PRODUCT_ADDED_ALERT,
    message: getProductionPoolAddedMessage(notification.productName, notification.productionTerm),
});

const getStandardProductRemovedNotification = notification => ({
    ...notification,
    alert: PRODUCT_REMOVED_ALERT,
    message: getStandardProductRemovedMessage(notification.additionalMessage),
});
