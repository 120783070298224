import styled from 'styled-components';
import { toRem } from '../../../../Styles/theme';

export const NotificationWrapper = styled.div`
    display: flex;

    padding: ${toRem(10)};
    border-radius: 5px;

    cursor: pointer;

    &:hover {
        background-color: lightgray;
    }
`;

export const NameWrapper = styled.div`
    max-width: 20vw;

    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
