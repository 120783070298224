import axios from 'axios';

import { API } from '../../Constants';
import { packageConstants } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const activateLoadingPackages = () => dispatch => {
    dispatch({
        type: packageConstants.REQUEST_PACKAGES,
    });
};
export const getPackages = (config, stage) => async dispatch => {
    try {
        const { data } = await axios.get(API.processPackages, config);
        dispatch({
            type: packageConstants.SUCCESS_RECEIVE_PACKAGES,
            payload: { packages: data, stage },
        });
    } catch (e) {
        console.log(e);
        if (e.response) checkStatus(e.response, dispatch);
        dispatch({
            type: packageConstants.FAIL_RECEIVE_PACKAGES,
        });
    }
};

export const pickPackage = packageId => dispatch => {
    dispatch({
        type: packageConstants.PICK_PACKAGE,
        payload: packageId,
    });
};

export const updatePackages = packages => dispatch => {
    dispatch({
        type: packageConstants.UPDATE_PACKAGES,
        payload: packages,
    });
};

export const pickProduct = productId => dispatch => {
    dispatch({
        type: packageConstants.PICK_PRODUCT,
        payload: productId,
    });
};

export const highlightProduct = (productId, packageId) => dispatch => {
    dispatch({
        type: packageConstants.HIGHLIGHT_PRODUCT,
        payload: { productId, packageId },
    });
};

export const unhighlightProduct = () => dispatch => {
    dispatch({
        type: packageConstants.UNHIGHLIGHT_PRODUCT,
    });
};

export const updateProductAmount = (productAmountId, packageId) => async dispatch => {
    try {
        const { data } = await axios.get(`${API.productAmount}?id=${productAmountId}`);
        return dispatch({
            type: packageConstants.UPDATE_PRODUCT_AMOUNT,
            payload: { packageId, productAmount: data },
        });
    } catch (e) {
        console.log(e);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const removeProductAmount = (productAmountId, packageId) => dispatch =>
    dispatch({
        type: packageConstants.REMOVE_PRODUCT_AMOUNT,
        payload: { productAmountId, packageId },
    });

export const getPackageInfo = packageId => async dispatch => {
    try {
        const { data } = await axios.get(`${API.processReservation}?packageId=${packageId}`);
        dispatch({
            type: packageConstants.RECEIVE_PACKAGE_INFO,
            payload: data,
        });
    } catch (e) {
        console.log(e);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const resetPackagesReducer = () => dispatch => {
    dispatch({
        type: packageConstants.RESET,
    });
};

export const showCalendarModal = todayProducts => dispatch => {
    dispatch({
        type: packageConstants.SHOW_CALENDAR_MODAL,
        payload: todayProducts,
    });
};

export const hideCalendarModal = () => dispatch => {
    dispatch({
        type: packageConstants.HIDE_CALENDAR_MODAL,
    });
};

export const toggleProductPrint = (productAmountId, packageId) => async dispatch => {
    try {
        await axios.post(API.togglePrinted, { id: productAmountId });
        dispatch({
            type: packageConstants.TOGGLE_PRINTED,
            payload: { productAmountId, packageId },
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};
