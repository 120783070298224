import { useEffect, useRef } from 'react';

const useUpdateEffect = (callback, deps) => {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }
        return callback();
    }, deps);
};

export default useUpdateEffect;
