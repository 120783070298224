import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import '../../Styles/ControlPanel.css';
import Select from 'react-select';
import RequiredFieldIndicator from '../RequiredFieldIndicator';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class WorkersPanel extends Component {
    render() {
        return (
            <div className='panel-div'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        surname: this.props.surname,
                        name: this.props.name,
                        language: this.props.language,
                        license: this.props.license,
                    }}
                    validationSchema={Yup.object().shape({
                        surname: Yup.string().required('To pole jest wymagane'),
                        name: Yup.string().required('To pole jest wymagane'),
                        language: Yup.string(),
                        license: Yup.mixed(),
                    })}
                    onSubmit={(fields, { resetForm }) => {
                        if (this.props.isRowClicked) {
                            this.props.handleEditClick(fields);
                        } else {
                            this.props.handleSubmit(fields);
                        }
                        resetForm();
                    }}
                    render={({ errors, status, touched, fields, values, setFieldValue }) => (
                        <Form>
                            <div className='form-group'>
                                <label htmlFor='surname'>Nazwisko</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='surname'
                                    placeholder='Wprowadź nazwisko'
                                    type='text'
                                    className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='surname' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='name'>Imię</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='name'
                                    placeholder='Wprowadź imię'
                                    type='text'
                                    className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='name' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='language'>Język</label>
                                <Field name='language' placeholder='Wprowadź język' type='text' className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='license'>Kat. prawa jazdy</label>

                                <Select
                                    name='license'
                                    value={values['license']}
                                    onChange={e => setFieldValue('license', e)}
                                    placeholder='Wybierz kat. prawa jazdy'
                                    isSearchable={true}
                                    options={this.props.licenseTypes.map(licenseType => ({
                                        value: licenseType.id,
                                        label: licenseType.type,
                                    }))}
                                />
                            </div>

                            {!this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                        Dodaj
                                    </button>
                                    <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                        Wyczyść
                                    </button>
                                </>
                            )}
                            {this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-primary' style={{ marginRight: '5%', width: '30%' }}>
                                        Zapisz
                                    </button>
                                    <Button
                                        onClick={this.props.handleCancelClick}
                                        variant='secondary'
                                        style={{ marginRight: '5%', width: '30%' }}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        onClick={this.props.handleDeleteClick}
                                        variant='danger'
                                        style={{ width: '30%' }}
                                        disabled={!this.props.isRowClicked}
                                    >
                                        Usuń
                                    </Button>
                                </>
                            )}
                        </Form>
                    )}
                />
            </div>
        );
    }
}

export default WorkersPanel;
