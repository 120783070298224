import axios from 'axios';
import { reservationsConstants, API } from '../../Constants';
import { PreprocessUtils } from '../../Utils/PreprocessResponse';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';

const preprocessTasks = tasks => {
    return PreprocessUtils.PreprocessReservationResponse(
        tasks.sort((a, b) => {
            const a_partsfromDate = a.fromDate.split('-');
            const b_partsfromDate = b.fromDate.split('-');

            const a_partstoDate = a.toDate.split('-');
            const b_partstoDate = b.toDate.split('-');

            const a_fromDate = new Date(a_partsfromDate[2], a_partsfromDate[1] - 1, a_partsfromDate[0]);
            const b_fromDate = new Date(b_partsfromDate[2], b_partsfromDate[1] - 1, b_partsfromDate[0]);

            const a_toDate = new Date(a_partstoDate[2], a_partstoDate[1] - 1, a_partstoDate[0]);
            const b_toDate = new Date(b_partstoDate[2], b_partstoDate[1] - 1, b_partstoDate[0]);

            return a_fromDate > b_fromDate ? 1 : b_fromDate > a_fromDate ? -1 : a_toDate > b_toDate ? 1 : b_toDate > a_toDate ? -1 : 0;
        })
    );
};

export const getReservations = () => dispatch => {
    axios
        .get(API.reservations)
        .then(res => {
            dispatch({
                type: reservationsConstants.GET,
                payload: preprocessTasks(res.data),
            });
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getArchiveTasks = () => async dispatch => {
    try {
        const { data } = await axios.get(API.reservations, {
            params: {
                daysLimit: 14,
                archive: true,
            },
        });

        dispatch({
            type: reservationsConstants.GET_ARCHIVE_TASKS,
            payload: preprocessTasks(data),
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const addReservation = (reservation, refresh) => dispatch => {
    axios
        .post(API.reservations, reservation)
        .then(res => {
            refresh();
            NotificationManager.success('Zadanie zostało dodane');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteReservation = (reservation, refresh) => dispatch => {
    axios
        .delete(API.reservations, { data: reservation })
        .then(res => {
            refresh();
            NotificationManager.success('Zadanie zostało usunięte');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editReservation = (reservation, refresh) => dispatch => {
    axios
        .patch(API.reservations, reservation)
        .then(res => {
            refresh();
            NotificationManager.success('Zadanie zostało zapisane');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const downloadReservations = () => dispatch => {
    axios({
        url: API.download,
        method: 'GET',
        responseType: 'blob',
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'tablica.csv');
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getReservationNotifications = () => async dispatch => {
    try {
        const res = await axios.get(API.notificationReservation);
        dispatch({ type: reservationsConstants.GET_NOTIFICATIONS, payload: res.data });
    } catch (e) {
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const removeReservationNotification = packageId => (dispatch, getState) => {
    const notifications = [...getState().reservations.notifications];

    const notificationIndex = notifications.findIndex(x => x.packageIds.includes(packageId));

    if (notificationIndex > -1) {
        const notification = notifications[notificationIndex];

        notification.packageIds = notification.packageIds.filter(x => x !== packageId);

        if (notification.packageIds.length === 0) {
            notifications.splice(notificationIndex, 1);
        }

        dispatch({
            type: reservationsConstants.GET_NOTIFICATIONS,
            payload: notifications,
        });
    }
};
