import styled from 'styled-components';
import { toRem } from '../../../../Styles/theme';
import { productItemDisabledStyles, productItemHighlightedStyles } from '../../production/production.styles';

export const ProductItem = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px ${props => props.borderColor || 'lightgrey'};

    cursor: pointer;

    ${props => props.picked && 'background: whitesmoke'};

    &:hover {
        background: whitesmoke;
    }

    ${props => props.disabled && productItemDisabledStyles};

    ${props => props.highlighted && productItemHighlightedStyles};
`;

export const ProductDetails = styled.div`
    font-weight: 600;
`;
