import * as dateFns from 'date-fns';
import { AVAILABLE_STAGES } from '../Constants/common';

export const removeDuplicates = (myArr, prop) =>
    myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);

export const haveRole = (user, role) => user.roles.map(x => x.name).includes(role);

export const isDateBetween = (date, startDate, endDate) =>
    dateFns.isWithinInterval(date, {
        start: startDate,
        end: endDate,
    });

export const formatDateForRequest = date => dateFns.format(date, 'yyyy-MM-dd', { awareOfUnicodeTokens: true });

export const getClientFromOrder = order => {
    return order.client ? order.client.name : order.oldClient ? order.oldClient : 'Brak klienta';
};

export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const value = row[id];

    if (value && value !== '') {
        return value.toLowerCase().includes(filter.value.toLowerCase());
    }

    return false;
};

export const getStageUrl = pathname => {
    const split = pathname.split('/');
    return split[split.length - 1];
};

export const getStageNameFromPathname = pathname => {
    switch (getStageUrl(pathname)) {
        case 'planning':
            return AVAILABLE_STAGES.PLANNING;
        case 'production':
            return AVAILABLE_STAGES.PRODUCTION;
        case 'tooling':
            return AVAILABLE_STAGES.TOOLING;
        case 'tech':
            return AVAILABLE_STAGES.TECH;
        case 'packing':
            return AVAILABLE_STAGES.PACKING;
        default:
            return '';
    }
};
