import styled, { css } from 'styled-components';
import { Button } from 'react-bootstrap';
import { toRem } from '../../../../../Styles/theme';

const COLORS = {
    ARROW_HOVER: '#0069ff',
    TEXT: 'black',
    BORDER: '#f1e8e8',
    BACKGROUND: 'whitesmoke',
    NEUTRAL: '#fff',
};

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    margin: 0;
    padding: 0;
`;

const Column = styled.div`
    flex-grow: 1;
    flex-basis: 0;

    width: 100%;
`;

export const Header = styled(Row)`
    justify-content: center;
    align-items: center;
    gap: ${toRem(30)};

    padding: ${toRem(5)};
    border-bottom: 1px solid ${COLORS.BORDER};

    text-transform: uppercase;
    font-weight: bold;
`;

export const DateWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: ${toRem(150)};
`;

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${toRem(30)};
    height: ${toRem(30)};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 70vw;
    height: 92vh;

    border-radius: 15px;
    border: 3px solid #a8a8a88c;

    background: ${COLORS.NEUTRAL};
    box-shadow: 12px 12px 12px 0 rgba(0, 0, 0, 0.575);
    color: ${COLORS.TEXT};
    overflow: hidden;
`;

export const Days = styled(Row)`
    padding: 0.75em 0;
    border-bottom: 1px solid ${COLORS.BORDER};

    text-transform: uppercase;
    font-weight: 400;
    color: ${COLORS.TEXT};
    font-size: 80%;

    gap: 10px;
`;

export const Day = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const Number = styled.span`
    position: absolute;
    top: 0.75em;
    right: 0.75em;

    font-size: 80%;
    line-height: 1;
    font-weight: 700;
`;

export const disabled = css`
    color: ${COLORS.TEXT};
    pointer-events: none;
`;

export const fromPast = css`
    background-color: ghostwhite;
`;

export const Cell = styled(Column)`
    position: relative;

    border-right: 1px solid ${COLORS.BORDER};
    height: 100%;

    overflow: hidden;
    background: ${COLORS.NEUTRAL};
    transition: 0.25s ease-out;

    &:hover {
        background: ${COLORS.BACKGROUND};
        transition: 0.5s ease-out;
    }

    ${props => props.disabled && disabled};

    ${props => props.fromPast && fromPast}
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ${Row} {
        flex-grow: 1;
        border-bottom: 1px solid ${COLORS.BORDER};
    }

    ${Row}:last-child {
        border-bottom: none;
    }

    ${Cell}:last-child {
        border-right: none;
    }
`;

export const PoolContainer = styled.div`
    position: absolute;
    top: 1.5em;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: ${toRem(5)};

    width: 100%;
    height: 90%;

    padding: 0 ${toRem(10)};

    overflow-y: auto;
    cursor: pointer;
`;

export const PoolName = styled.div`
    width: 100%;

    padding: 0 ${toRem(5)};
    border-radius: 5px;
    border: solid 1px ${props => props.borderColor || 'lightgrey'};
`;
