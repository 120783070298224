import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import { toRem } from '../Styles/theme';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
`;

const Image = styled.img`
    ${props => (props.customHeight ? `height: ${toRem(props.customHeight)}` : 'height: 15rem')};
    ${props => props.loading && 'display: none;'}
`;

const ImagePreview = ({ imageUrl, customHeight }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(false);
    }, [imageUrl]);

    const onError = () => {
        setLoading(false);
        setError(true);
    };

    return (
        <Wrapper>
            {loading && <CircularProgress />}
            {error ? (
                'Pogląd schematu jest niedostępny'
            ) : (
                <Image {...{ loading }} {...{ customHeight }} src={imageUrl} onLoad={() => setLoading(false)} {...{ onError }} />
            )}
        </Wrapper>
    );
};

export default ImagePreview;
