import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ROLES } from '../../Constants/roles';
import ChangePasswordPage from '../../pages/ChangePasswordPage';
import ClientsPage from '../../pages/ClientsPage';
import Login from '../../pages/login/login';
import OrdersPage from '../../pages/OrdersPage';
import Packing from '../../pages/production/packing/packing';
import Planning from '../../pages/production/planning/planning';
import Production from '../../pages/production/production/production';
import Tech from '../../pages/production/tech/tech';
import Tooling from '../../pages/production/tooling/tooling';
import ProductsPage from '../../pages/ProductsPage';
import ProvidersPage from '../../pages/ProvidersPage';
import Report from '../../pages/report/report.component';
import ReservationsPage from '../../pages/ReservationsPage';
import TrailersPage from '../../pages/TrailersPage';
import TrucksPage from '../../pages/TrucksPage';
import UsersPage from '../../pages/UsersPage';
import WorkersPage from '../../pages/WorkersPage';
import { history } from '../../Utils/History';
import NavigationBar from '../navigation-bar/navigation-bar';
import { PrivateRoute } from '../PrivateRoute';
import { ProtectedRoute } from './protected-route';
import RootPage from './root-page';
import { prepareUrl } from './routing-utils';

const Routing = () => {
    const { user, initLoading } = useSelector(store => store.auth);

    return (
        <Router history={history}>
            {!initLoading && user && user.roles && <NavigationBar />}
            <Switch>
                <PrivateRoute exact path={prepareUrl('/')} component={RootPage} />
                <ProtectedRoute path={prepareUrl('/tasks')} component={ReservationsPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/orders')} component={OrdersPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/workers')} component={WorkersPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/trucks')} component={TrucksPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/trailers')} component={TrailersPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/users')} component={UsersPage} roles={[ROLES.ADMIN]} />
                <PrivateRoute path={prepareUrl('/changePassword')} component={ChangePasswordPage} />
                <ProtectedRoute path={prepareUrl('/providers')} component={ProvidersPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/clients')} component={ClientsPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/products')} component={ProductsPage} roles={[ROLES.ADMIN, ROLES.USER]} />
                <ProtectedRoute path={prepareUrl('/planning')} component={Planning} roles={[ROLES.ADMIN, ROLES.PRODUCTION]} />
                <ProtectedRoute path={prepareUrl('/production')} component={Production} roles={[ROLES.ADMIN, ROLES.PRODUCTION]} />
                <ProtectedRoute path={prepareUrl('/tooling')} component={Tooling} roles={[ROLES.ADMIN, ROLES.TOOLING]} />
                <ProtectedRoute path={prepareUrl('/tech')} component={Tech} roles={[ROLES.ADMIN, ROLES.TECH]} />
                <ProtectedRoute path={prepareUrl('/packing')} component={Packing} roles={[ROLES.ADMIN, ROLES.PACKING]} />
                <ProtectedRoute path={prepareUrl('/reports')} component={Report} roles={[ROLES.ADMIN, ROLES.USER]} />
                <Route path={prepareUrl('/login')} component={Login} />
                <Route component={Login} />
            </Switch>
        </Router>
    );
};

export default Routing;
