export const getProvidersFromProduct = product => {
    return product && product.providers
        ? product.providers
              .filter(x => x.active)
              .map(provider => ({
                  value: provider.id,
                  label: provider.name,
              }))
        : [];
};

export const getProductProviders = (products, picked) => getProvidersFromProduct(products.find(product => product.id === picked.value));
