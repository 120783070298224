import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { fetchReport, resetReport } from './redux/report.actions';
import ReportPanel from './report-panel/report-panel.component';
import { REPORT_OPTIONS } from './report-panel/report-panel.constants';
import ReportTable from './report-table/report-table.component';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from './report.constants';
import { Container } from './report.styles';
import { getBaseUrl } from './report.utils';

const Report = () => {
    const [reportType, setReportType] = useState(REPORT_OPTIONS[0]);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentParams, setCurrentParams] = useState({});
    const [pageParams, setPageParams] = useState({});
    const [sort, setSort] = useState(DEFAULT_SORT);

    const reportData = useSelector(store => store.report.reportData);
    const dispatch = useDispatch();

    useEffect(() => {
        return dispatch(resetReport());
    }, []);

    useUpdateEffect(() => {
        fetchReportPage();
    }, [page, size, sort, pageParams]);

    const composeParams = () => ({
        ...pageParams,
        page,
        size,
        sort,
    });

    const resetPage = () => setPage(0);

    const fetchReportPage = () => dispatch(fetchReport(getBaseUrl(reportType.value), composeParams()));

    const getReportPageCustomParams = customParams => {
        setPageParams(pageParams => ({ ...pageParams, ...customParams }));
        resetPage();
    };

    const getReportPageForNewParams = () => {
        setPageParams(currentParams);
        resetPage();
    };

    const handleSizeChange = newSize => {
        setSize(newSize);
        resetPage();
    };

    const handleSortChange = newSort => {
        setSort(newSort);
        resetPage();
    };

    return (
        <Container>
            {reportData && (
                <ReportTable
                    reportType={reportType}
                    page={page}
                    size={size}
                    setPage={setPage}
                    setSize={handleSizeChange}
                    setSort={handleSortChange}
                />
            )}
            <ReportPanel
                reportType={reportType}
                setReportType={setReportType}
                getReportPageForNewParams={getReportPageForNewParams}
                getReportPageCustomParams={getReportPageCustomParams}
                setParams={setCurrentParams}
            />
        </Container>
    );
};

export default Report;
