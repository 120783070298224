import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SlidableModal from '../SlidableModal/SlidableModal';
import ProductList from './ProductList/ProductList';
import ProductConfigurator from './ProductConfigurator/ProductConfigurator';
import { addProduct, deleteProduct, editProduct, getProducts } from '../../Redux/Actions/OrdersActions';

export const availableModes = {
    LIST: 'LIST',
    CONFIGURATOR: 'CONFIGURATOR',
};

const OrderConfigurator = ({ show, id, handleToggleProductsModal, mode, setMode, productId, setProductId }) => {
    const [saveLoading, setSaveLoading] = useState(false);

    const dispatch = useDispatch();

    const products = useSelector(store => store.orders.products);

    const selectedProduct = products.find(product => product.id === productId);

    useEffect(() => {
        if (id !== -1) {
            dispatch(getProducts(id));
        }
    }, [id]);

    const refresh = () => dispatch(getProducts(id));

    const handleAddProductClick = () => {
        setMode(availableModes.CONFIGURATOR);
    };

    const handleOnBackClick = () => {
        setMode(availableModes.LIST);
        setProductId(-1);
    };

    const createPoolEquipment = configuration =>
        configuration.addons.map(addon => ({
            id: addon.id,
            amount:
                addon.category === 'POOL_OTHER' ? 1 : configuration.jsonData.filter(record => record.category === addon.category).length,
            productSchema: {
                id: addon.product.value,
            },
            provider: {
                id: addon.provider.value,
            },
            description: addon.description,
            category: addon.category,
            counter: addon.counter,
        }));

    const createPoolStructure = (jsonData, poolEquipment) => {
        const filtered = jsonData.filter(record => poolEquipment.some(addon => addon.category === record.category));

        const adjusted = filtered.filter(record => {
            if (record.category === 'POOL_OTHER') {
                return poolEquipment.some(eq => eq.counter === record.counter);
            }
            return true;
        });

        return JSON.stringify(adjusted);
    };

    const handleOnSaveClick = configuration => {
        const poolEquipment = configuration.category.value === 'POOL' ? createPoolEquipment(configuration) : [];

        const poolStructure = configuration.category.value === 'POOL' ? createPoolStructure(configuration.jsonData, poolEquipment) : null;

        const product = {
            productSchema: { id: configuration.product.value },
            order: { id },
            provider: { id: configuration.provider.value },
            description: configuration.description,
            amount: configuration.amount,
            filterRoomEquipment:
                configuration.category.value === 'FILTER_ROOM'
                    ? configuration.addons.map(addon => ({
                          id: addon.id,
                          amount: 1,
                          productSchema: {
                              id: addon.product.value,
                          },
                          provider: {
                              id: addon.provider.value,
                          },
                          description: addon.description,
                      }))
                    : [],
            poolStructure,
            poolEquipment,
        };

        setSaveLoading(true);
        if (productId !== -1) {
            dispatch(editProduct({ ...product, id: productId }, handleSuccessfulSave, handleFailedSave));
        } else {
            dispatch(addProduct(product, handleSuccessfulSave, handleFailedSave));
        }
    };

    const handleSuccessfulSave = () => {
        setSaveLoading(false);
        dispatch(getProducts(id));
        setMode(availableModes.LIST);
        setProductId(-1);
    };

    const handleFailedSave = () => {
        setSaveLoading(false);
    };

    const handleRemoveProductClick = productId => {
        dispatch(deleteProduct(productId, handleSuccessfulDelete));
    };

    const handleSuccessfulDelete = () => {
        dispatch(getProducts(id));
    };

    const handleEditProductClick = productId => {
        setProductId(productId);
        setMode(availableModes.CONFIGURATOR);
    };

    const productList = (
        <ProductList
            {...{ handleAddProductClick }}
            {...{ handleRemoveProductClick }}
            {...{ handleEditProductClick }}
            {...{ handleToggleProductsModal }}
            {...{ refresh }}
        />
    );

    const productConfigurator = (
        <ProductConfigurator
            orderId={id}
            {...{ handleOnBackClick }}
            {...{ handleOnSaveClick }}
            {...{ saveLoading }}
            {...{ selectedProduct }}
        />
    );

    const renderContent = () => {
        switch (mode) {
            case availableModes.LIST:
                return productList;
            case availableModes.CONFIGURATOR:
                return productConfigurator;
        }
    };

    return <SlidableModal {...{ show }}>{renderContent()}</SlidableModal>;
};

export default OrderConfigurator;
