import { trailerConstants } from '../../Constants';

const initialState = {
    trailers: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case trailerConstants.GET:
            return {
                ...state,
                trailers: action.payload,
            };
        default:
            return state;
    }
}
