import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { HeaderWrapper } from '../OrderConfigurator/styles';
import { ButtonWrapper, ProductHeader } from '../OrderConfigurator/ProductList/styles';
import { renderAddons } from './picklist';
import Loader from '../UI/Loader/loader';
import { LoaderWrapper, OrderTitle, PackageListWrapper, PackageTitle, PackageWrapper, ProductItem } from './packages.styles';
import { toRem } from '../../Styles/theme';
import DeletionModal from '../DeletionModal';
import { API } from '../../Constants';
import { getClientFromOrder, removeDuplicates } from '../../Utils/commons';
import { checkStatus } from '../../Utils/CheckStatus';
import { removeReservationNotification } from '../../Redux/Actions/ReservationsActions';

const PackageList = props => {
    const [packages, setPackages] = useState([]);
    const [loadingPackages, setLoadingPackages] = useState(true);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [showStartModal, setShowStartModal] = useState(false);
    const [id, setId] = useState(-1);
    const [deadline, setDeadline] = useState(new Date());
    const [loadingStartPackage, setLoadingStartPackage] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.show) {
            getPackages();
        }
    }, [props.show]);

    const getPackages = async () => {
        try {
            const res = await axios.get(`${API.productPackage}?reservationId=${props.reservationId}`);
            setPackages(res.data);
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }
        setLoadingPackages(false);
    };

    const deletePackage = async () => {
        try {
            await axios.delete(API.productPackage, { data: { id } });
            NotificationManager.success('Paczka została usunięta');
            dispatch(removeReservationNotification(id));
            getPackages();
        } catch (e) {
            console.log(e.response);
            NotificationManager.error('Błąd podczas usuwania paczki');
            if (e.response) checkStatus(e.response, dispatch);
        }
    };

    const clearFields = () => {
        setShowStartModal(false);
        setId(-1);
        setDeadline(new Date());
    };

    const startPackage = async () => {
        setLoadingStartPackage(true);
        try {
            await axios.post(`${API.processStart}/${id}`, null, { params: { deadline: deadline.toISOString().split('T')[0] } });
            dispatch(removeReservationNotification(id));
            NotificationManager.success('Paczka została wysłana');
            getPackages();
            clearFields();
        } catch (e) {
            NotificationManager.error('Błąd podczas wysyłania paczki');
            if (e.response) checkStatus(e.response, dispatch);
        }
        setLoadingStartPackage(false);
    };

    const handleStartClick = id => {
        setShowStartModal(true);
        setId(id);
        setDeadline(new Date());
    };

    const handleStartModalClose = () => {
        clearFields();
    };

    const handleDeleteClick = id => {
        setShowDeletionModal(true);
        setId(id);
    };

    const handleModalClose = () => {
        clearFields();
    };

    const handleRemoveClick = () => {
        deletePackage(id);
        setShowDeletionModal(false);
        setId(-1);
    };

    const disableStartPackage = pack => {
        const stages = pack.products.map(product => product.processStage.stage);

        return (
            stages.includes('PLANNING') ||
            stages.includes('PRODUCTION') ||
            stages.includes('TOOLING') ||
            stages.includes('TECH') ||
            stages.includes('PACKING')
        );
    };

    return (
        <>
            <DeletionModal
                show={showDeletionModal}
                onHide={handleModalClose}
                {...{ handleRemoveClick }}
                text='Czy na pewno chcesz usunąć tę paczkę?'
            />
            <Modal show={showStartModal} onHide={handleStartModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Wysyłanie na produkcję</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label htmlFor='deadline'>Termin</label>

                        <DateTimePicker
                            name='deadline'
                            value={deadline}
                            messages={{ dateButton: 'Wybierz termin' }}
                            culture='pl'
                            placeholder='Wybierz termin'
                            time={false}
                            onChange={e => {
                                setDeadline(e);
                            }}
                            min={new Date()}
                            inputProps={{
                                component: props => <input {...props} readOnly />,
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleStartModalClose}>
                        Anuluj
                    </Button>
                    {loadingStartPackage ? (
                        <Loader size={toRem(40)} />
                    ) : (
                        <Button variant='primary' onClick={startPackage}>
                            Wyślij
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <HeaderWrapper>
                <Button onClick={props.handleAddPackageClick}>Dodaj paczkę</Button>
                Zarządzanie paczkami
                <Button onClick={props.handleTogglePackageModal} variant={'secondary'}>
                    Zamknij
                </Button>
            </HeaderWrapper>
            {loadingPackages ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : packages.length > 0 ? (
                <PackageListWrapper>
                    {packages.map((pack, i) => {
                        const ordersInPackage = removeDuplicates(
                            pack.products.map(product => product.product.order),
                            'id'
                        );

                        const disableDeletePack = pack.deadline && !pack.deadlineProposition;

                        return (
                            <PackageWrapper key={i} borderColor={pack.deadlineProposition ? 'red' : null}>
                                <ProductHeader>
                                    <PackageTitle>
                                        {pack.name} {pack.deadline && `(${pack.deadline})`}{' '}
                                        {pack.deadlineProposition &&
                                            `- Paczka została odrzucona, zaproponowany został termin: ${pack.deadlineProposition}`}
                                    </PackageTitle>
                                    <ButtonWrapper>
                                        <Button
                                            onClick={() => handleStartClick(pack.id)}
                                            variant='info'
                                            disabled={disableStartPackage(pack)}
                                        >
                                            Wyślij na produkcję
                                        </Button>
                                        <Button
                                            style={{ width: toRem(100) }}
                                            onClick={() => {
                                                props.handleOnEditClick(pack);
                                            }}
                                        >
                                            Edytuj
                                        </Button>
                                        <Button
                                            disabled={disableDeletePack}
                                            style={{ width: toRem(100) }}
                                            variant='danger'
                                            onClick={() => handleDeleteClick(pack.id)}
                                        >
                                            Usuń
                                        </Button>
                                    </ButtonWrapper>
                                </ProductHeader>
                                {ordersInPackage.map((orderInPackage, i) => (
                                    <>
                                        <OrderTitle>
                                            Zamówienie #{i + 1}: {getClientFromOrder(orderInPackage)} - {orderInPackage.description}
                                        </OrderTitle>
                                        {pack.products
                                            .filter(product => product.product.order.id === orderInPackage.id)
                                            .map((product, index) => {
                                                return (
                                                    <ProductItem key={index}>
                                                        <ProductHeader>
                                                            {`${product.product.productSchema.name}${
                                                                product.product.provider ? ` (${product.product.provider.name})` : ''
                                                            } (szt. ${product.amount}) (${product.processStage.label}) ${
                                                                product.product.description ? `- ${product.product.description}` : ''
                                                            }`}
                                                        </ProductHeader>
                                                        {renderAddons(product.product)}
                                                    </ProductItem>
                                                );
                                            })}
                                    </>
                                ))}
                            </PackageWrapper>
                        );
                    })}
                </PackageListWrapper>
            ) : (
                <LoaderWrapper>Brak paczek</LoaderWrapper>
            )}
        </>
    );
};

export default PackageList;
