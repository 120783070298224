import styled, { css } from 'styled-components';

import { toRem } from '../../../Styles/theme';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    cursor: pointer;
    color: gray;

    ${props => props.active && activeTabStyles};
`;

export const activeTabStyles = css`
    color: black;
    font-weight: bold;
`;
