import React, { useState } from 'react';

import SlidableModal from '../SlidableModal/SlidableModal';
import PackageList from './package-list';
import PackageConfigurator from './package-configurator';

export const availableModes = {
    LIST: 'LIST',
    CONFIGURATOR: 'CONFIGURATOR',
};

const PackageModal = ({ show, reservationId, handleTogglePackageModal, mode, setMode, editedPack, setEditedPack, showArchiveTasks }) => {
    const handleAddPackageClick = () => setMode(availableModes.CONFIGURATOR);

    const handleOnBackClick = () => {
        setMode(availableModes.LIST);
        setEditedPack(undefined);
    };

    const handleOnEditClick = pack => {
        setMode(availableModes.CONFIGURATOR);
        setEditedPack(pack);
    };

    const packageList = (
        <PackageList
            {...{ show }}
            {...{ reservationId }}
            {...{ handleTogglePackageModal }}
            {...{ handleAddPackageClick }}
            {...{ handleOnEditClick }}
        />
    );

    const packageConfigurator = (
        <PackageConfigurator {...{ handleOnBackClick }} {...{ reservationId }} {...{ editedPack }} showArchiveTasks={showArchiveTasks} />
    );

    const renderContent = () => {
        switch (mode) {
            case availableModes.LIST:
                return packageList;
            case availableModes.CONFIGURATOR:
                return packageConfigurator;
        }
    };

    return <SlidableModal {...{ show }}>{renderContent()}</SlidableModal>;
};

export default PackageModal;
