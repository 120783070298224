import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './ChangePasswordForm.css';

const ChangePasswordForm = props => {
    return (
        <div className='ChangePasswordForm'>
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .required('To pole jest wymagane')
                        .min(8, 'Hasło jest za krótkie')
                        .max(30, 'Hasło jest za długie')
                        .matches(/(?=.*[a-z])/, 'Hasło musi zawierać co najmniej jedną małą literę')
                        .matches(/(?=.*[A-Z])/, 'Hasło musi zawierać co najmniej jedną dużą literę')
                        .matches(/(?=.*[0-9])/, 'Hasło musi zawierać co najmniej jedną liczbę')
                        .matches(/(?=.[!@#$%^&])/, 'Hasło musi zawierać co najmniej jeden znak specjalny (!,@,#,$,%,^,&)'),
                    confirmPassword: Yup.string()
                        .required('To pole jest wymagane')
                        .oneOf([Yup.ref('password'), null], 'Wprowadzone hasła są różne'),
                    role: Yup.mixed().notOneOf([-1], 'To pole jest wymagane'),
                })}
                onSubmit={(fields, { resetForm }) => {
                    props.handleSubmit(fields);
                    resetForm();
                }}
                render={({ errors, status, touched }) => (
                    <Form>
                        <h3 style={{ textAlign: 'center' }}>Zmiana hasła</h3>
                        <div className='form-group'>
                            <label htmlFor='password'>Nowe hasło</label>
                            <Field
                                name='password'
                                type='password'
                                placeholder='Wprowadź hasło'
                                className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='password' component='div' className='invalid-feedback' />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='confirmPassword'>Powtórz hasło</label>
                            <Field
                                name='confirmPassword'
                                type='password'
                                placeholder='Powtórz hasło'
                                className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='confirmPassword' component='div' className='invalid-feedback' />
                        </div>
                        <div className='form-group'>
                            <button type='submit' className='btn btn-primary btn-block'>
                                Wyślij
                            </button>
                            {/* <button type="reset" className="btn btn-secondary">
                Reset
              </button> */}
                        </div>
                    </Form>
                )}
            />
        </div>
    );
};

export default ChangePasswordForm;
