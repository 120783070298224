import { authConstants } from '../Constants';
import { NotificationManager } from 'react-notifications';

export const checkStatus = (res, dispatch) => {
    switch (res.status) {
        case 403:
            NotificationManager.error(`Operacja niedozwolona`);
            dispatch({ type: authConstants.LOGOUT });
            break;
        case 500:
            if (res.data.toString().includes('proxy')) {
                NotificationManager.error(`Brak połączenia z serwerem`);
                dispatch({ type: authConstants.LOGOUT });
            } else {
                NotificationManager.error(`Błąd serwera`);
            }
            break;
        default:
            NotificationManager.error(`Operacje się nie powiodła`);
    }
};
