import styled, { css } from 'styled-components';
import { toRem } from '../../../Styles/theme';
import { Panel } from '../planning/planning.styles';
import { productItemHighlightedStyles } from '../production/production.styles';

export const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: ${toRem(10)};

    padding: ${toRem(10)};
`;

export const listStyles = css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: ${toRem(10)};

    width: 35vw;

    overflow-y: auto;
`;

export const PackagesList = styled.div`
    ${listStyles};
`;

export const DetailsWrapper = styled.div`
    ${listStyles};

    width: 57vw;
`;

export const PackageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const PackageTitle = styled.div`
    font-weight: bold;
`;

export const PackageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.gap};

    width: 100%;

    border-radius: 5px;
    border: solid 1px lightgrey;
    padding: ${toRem(10)};

    cursor: pointer;

    ${props => props.picked && 'background: whitesmoke'};

    &:hover {
        background: whitesmoke;
    }
`;

export const OrderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    width: 100%;
`;

export const ProductItem = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px lightgrey;

    cursor: pointer;

    ${props => props.highlighted && productItemHighlightedStyles};
`;

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
`;

export const StyledText = styled.text`
    font-weight: 500;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;

    font-weight: bold;
`;

export const FixedPanel = styled(Panel)`
    display: flex;
    flex-direction: column;
    gap: ${toRem(15)};

    height: 90vh;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: lightgrey;
`;

export const Addons = styled.ul`
    margin: 0;
`;
