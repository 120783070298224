import { licenseTypesConstants } from '../../Constants';

const initialState = {
    licenseTypes: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case licenseTypesConstants.GET:
            return {
                ...state,
                licenseTypes: action.payload,
            };
        default:
            return state;
    }
}
