import styled from 'styled-components';

import { toRem } from '../../../Styles/theme';

export const FieldsRow = styled.div`
    display: flex;
    margin: ${toRem(10)} 0;
    width: 100%;
    align-items: center;
    gap: ${toRem(10)};
`;

export const FormItem = styled.div`
    ${props => `flex-grow: ${props.flexGrow || 1}`};
    ${props => props.width && `width: ${props.width}`};
`;

export const VerticalDivider = styled.div`
    width: 100%;
    height: 1px;
    background: lightgrey;
`;

export const NoBuilder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    height: 70%;
`;
