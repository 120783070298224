import React from 'react';
import * as dateFns from 'date-fns';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
    Body,
    Cell,
    Container,
    Day,
    Days,
    Header,
    Number,
    PoolContainer,
    PoolName,
    Row,
    StyledButton,
    DateWrapper,
} from './calendar.styles';
import { getMonthName } from './calendar.utils';

export const Calendar = ({ currentMonth, nextMonthClick, prevMonthClick, plannedProducts, onCellClick }) => {
    const renderHeader = () => {
        return (
            <Header>
                <StyledButton onClick={prevMonthClick}>
                    <ChevronLeftIcon />
                </StyledButton>
                <DateWrapper>{`${getMonthName(dateFns.format(currentMonth, 'M'))} ${dateFns.format(currentMonth, 'yyyy')}`}</DateWrapper>
                <StyledButton onClick={nextMonthClick}>
                    <ChevronRightIcon />
                </StyledButton>
            </Header>
        );
    };

    const renderDays = () => {
        const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

        return (
            <Days>
                {days.map(day => (
                    <Day>{day}</Day>
                ))}
            </Days>
        );
    };

    const renderCells = () => {
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });

        const dateFormat = 'd';
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = '';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);

                const todayProducts = plannedProducts.filter(product => dateFns.isSameDay(new Date(product.productionTerm), day));
                const disabled = !dateFns.isSameMonth(day, monthStart);
                const formPast = new Date() > day && !dateFns.isSameDay(new Date(), day);

                days.push(
                    <Cell key={day} disabled={disabled} fromPast={formPast}>
                        {dateFns.isSameMonth(day, monthStart) && <Number className='number'>{formattedDate}</Number>}
                        {todayProducts.length > 0 && (
                            <PoolContainer onClick={() => onCellClick(todayProducts)}>
                                {todayProducts.map(product => (
                                    <PoolName>{product.product.productSchema.name}</PoolName>
                                ))}
                            </PoolContainer>
                        )}
                    </Cell>
                );
                day = dateFns.addDays(day, 1);
            }

            rows.push(<Row key={day}>{days}</Row>);
            days = [];
        }
        return <Body>{rows}</Body>;
    };

    return (
        <Container>
            {renderHeader()}
            {renderDays()}
            {renderCells()}
        </Container>
    );
};
