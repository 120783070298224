import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import { toRem } from '../../../Styles/theme';
import { productItemHighlightedStyles } from '../production/production.styles';

export const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: ${toRem(10)};

    padding: ${toRem(10)};
`;

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    height: 92vh;

    padding: 10px;
    border-radius: 15px;
    border: 3px solid #a8a8a88c;

    background: white;
    box-shadow: 12px 12px 12px 0 rgba(0, 0, 0, 0.575);
`;

export const PlanningList = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: ${toRem(10)};

    width: 500px;

    overflow-y: auto;
`;

export const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${toRem(35)};
    height: ${toRem(35)};
    border-radius: 50%;
`;

export const PackHeader = styled.div`
    text-align: justify;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const ProductHeader = styled.div`
    text-align: justify;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const ProductName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${toRem(10)};
    width: 100%;
    margin-bottom: ${toRem(5)};
`;

export const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;

    font-weight: bold;
`;

export const DatePickers = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};
`;

export const DatePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${toRem(10)};

    border-radius: ${toRem(5)};

    ${props => props.highlighted && productItemHighlightedStyles};
`;

export const DateTimePickerWrapper = styled.div`
    max-width: ${toRem(150)};
    min-width: ${toRem(150)};
`;
