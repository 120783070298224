import { Button, Form, InputGroup } from 'react-bootstrap';
import logo from '../Images/logo.png';
import '../Styles/Login.css';

import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

class LoginForm extends Component {
    state = { username: '', password: '', isLoading: false };

    componentDidMount() {
        this.loginInput.focus();
    }

    turnOffSpinner = () => {
        this.setState({ isLoading: false });
    };

    clearFields = () => {
        this.setState({
            username: '',
            password: '',
            isLoading: false,
        });
    };

    handleOnSubmit = e => {
        this.setState({ isLoading: true });
        e.preventDefault();
        this.props.onSubmit(this.state.username, this.state.password, this.turnOffSpinner);
        // this.clearFields();
        this.loginInput.focus();
    };

    render() {
        return (
            <div className='Login'>
                <Form onSubmit={this.handleOnSubmit}>
                    <img className='loginFormLogo' src={logo} alt='Europool' />
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className='fas fa-user' />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                ref={input => {
                                    this.loginInput = input;
                                }}
                                type='text'
                                placeholder='Login'
                                value={this.state.username}
                                onChange={e => this.setState({ username: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className='fas fa-lock' />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type='password'
                                placeholder='Hasło'
                                value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                        </InputGroup>
                    </Form.Group>

                    {this.state.isLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress size={35} />
                        </div>
                    ) : (
                        <Button variant='primary' type='submit'>
                            Zaloguj
                        </Button>
                    )}
                </Form>
            </div>
        );
    }
}

export default LoginForm;
