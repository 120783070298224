export const CONTENT_REFRESH_INTERVAL = 60000;
export const PRODUCT_HIGHLIGHT_TIMEOUT = 3000;

export const AVAILABLE_STAGES = {
    BOARD: 'BOARD',
    PLANNING: 'PLANNING',
    PRODUCTION: 'PRODUCTION',
    TOOLING: 'TOOLING',
    TECH: 'TECH',
    PACKING: 'PACKING',
};
