import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { toRem } from '../../../../Styles/theme';
import { Panel } from '../../planning/planning.styles';
import { listStyles } from '../tech.styles';

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    font-weight: bold;
`;

export const PrintButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${toRem(40)};
    height: ${toRem(30)};

    @media print {
        opacity: 0;
    }
`;

export const FixedPanel = styled(Panel)`
    gap: ${toRem(15)};
    height: 90vh;

    @media print {
        width: 100%;
        height: 100vh;
        padding: ${toRem(40)};
        border-radius: 0;
        border: none;
        box-shadow: none;
    }
`;

export const DetailsWrapper = styled.div`
    ${listStyles};

    width: 57vw;

    @media print {
        width: 100%;
    }
`;
