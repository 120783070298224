import axios from 'axios';
import { usersConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';

export const getUsers = () => async dispatch => {
    try {
        const res = await axios.get(API.users);
        return dispatch({
            type: usersConstants.GET,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const addUser = (user, refresh) => dispatch => {
    axios
        .post(API.users, user)
        .then(res => {
            refresh();
            NotificationManager.success('Użytkownik został dodany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editUser = (user, refresh) => dispatch => {
    axios
        .patch(API.users, user)
        .then(res => {
            refresh();
            NotificationManager.success('Użytkownik został zapisany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
