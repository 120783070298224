import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { productConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getProductCategories = () => async dispatch => {
    dispatch({ type: productConstants.LOADING_CATEGORIES });
    try {
        const { data } = await axios.get(API.productCategory);
        return dispatch({
            type: productConstants.GET_CATEGORIES,
            payload: data.sort((a, b) => a.label.localeCompare(b.label)),
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const getProducts = archive => async dispatch => {
    try {
        const url = archive ? `${API.product}?active=false` : API.product;
        const { data } = await axios.get(url);
        const type = archive ? productConstants.GET_ARCHIVE : productConstants.GET;
        return dispatch({
            type: type,
            payload: data.sort((a, b) => a.name.localeCompare(b.name)),
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const addProduct = (product, refresh, schema) => async dispatch => {
    try {
        const response = await axios.post(API.product, product);
        if (schema) await patchSchema(response.data, schema);
        refresh();
        NotificationManager.success('Produkt został dodany');
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const deleteProduct = (product, refresh, literal) => dispatch => {
    axios
        .patch(API.toggleProduct, product)
        .then(_ => {
            refresh();
            NotificationManager.success(`Produkt został ${literal}`);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editProduct = (product, refresh, schema) => async dispatch => {
    try {
        await axios.patch(API.product, product);
        if (schema) await patchSchema(product.id, schema);
        refresh();
        NotificationManager.success('Produkt został zapisany');
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

const patchSchema = async (productId, schema) => {
    const body = new FormData();
    body.append('id', productId);
    body.append('poolSchema', schema);

    try {
        const _ = await axios({
            method: 'PATCH',
            url: API.productSchema,
            data: body,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    } catch (err) {
        console.log(err);
        NotificationManager.error('Schemat nie został zapisany');
    }
};
