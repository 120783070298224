export const REPORT_TYPES = {
    PRODUCTS: 'PRODUCTS',
};

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_SORT = 'productName,ASC';

export const REQUEST_REPORT = 'REQUEST_REPORT';
export const SUCCESSFUL_RECEIVED_REPORT = 'SUCCESSFUL_RECEIVED_REPORT';
export const FAILED_RECEIVED_REPORT = "FAILED_RECEIVED_REPORT"
export const RESET_REPORT = "RESET_REPORT";
