import styled from 'styled-components';

import { toRem } from '../../Styles/theme';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    font-size: ${toRem(20)};
    text-align: center;
`;

export const EmptyMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    text-align: center;
    color: grey;
`;
