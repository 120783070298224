import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeView, deleteUser } from '../../Redux/Actions/AuthActions';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import * as dateFns from 'date-fns';

import {
    Dropdown,
    DropdownItem,
    DropdownContent,
    LoggedUserInfo,
    LogoutButton,
    NavBar,
    StyledNavLink,
    UtilsWrapper,
} from './navigation-bar.styles';
import { getAvailableRoutes } from './navigation-bar.utils';
import { availableViews } from '../../Redux/Reducers/AuthReducer';
import { getStageNameFromPathname, haveRole } from '../../Utils/commons';
import { prepareUrl } from '../routing/routing-utils';
import Notifications from './notifications/notifications';
import useIncomingNotifications from '../../hooks/useIncomingNotifications';
import { API } from '../../Constants';
import { highlightProduct, resetPackagesReducer, showCalendarModal } from '../../Redux/Actions/package.actions';
import { checkStatus } from '../../Utils/CheckStatus';
import { ROLES } from '../../Constants/roles';
import { setNotifications } from '../../Redux/Actions/notification.actions';
import { AVAILABLE_STAGES } from '../../Constants/common';
import useNotificationsFactory, { AVAILABLE_TOPICS } from './notifications/notificationsFactory/useNotificationsFactory';

const NavigationBar = () => {
    const [pendingNotifications, setPendingNotifications] = useState([]);
    const [incomingNotifications, setIncomingNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { createNotification } = useNotificationsFactory();

    const {
        incomingNotifications: incomingNotificationsFromHook,
        clearIncomingNotifications,
        removeIncomingNotification,
    } = useIncomingNotifications();

    const { user, view } = useSelector(store => store.auth);
    const { notifications } = useSelector(store => store.notifications);
    const { loadingPackages } = useSelector(store => store.packages);
    const plannedProducts = useSelector(store =>
        store.packages.packages
            .map(pack => pack.products)
            .reduce((result, current) => [...result, ...current], [])
            .filter(product => product.processStage.stage === 'PRODUCTION')
    );

    const isAdmin = haveRole(user, ROLES.ADMIN);
    const isUser = haveRole(user, ROLES.USER);

    useEffect(() => {
        setIncomingNotifications([...getIncomingNotificationsForStage(incomingNotificationsFromHook)]);
    }, [location.pathname, incomingNotificationsFromHook]);

    useEffect(() => {
        if (ifFetchNotifications()) {
            fetchPendingNotifications();
            clearIncomingNotifications();
        }
    }, [location.pathname, loadingPackages]);

    useEffect(() => {
        dispatch(setNotifications([...pendingNotifications, ...incomingNotifications]));
    }, [pendingNotifications, incomingNotifications]);

    const getIncomingNotificationsForStage = incomingNotifications => {
        const stage = getStageNameFromPathname(location.pathname);
        return incomingNotifications.filter(x => x.stage === stage);
    };

    const preprocessPendingNotifications = notifications => {
        const preprocessed = [];
        notifications.forEach(notification => {
            const stage = getStageNameFromPathname(location.pathname);
            const fromFactory = createNotification({ ...notification, stage });
            if (fromFactory) {
                preprocessed.push(fromFactory);
            }
        });
        return preprocessed;
    };

    const fetchPendingNotifications = async () => {
        try {
            const { data } = await axios.get(`${API.notificationProduction}?stage=${getStageNameFromPathname(location.pathname)}`);
            setPendingNotifications(preprocessPendingNotifications(data));
        } catch (e) {
            console.log(e);
            NotificationManager.error('Błąd podczas pobierania powiadomień');
            if (e.response) checkStatus(e.response, dispatch);
        }
    };

    const deactivateNotification = async id => {
        try {
            setAnchorEl(null);
            const { packageId, productAmountId, notificationTopic } = notifications.find(x => x.id === id);
            await axios.patch(`${API.notificationDeactivate}?id=${id}`);

            if (notificationTopic !== AVAILABLE_TOPICS.REMOVE) {
                dispatch(highlightProduct(productAmountId, packageId));
                if (getStageNameFromPathname(location.pathname) === AVAILABLE_STAGES.PLANNING) {
                    handleShowPlanningNotification(id, packageId, productAmountId);
                }
            }

            removeNotification(id);
        } catch (e) {
            console.log(e);
            NotificationManager.error('Błąd podczas odhaczania powiadomienia');
            if (e.response) checkStatus(e.response, dispatch);
        }
    };

    const handleShowPlanningNotification = (notificationId, packageId, productAmountId) => {
        const notificationProduct = plannedProducts.find(x => x.id === productAmountId);
        const today = new Date(notificationProduct.productionTerm);
        const todayProducts = plannedProducts.filter(x => dateFns.isSameDay(new Date(x.productionTerm), today));
        dispatch(showCalendarModal(todayProducts));
    };

    const removeNotification = id => {
        const isFromPending = pendingNotifications.find(x => x.id === id);
        isFromPending ? setPendingNotifications(notifications => notifications.filter(x => x.id !== id)) : removeIncomingNotification(id);
    };

    const handleOnLogoutClick = async () => {
        dispatch(resetPackagesReducer());
        await dispatch(deleteUser());
        history.push(prepareUrl('/'));
    };

    const handleChangeView = view => {
        dispatch(changeView(view));
        history.push(prepareUrl('/'));
    };

    const ifFetchNotifications = () =>
        !isAdmin && !isUser && location.pathname !== prepareUrl('/') && location.pathname !== prepareUrl('/login') && !loadingPackages;

    const documentTitle = notifications.length > 0 ? `(${notifications.length}) EuroBoard` : 'EuroBoard';

    return (
        <NavBar>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <div>
                {getAvailableRoutes(user, view).map((route, index) => (
                    <StyledNavLink
                        key={index}
                        exact={route.exact}
                        to={route.to}
                        activeStyle={{
                            backgroundColor: '#0159b8',
                            color: 'white',
                        }}
                    >
                        {route.label}
                    </StyledNavLink>
                ))}
            </div>

            <UtilsWrapper>
                {!isAdmin && !isUser && (
                    <Notifications {...{ notifications }} {...{ deactivateNotification }} {...{ anchorEl }} {...{ setAnchorEl }} />
                )}

                {isAdmin && (
                    <Dropdown>
                        <DropdownItem>
                            Widok&nbsp;&nbsp;
                            <i className='fa fa-caret-down' />
                        </DropdownItem>
                        <DropdownContent>
                            {Object.keys(availableViews).map((availableView, i) => {
                                const value = availableViews[availableView];
                                return (
                                    <DropdownItem key={i} active={view === value} onClick={() => handleChangeView(value)}>
                                        {value}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownContent>
                    </Dropdown>
                )}

                <LoggedUserInfo>
                    Zalogowano jako: {user.name} {user.surname}
                </LoggedUserInfo>

                <LogoutButton onClick={handleOnLogoutClick}>Wyloguj</LogoutButton>
            </UtilsWrapper>
        </NavBar>
    );
};

export default NavigationBar;
