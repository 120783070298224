import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DateTimePicker } from 'react-widgets';
import { FieldsRow, FormItem } from '../../../../../Components/OrderConfigurator/ProductConfigurator/styles';
import RequiredFieldIndicator from '../../../../../Components/RequiredFieldIndicator';
import Loader from '../../../../../Components/UI/Loader/loader';
import useDebouncedState from '../../../../../hooks/useDebouncedState';
import useUpdateEffect from '../../../../../hooks/useUpdateEffect';
import { toRem } from '../../../../../Styles/theme';
import { formatDateForRequest } from '../../../../../Utils/commons';
import { LoaderWrapper } from '../report-forms.styles';
import { mapByValue } from './products-form.utils';

const ProductsFrom = ({ getReportPageForNewParams, getReportPageCustomParams, setParams }) => {
    const [dateFrom, setDateFrom] = useState(dateFns.subMonths(new Date(), 1));
    const [dateTo, setDateTo] = useState(new Date());
    const [categories, setCategories] = useState([]);
    const [providers, setProviders] = useState([]);
    const [clients, setClients] = useState([]);
    const [name, setName, debouncedName] = useDebouncedState('');

    const allCategories = useSelector(store => store.product.mainCategories);
    const allProviders = useSelector(store => store.provider.providers);
    const allClients = useSelector(store => store.client.clients);
    const loading = useSelector(store => store.report.loading);
    const reportData = useSelector(store => store.report.reportData);

    useEffect(() => {
        setParams(createParams());
    }, [dateFrom, dateTo, categories, providers, clients, name]);

    useUpdateEffect(() => {
        reportData && getReportPageCustomParams({ productName: debouncedName || undefined });
    }, [debouncedName]);

    const createParams = () => ({
        from: formatDateForRequest(dateFrom),
        to: formatDateForRequest(dateTo),
        productName: name || undefined,
        categoryIds: mapByValue(categories),
        providerIds: mapByValue(providers),
        clientIds: mapByValue(clients),
    });

    const loadingButton = loading ? (
        <LoaderWrapper>
            <Loader size={toRem(40)} />
        </LoaderWrapper>
    ) : (
        <Button onClick={getReportPageForNewParams}>Generuj raport</Button>
    );

    return (
        <>
            <FieldsRow>
                <FormItem>
                    <label>Kategorie</label>
                    <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder='Filtruj po kategoriach'
                        options={allCategories.map(category => ({
                            value: category.id,
                            label: category.label,
                        }))}
                        value={categories}
                        onChange={setCategories}
                    />
                </FormItem>
            </FieldsRow>
            <FieldsRow>
                <FormItem>
                    <label>Data od</label>
                    <RequiredFieldIndicator />
                    <DateTimePicker
                        messages={{ dateButton: 'Wybierz datę od' }}
                        culture='pl'
                        placeholder='Wybierz datę od'
                        time={false}
                        max={dateTo}
                        value={dateFrom}
                        onSelect={setDateFrom}
                        inputProps={{
                            component: props => <input {...props} readOnly />,
                        }}
                    />
                </FormItem>
            </FieldsRow>
            <FieldsRow>
                <FormItem>
                    <label>Data do</label>
                    <RequiredFieldIndicator />
                    <DateTimePicker
                        messages={{ dateButton: 'Wybierz datę do' }}
                        culture='pl'
                        placeholder='Wybierz datę do'
                        time={false}
                        min={dateFrom}
                        onSelect={setDateTo}
                        value={dateTo}
                        inputProps={{
                            component: props => <input {...props} readOnly />,
                        }}
                    />
                </FormItem>
            </FieldsRow>

            <FieldsRow>
                <FormItem>
                    <label>Dostawcy</label>
                    <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder='Filtruj po dostawcach'
                        options={allProviders.map(provider => ({
                            value: provider.id,
                            label: provider.name,
                        }))}
                        value={providers}
                        onChange={setProviders}
                    />
                </FormItem>
            </FieldsRow>
            <FieldsRow>
                <FormItem>
                    <label>Odbiorcy</label>
                    <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder='Filtruj odbiorcach'
                        options={allClients.map(client => ({
                            value: client.id,
                            label: client.shortName,
                        }))}
                        value={clients}
                        onChange={setClients}
                    />
                </FormItem>
            </FieldsRow>
            <FieldsRow>
                <FormItem>
                    <label>Nazwa</label>
                    <Form.Control type='text' placeholder='Wprowadź nazwę' value={name} onChange={e => setName(e.target.value)} />
                </FormItem>
            </FieldsRow>
            {loadingButton}
        </>
    );
};

export default ProductsFrom;
