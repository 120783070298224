import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { DEFAULT_SORT } from './report-table.constants';
import { Container } from './report-table.styles';
import { getColumns } from './report-table.utils';

const ReportTable = ({ reportType, page, size, setPage, setSize, setSort }) => {
    const [localSort, setLocalSort] = useState(DEFAULT_SORT);
    const loading = useSelector(store => store.report.loading);
    const data = useSelector(store => store.report.reportData.content);
    const pages = useSelector(store => store.report.reportData.totalPages);

    const columns = getColumns(reportType.value, localSort);

    const handleSortedChange = ([{ id, desc }]) => {
        const order = desc ? 'DESC' : 'ASC';
        setLocalSort({ sortId: id, desc });
        setSort(`${id},${order}`);
    };

    return (
        <Container>
            <ReactTable
                manual
                loading={loading}
                data={data}
                pages={pages}
                page={page}
                onPageChange={setPage}
                pageSize={size}
                onPageSizeChange={setSize}
                columns={columns}
                onSortedChange={handleSortedChange}
                className='-striped -highlight'
                pageSizeOptions={[15, 30, 50, 100]}
                pageText='Strona'
                ofText='z'
                rowsText='na stronie'
                previousText='Poprzednia'
                nextText='Następna'
                resizable={false}
                multiSort={false}
                style={{ border: 'none' }}
                noDataText='Brak danych'
                loadingText='Ładowanie danych'
            />
        </Container>
    );
};

export default ReportTable;
