import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';

import { PageWrapper, ProductHeader } from '../planning/planning.styles';
import {
    DetailsWrapper,
    PackageHeader,
    PackagesList,
    PackageWrapper,
    PackageTitle,
    OrderWrapper,
    ProductItem,
    InfoWrapper,
    StyledText,
    TitleWrapper,
    FixedPanel,
    Divider,
} from './packing.styles';
import { getClientFromOrder, haveRole, isDateBetween, removeDuplicates } from '../../../Utils/commons';
import { API } from '../../../Constants';
import { LoaderWrapper, OrderTitle } from '../../../Components/packages/packages.styles';
import Loader from '../../../Components/UI/Loader/loader';
import WeekPicker from '../../../Components/week-picker/week-picker';
import { AVAILABLE_STAGES, CONTENT_REFRESH_INTERVAL, PRODUCT_HIGHLIGHT_TIMEOUT } from '../../../Constants/common';
import {
    activateLoadingPackages,
    getPackageInfo,
    getPackages,
    pickPackage,
    resetPackagesReducer,
    unhighlightProduct,
    updatePackages,
} from '../../../Redux/Actions/package.actions';
import { checkStatus } from '../../../Utils/CheckStatus';
import { Addons } from '../tech/tech.styles';

const Packing = () => {
    const [startDate, setStartDate] = useState(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));

    const dispatch = useDispatch();

    const { user } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.roles);
    const { loadingPackages, packageInfo, highlightedProductId, stage } = useSelector(store => store.packages);
    const pickedPackage = useSelector(store => store.packages.packages.find(pack => pack.id === store.packages.pickedPackageId));
    const packages = useSelector(store => store.packages.packages.sort((a, b) => new Date(a.deadline) - new Date(b.deadline)));

    useEffect(() => {
        dispatch(activateLoadingPackages());
        dispatchGetPackages();

        const interval = setInterval(() => dispatchGetPackages(), CONTENT_REFRESH_INTERVAL);

        return () => {
            dispatch(resetPackagesReducer());
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (pickedPackage && stage === AVAILABLE_STAGES.PACKING) {
            dispatch(getPackageInfo(pickedPackage.id));
            if (pickedPackageIsFromAnotherWeek()) {
                adjustStartDate();
            }
        }
    }, [pickedPackage && pickedPackage.id]);

    useEffect(() => {
        if (highlightedProductId) {
            const packageEl = document.getElementById(`package-${pickedPackage.id}`);
            if (packageEl) {
                packageEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            setTimeout(() => {
                const el = document.getElementById(`product-item-${highlightedProductId}`);
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 300);

            setTimeout(() => {
                dispatch(unhighlightProduct());
            }, PRODUCT_HIGHLIGHT_TIMEOUT);
        }
    }, [highlightedProductId]);

    useEffect(() => {
        if (pickedPackage) {
            const packageEl = document.getElementById(`package-${pickedPackage.id}`);
            if (packageEl) {
                packageEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [startDate]);

    const pickedPackageIsFromAnotherWeek = () => !isDateBetween(new Date(pickedPackage.deadline), startDate, dateFns.addDays(startDate, 7));

    const adjustStartDate = () => setStartDate(dateFns.startOfWeek(new Date(pickedPackage.deadline), { weekStartsOn: 1 }));

    const resetStartDate = () => setStartDate(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));

    const dispatchGetPackages = () => {
        const role = roles.find(role => role.name === 'ROLE_PACKING');
        const configIfAdmin = haveRole(user, 'ROLE_ADMIN') ? { params: { roleId: role.id } } : null;
        dispatch(getPackages(configIfAdmin, AVAILABLE_STAGES.PACKING));
    };

    const patchProduct = async body => {
        try {
            await axios.patch(`${API.processStatus}`, body);
            return true;
        } catch (e) {
            console.log(e);
            if (e.response) checkStatus(e.response, dispatch);
            return false;
        }
    };

    const updateProduct = updatedProduct => {
        const productIndex = pickedPackage.products.findIndex(p => p.id === updatedProduct.id);
        const updatedProducts = [...pickedPackage.products];
        updatedProducts[productIndex] = updatedProduct;

        const updatedPackage = { ...pickedPackage, products: updatedProducts };
        const updatedPackages = [...packages];

        const packageIndex = packages.findIndex(pack => pack.id === pickedPackage.id);
        updatedPackages[packageIndex] = updatedPackage;

        dispatch(updatePackages(updatedPackages));
    };

    const changeProductStatus = async (product, status) => {
        const body = { productId: product.id, processStatus: status };
        const updated = await patchProduct(body);
        if (updated) {
            const updatedProduct = { ...product, status: status };
            updateProduct(updatedProduct);
        }
    };

    const handleOnCheckboxClick = product =>
        product.status === 'PENDING' ? changeProductStatus(product, 'DONE') : changeProductStatus(product, 'PENDING');

    const disableProduct = product => product.processStage.stage !== 'PACKING';

    const renderDetails = () => {
        const ordersInPackage = removeDuplicates(
            pickedPackage.products.map(product => product.product.order),
            'id'
        );

        return (
            <OrderWrapper>
                {ordersInPackage.map((order, i) => (
                    <>
                        <OrderTitle>
                            Zamówienie #{i + 1}: {getClientFromOrder(order)} - {order.description}
                        </OrderTitle>

                        {pickedPackage.products
                            .filter(product => product.product.order.id === order.id)
                            .map((product, index) => {
                                return (
                                    <ProductItem
                                        key={index}
                                        id={`product-item-${product.id}`}
                                        disabled={disableProduct(product)}
                                        highlighted={product.id === highlightedProductId}
                                    >
                                        <ProductHeader>
                                            {`${product.product.productSchema.name}${
                                                product.product.provider ? ` (${product.product.provider.name})` : ''
                                            } (szt. ${product.amount})`}

                                            <Form.Check
                                                inline
                                                type='checkbox'
                                                checked={product.status === 'DONE'}
                                                onChange={() => handleOnCheckboxClick(product)}
                                            />
                                        </ProductHeader>
                                        {product.product.description || ''}
                                        {product.product.poolEquipment.length > 0 && (
                                            <Addons>
                                                {product.product.poolEquipment.map(addon => {
                                                    return (
                                                        <>
                                                            <li>
                                                                {`${addon.productSchema.name}${
                                                                    addon.provider ? ` (${addon.provider.name})` : ''
                                                                } (szt. ${addon.amount}) ${
                                                                    addon.description ? `- ${addon.description}` : ''
                                                                }`}
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </Addons>
                                        )}
                                    </ProductItem>
                                );
                            })}
                    </>
                ))}
            </OrderWrapper>
        );
    };

    // const getProductIds = () =>
    //     pickedPackage.products
    //         .filter(product => product.processStage.stage === 'PACKING')
    //         .map(product => product.id)
    //         .join(',');

    const onNextClick = () => {
        dispatch(pickPackage(null));
        setStartDate(dateFns.addDays(startDate, 7));
    };
    const onBackClick = () => {
        dispatch(pickPackage(null));
        setStartDate(dateFns.subDays(startDate, 7));
    };

    const isDeadlineInShownWeek = orderDeadline => isDateBetween(orderDeadline, startDate, dateFns.addDays(startDate, 7));

    const onPackageClick = packageId => {
        dispatch(pickPackage(packageId));
        dispatch(getPackageInfo(packageId));
    };

    const getPackagesList = () => {
        const packagesInWeek = packages.filter(pack => isDeadlineInShownWeek(new Date(pack.deadline)));

        return packagesInWeek.length > 0 ? (
            packagesInWeek.map((pack, index) => (
                <PackageWrapper
                    id={`package-${pack.id}`}
                    key={index}
                    picked={pickedPackage && pickedPackage.id === pack.id}
                    onClick={() => onPackageClick(pack.id)}
                >
                    <PackageHeader>
                        <PackageTitle>
                            {pack.name} ({pack.deadline})
                        </PackageTitle>
                    </PackageHeader>
                </PackageWrapper>
            ))
        ) : (
            <LoaderWrapper>Brak paczek</LoaderWrapper>
        );
    };

    const preprocessWorkersNames = () =>
        packageInfo.workerNames
            .map(worker => {
                const [name, surname] = worker.split(' ');
                return `${name[0]}. ${surname}`;
            })
            .join(', ');

    const getInfoContent = () => (
        <>
            <InfoWrapper>
                <div>
                    Kierowca: <StyledText>{packageInfo && preprocessWorkersNames()}</StyledText>
                </div>
                <div>
                    Pojazd: <StyledText>{packageInfo && packageInfo.truckRegistration}</StyledText>
                </div>
                <div>
                    Laweta: <StyledText>{packageInfo && packageInfo.trailerRegistration}</StyledText>
                </div>
            </InfoWrapper>
            <Divider />
        </>
    );

    return (
        <PageWrapper>
            <FixedPanel>
                <TitleWrapper>Lista paczek</TitleWrapper>
                <WeekPicker {...{ startDate }} {...{ onBackClick }} {...{ onNextClick }} />
                <PackagesList>
                    {loadingPackages ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        getPackagesList()
                    )}
                </PackagesList>
            </FixedPanel>
            <FixedPanel>
                <TitleWrapper>{pickedPackage ? pickedPackage.name : 'Szczegóły paczki'}</TitleWrapper>

                {pickedPackage && getInfoContent()}

                <DetailsWrapper>{pickedPackage ? renderDetails() : <LoaderWrapper>Wybierz paczkę</LoaderWrapper>}</DetailsWrapper>
            </FixedPanel>
        </PageWrapper>
    );
};

export default Packing;
