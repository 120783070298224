import styled from 'styled-components';

import { toRem } from '../../../Styles/theme';

export const ProductsWrapper = styled.div`
    width: 100%;
    height: 92%;

    margin-top: ${toRem(20)};
    overflow-y: auto;
`;

export const ProductItem = styled.div`
    width: 99%;

    margin: ${toRem(10)};
    margin-left: 0;
    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px lightgrey;

    &:hover {
        background: whitesmoke;
    }
`;

export const ProductHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ProductAddon = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    margin-left: ${toRem(10)};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${toRem(10)};

    margin-left: ${toRem(15)};
`;

export const PackageInfosWrapper = styled.div`
    display: flex;
    gap: ${toRem(10)};
`;

export const PackageLink = styled.div`
    cursor: pointer;
    font-weight: 600;
`;
