import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { StyledButton, Wrapper, DateWrapper } from './week-picker.styles';
import { getText } from './week-picker.utils';

const WeekPicker = ({ startDate, onNextClick, onBackClick }) => {
    return (
        <Wrapper>
            <StyledButton onClick={onBackClick}>
                <ChevronLeftIcon />
            </StyledButton>
            <DateWrapper>{getText(startDate)}</DateWrapper>
            <StyledButton onClick={onNextClick}>
                <ChevronRightIcon />
            </StyledButton>
        </Wrapper>
    );
};

export default WeekPicker;
