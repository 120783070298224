import React from 'react';
import './MyCalendar.css';
import * as dateFns from 'date-fns';

const MyCalendar = React.memo(props => {
    const { resources, numberOfSelectedResources, currentMonth, handleNextMonthClick, handlePrevMonthClick, hasPanel } = props;

    const renderHeader = () => {
        return (
            <div className='header row flex-middle'>
                <div className='col col-start'>
                    <div className='icon' onClick={prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className='col col-center'>
                    <span>{`${getMonthName(dateFns.format(currentMonth, 'M'))} ${dateFns.format(currentMonth, 'yyyy')}`}</span>
                </div>
                <div className='col col-end' onClick={nextMonth}>
                    <div className='icon'>chevron_right</div>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        let days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

        return (
            <div className='days row'>
                {days.map(day => (
                    <div className='col col-center' key={day}>
                        {day}
                    </div>
                ))}
            </div>
        );
    };

    const checkIfArrayContainsDate = (array, date) => {
        let result = false;
        array.forEach(element => {
            if (dateFns.isSameDay(element, date)) {
                result = true;
            }
        });

        return result;
    };

    const renderResources = date => {
        const spans = [];
        resources.forEach(resourceAvailability => {
            if (checkIfArrayContainsDate(resourceAvailability.availability, date)) {
                spans.push(
                    <span
                        key={resourceAvailability.name}
                        className='resource-span'
                        style={{
                            backgroundColor: resourceAvailability.color,
                        }}
                    >
                        {resourceAvailability.name}
                    </span>
                );
            }
        });

        return spans;
    };

    const renderCells = () => {
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });

        const dateFormat = 'd';
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = '';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                let spans = [];

                if (dateFns.isSameMonth(day, monthStart)) spans = renderResources(day);
                days.push(
                    <div
                        className={`col cell ${spans.length === numberOfSelectedResources && spans.length > 0 ? 'intersection' : ''} ${
                            !dateFns.isSameMonth(day, monthStart) ? 'disabled' : ''
                        }`}
                        key={day}
                    >
                        {dateFns.isSameMonth(day, monthStart) && <span className='number'>{formattedDate}</span>}
                        <div className='spans-container'>{spans}</div>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }

            rows.push(
                <div className='row' key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className='body'>{rows}</div>;
    };

    const nextMonth = () => {
        handleNextMonthClick();
    };

    const prevMonth = () => {
        handlePrevMonthClick();
    };

    const getMonthName = monthNo => {
        switch (monthNo) {
            case '1':
                return 'Styczeń';
            case '2':
                return 'Luty';
            case '3':
                return 'Marzec';
            case '4':
                return 'Kwiecień';
            case '5':
                return 'Maj';
            case '6':
                return 'Czerwiec';
            case '7':
                return 'Lipiec';
            case '8':
                return 'Sierpień';
            case '9':
                return 'Wrzesień';
            case '10':
                return 'Październik';
            case '11':
                return 'Listopad';
            case '12':
                return 'Grudzień';
            default:
                return 'Błędny miesiąc';
        }
    };

    return (
        <div className={`calendar ${hasPanel ? '' : 'filters'}`}>
            {renderHeader()}
            {renderDays()}
            {renderCells()}
        </div>
    );
});

export default MyCalendar;
