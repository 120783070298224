import axios from 'axios';
import { licenseTypesConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getLicenseTypes = () => async dispatch => {
    try {
        const res = await axios.get(API.licenseTypes);
        return dispatch({
            type: licenseTypesConstants.GET,
            payload: res.data,
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};
