import React from 'react';
import { useSelector } from 'react-redux';

import { PalletItem, PalletWrapper, IconImage, OtherIconImage } from './styles';
import { categoryPriority, categorySymbols, getIconOffset } from './utlis';

const Pallet = ({ counter, productId }) => {
    const categories = useSelector(store =>
        store.product.categories
            .filter(category => category.overcategory && category.overcategory.id === 'TROUGH_EQUIPMENT')
            .map(category => ({
                ...category,
                symbol: categorySymbols[category.id],
                priority: categoryPriority[category.id],
            }))
            .sort((a, b) => a.priority - b.priority)
    );

    const dragStart = (e, category) => {
        const [iconOffsetX, iconOffsetY] = getIconOffset(e);

        e.dataTransfer.setData('iconOffsetX', iconOffsetX);
        e.dataTransfer.setData('iconOffsetY', iconOffsetY);
        e.dataTransfer.setData('category', category);
        e.dataTransfer.setData('counter', counter);
    };

    const onDragOver = e => {
        e.stopPropagation();
    };

    const disableDrag = productId !== -1;

    return (
        <PalletWrapper>
            {categories.map((category, index) => (
                <PalletItem title={category.label} key={index}>
                    {category.id === 'POOL_OTHER' ? (
                        <OtherIconImage draggable={disableDrag} onDragStart={e => dragStart(e, category.id)} {...{ onDragOver }}>
                            {counter}
                        </OtherIconImage>
                    ) : (
                        <IconImage
                            src={category.symbol}
                            draggable={disableDrag}
                            onDragStart={e => dragStart(e, category.id)}
                            {...{ onDragOver }}
                        />
                    )}
                </PalletItem>
            ))}
        </PalletWrapper>
    );
};

export default Pallet;
