import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const StyledLoader = styled.div`
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: ${props => props.size || '120px'};
    height: ${props => props.size || '120px'};
    animation: ${spin} 0.5s linear infinite;
`;
