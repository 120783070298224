import styled, { css } from 'styled-components';
import { toRem } from '../../../Styles/theme';

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    width: ${toRem(450)};

    padding: ${toRem(10)};
    border-radius: ${toRem(15)};
    border: ${toRem(3)} solid #a8a8a88c;

    background-color: #fff;
    box-shadow: ${toRem(12)} ${toRem(12)} ${toRem(12)} 0 rgba(0, 0, 0, 0.575);

    ${({ withTable }) =>
        withTable &&
        css`
            border-top-left-radius: 0;
            border-left-width: ${toRem(1)};
        `}
`;
