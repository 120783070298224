import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from '../Components/CustomTable';
import { ColumnsConfig } from '../Utils/ColumnsConfig';
import { getActiveOrders, addOrder, deleteOrder, editOrder } from '../Redux/Actions/OrdersActions';
import OrdersPanel from '../Components/ControlPanels/OrdersPanel';
import DeletionModal from '../Components/DeletionModal';
import OrderConfigurator, { availableModes } from '../Components/OrderConfigurator/OrderConfigurator';
import { CONTENT_REFRESH_INTERVAL } from '../Constants/common';

const OrderPage = () => {
    const [id, setId] = useState(-1);
    const [isRowClicked, setIsRowClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);

    const [oldClient, setOldClient] = useState('');
    const [client, setClient] = useState(-1);
    const [dueDate, setDueDate] = useState(undefined);
    const [description, setDescription] = useState('');
    const [hidden, setHidden] = useState(false);

    const [mode, setMode] = useState(availableModes.LIST);
    const [productId, setProductId] = useState(-1);

    const dispatch = useDispatch();

    const orders = useSelector(store => store.orders.orders);
    const user = useSelector(store => store.auth.user);
    const clients = useSelector(store => store.client.clients);

    useEffect(() => {
        dispatch(getActiveOrders());

        const interval = setInterval(() => {
            refresh();
        }, CONTENT_REFRESH_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const refresh = () => {
        dispatch(getActiveOrders());
    };

    const preprocessFields = fields => {
        return {
            ...fields,
            client: fields.client.value >= 0 ? { id: fields.client.value } : null,
        };
    };

    const handleSubmit = fields => {
        if (fields.dueDate) fields.dueDate = prepareDate(fields.dueDate);
        dispatch(addOrder(preprocessFields(fields), refresh));
        clearFields();
    };

    const prepareDate = date => {
        return '' + ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
    };

    const clearFields = () => {
        setDueDate(undefined);
        setOldClient('');
        setClient(-1);
        setDescription('');
        setIsRowClicked(false);
        setHidden(false);
        setId(-1);
    };

    const handleOnRowClick = rowInfo => {
        if (typeof rowInfo !== 'undefined') {
            let dueDate = undefined;
            if (rowInfo.original.dueDate) {
                const splitedDate = rowInfo.original.dueDate.split('-');
                dueDate = new Date(splitedDate[2], splitedDate[1] - 1, splitedDate[0]);
            }
            setOldClient(rowInfo.original.oldClient);
            if (rowInfo.original.client) {
                setClient({
                    value: rowInfo.original.client.id,
                    label: rowInfo.original.client.shortName,
                });
            } else {
                setClient(-1);
            }

            setDueDate(dueDate);
            setDescription(rowInfo.original.description);
            setHidden(rowInfo.original.hidden);
            setId(rowInfo.original.id);
            setIsRowClicked(true);
        }
    };

    const handleCancelClick = () => {
        setIsRowClicked(false);
        clearFields();
    };

    const handleRemoveClick = () => {
        setIsRowClicked(false);
        setShowModal(false);
        dispatch(deleteOrder({ id }, refresh));
        clearFields();
    };

    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleEditClick = fields => {
        setIsRowClicked(false);
        fields.dueDate = prepareDate(fields.dueDate);
        dispatch(editOrder({ id, ...preprocessFields(fields) }, refresh));
        clearFields();
    };

    const handleToggleProductsModal = e => {
        e.preventDefault();
        setShowProductsModal(prev => !prev);
        setMode(availableModes.LIST);
        setProductId(-1);
    };

    return (
        <>
            <div style={{ padding: 10 }}>
                <DeletionModal
                    show={showModal}
                    onHide={handleModalClose}
                    {...{ handleRemoveClick }}
                    text='Czy na pewno chcesz zakończyć to zamówienie?'
                    deletionText='Zakończ'
                />
                <CustomTable
                    content={orders}
                    columns={ColumnsConfig.OrdersColumns}
                    className='reservationTable'
                    {...{ handleOnRowClick }}
                />
                <OrdersPanel
                    {...{ clients }}
                    {...{ handleSubmit }}
                    {...{ isRowClicked }}
                    {...{ handleCancelClick }}
                    {...{ handleDeleteClick }}
                    {...{ handleEditClick }}
                    {...{ handleToggleProductsModal }}
                    {...{ showProductsModal }}
                    {...{ oldClient }}
                    {...{ client }}
                    {...{ dueDate }}
                    {...{ description }}
                    {...{ hidden }}
                    {...{ user }}
                />
            </div>
            <OrderConfigurator
                show={showProductsModal}
                {...{ id }}
                {...{ handleToggleProductsModal }}
                {...{ mode }}
                {...{ setMode }}
                {...{ productId }}
                {...{ setProductId }}
            />
        </>
    );
};

export default OrderPage;
