import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-block;
    color: red;
`;

const RequiredFieldIndicator = () => {
    return <Container>&nbsp;*</Container>;
};

export default RequiredFieldIndicator;
