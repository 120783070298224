import styled from 'styled-components';
import { toRem } from '../../../Styles/theme';

export const NotificationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    max-height: 50vh;

    padding: ${toRem(10)};
`;
