import { API } from '../../Constants';
import { REPORT_TYPES } from './report.constants';

export const getBaseUrl = reportType => {
    switch (reportType) {
        case REPORT_TYPES.PRODUCTS:
            return API.productReport;
        default:
            break;
    }
};
