import styled, { css } from 'styled-components';

import { toRem } from '../../../Styles/theme';

export const ContentWrapper = styled.div`
    height: 70%;
    margin: ${toRem(10)} 0;
    display: flex;
`;

export const AddonsWrapper = styled.div`
    width: 100%;
    height: 100%;

    padding: ${toRem(10)};
`;

export const PalletWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${toRem(10)};

    width: ${toRem(150)};
    height: 600px;

    border: 1px solid lightgrey;
    border-left: 0;
    padding: ${toRem(10)};
`;

export const PalletItem = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    width: 100%;

    &:hover {
        background: whitesmoke;
    }
`;

const iconStyles = css`
    width: 50px;
    height: 50px;
    cursor: move;
`;

export const IconImage = styled.img`
    ${iconStyles};
`;

export const OtherIconImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: solid 3px black;

    background: white;
    font-size: 30px;
    font-weight: 600;

    ${iconStyles};
`;

export const CanvasImage = styled.img`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;

    width: ${props => props.size}px;
    height: ${props => props.size}px;

    ${props => !props.readOnly && 'cursor: move'};
`;

export const OtherCanvasImage = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: ${props => props.size}px;
    height: ${props => props.size}px;

    border: solid 3px black;

    background: white;
    ${props => !props.readOnly && 'cursor: move'};

    font-size: 30px;
    font-weight: 600;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
`;

export const OtherWrapper = styled.div`
    height: ${toRem(300)};

    overflow-x: hidden;
    overflow-y: auto;
`;
