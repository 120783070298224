import { availableViews } from '../../Redux/Reducers/AuthReducer';
import { haveRole } from '../../Utils/commons';
import { prepareUrl } from '../routing/routing-utils';

const managementRoutesForAllUsers = [
    { label: 'Zadania', to: prepareUrl('/tasks') },
    { label: 'Zamówienia', to: prepareUrl('/orders') },
    { label: 'Pracownicy', to: prepareUrl('/workers') },
    { label: 'Pojazdy', to: prepareUrl('/trucks') },
    { label: 'Przyczepy', to: prepareUrl('/trailers') },
    { label: 'Dostawcy', to: prepareUrl('/providers') },
    { label: 'Odbiorcy', to: prepareUrl('/clients') },
    { label: 'Produkty', to: prepareUrl('/products') },
    { label: 'Raporty', to: prepareUrl('/reports') },
];

const managementRoutesOnlyForAdmin = [{ label: 'Użytkownicy', to: prepareUrl('/users') }];

const productionRoutes = [
    { label: 'Planowanie', to: prepareUrl('/planning') },
    { label: 'Produkcja', to: prepareUrl('/production') },
];

const toolingRoutes = [{ label: 'Obróbka', to: prepareUrl('/tooling') }];

const techRoutes = [{ label: 'Technika', to: prepareUrl('/tech') }];

const packingRoutes = [{ label: 'Pakowanie', to: prepareUrl('/packing') }];

export const getAvailableRoutes = (user, view) => {
    const availableRoutes = [];

    if (haveRole(user, 'ROLE_USER') && !haveRole(user, 'ROLE_TEMP_PASSWORD')) {
        availableRoutes.push(...managementRoutesForAllUsers);
    }

    if (haveRole(user, 'ROLE_ADMIN')) {
        switch (view) {
            case availableViews.MANAGEMENT:
                availableRoutes.push(...managementRoutesForAllUsers, ...managementRoutesOnlyForAdmin);
                break;
            case availableViews.PRODUCTION:
                availableRoutes.push(...productionRoutes, ...toolingRoutes, ...techRoutes, ...packingRoutes);
                break;
        }
    }

    if (haveRole(user, 'ROLE_PRODUCTION')) {
        availableRoutes.push(...productionRoutes);
    }

    if (haveRole(user, 'ROLE_TOOLING')) {
        availableRoutes.push(...toolingRoutes);
    }

    if (haveRole(user, 'ROLE_TECH')) {
        availableRoutes.push(...techRoutes);
    }

    if (haveRole(user, 'ROLE_PACKING')) {
        availableRoutes.push(...packingRoutes);
    }

    return availableRoutes;
};
