import styled from 'styled-components';

import { toRem } from '../../../Styles/theme';

export const ContentWrapper = styled.div`
    height: 70%;
    margin: ${toRem(10)} 0;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;

    font-size: ${toRem(20)};
    text-align: center;
`;

export const ItemRow = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
`;

export const BodyWrapper = styled.div`
    width: 100%;
    height: 87%;
    margin-top: ${toRem(20)};
    overflow-y: auto;
`;

export const AddonRow = styled.div`
    display: flex;
    justify-self: center;
    width: 99%;
    gap: ${toRem(10)};
    margin: ${toRem(10)} 0;
`;
