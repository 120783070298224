import * as dateFns from 'date-fns';

export const formatDate = date => dateFns.format(date, 'dd/MM/yyyy', { awareOfUnicodeTokens: true });

export const getEndDate = startDate => dateFns.addDays(startDate, 6);

const getFormattedStart = startDate => formatDate(startDate);

const getFormattedEnd = endDate => formatDate(endDate);

const getWeekNumber = startDate => dateFns.getISOWeek(startDate);

export const getText = startDate =>
    `${getFormattedStart(startDate)} - ${getFormattedEnd(getEndDate(startDate))} (KW ${getWeekNumber(startDate)})`;
