import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { ProductHeader, ButtonWrapper, ProductAddon, ProductItem, ProductsWrapper, PackageInfosWrapper, PackageLink } from './styles';
import { EmptyMessage, HeaderWrapper } from '../styles';
import { toRem } from '../../../Styles/theme';
import DeletionModal from '../../DeletionModal';
import PackageConfigurator from '../../packages/package-configurator';

const ProductList = ({ handleAddProductClick, handleRemoveProductClick, handleEditProductClick, handleToggleProductsModal, refresh }) => {
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [showPackageModal, setShowPackageModal] = useState(false);
    const [id, setId] = useState(-1);
    const [packId, setPackId] = useState(-1);
    const [productId, setProductId] = useState(-1);

    const products = useSelector(store => store.orders.products);

    const handleModalClose = () => {
        setShowDeletionModal(false);
    };

    const handleDeleteClick = id => {
        setShowDeletionModal(true);
        setId(id);
    };

    const handleRemoveClick = () => {
        handleRemoveProductClick(id);
        setShowDeletionModal(false);
    };

    const handleOnPackClick = (packId, clickedProductId) => {
        setPackId(packId);
        setShowPackageModal(true);
        setProductId(clickedProductId);
    };

    const handleOnBackClick = () => {
        setShowPackageModal(false);
        refresh();
    };

    return (
        <>
            {showPackageModal ? (
                <PackageConfigurator handleOnBackClick={handleOnBackClick} {...{ packId }} {...{ productId }} />
            ) : (
                <>
                    <DeletionModal
                        show={showDeletionModal}
                        onHide={handleModalClose}
                        {...{ handleRemoveClick }}
                        text='Czy na pewno chcesz usunąć ten produkt z zamówienia?'
                    />

                    <HeaderWrapper>
                        <Button onClick={handleAddProductClick}>Dodaj produkt</Button>
                        Produkty w zamówieniu
                        <Button onClick={handleToggleProductsModal} variant={'secondary'}>
                            Zamknij
                        </Button>
                    </HeaderWrapper>

                    <ProductsWrapper>
                        {products.length ? (
                            products.map((product, index) => {
                                return (
                                    <ProductItem key={index}>
                                        <ProductHeader>
                                            {`${product.productSchema.name}${product.provider ? ` (${product.provider.name})` : ''} (szt. ${
                                                product.amount
                                            }) ${product.description ? `- ${product.description}` : ''}`}
                                            <ButtonWrapper>
                                                <Button style={{ width: toRem(100) }} onClick={() => handleEditProductClick(product.id)}>
                                                    Edytuj
                                                </Button>
                                                <Button
                                                    style={{ width: toRem(100) }}
                                                    variant='danger'
                                                    onClick={() => handleDeleteClick(product.id)}
                                                    disabled={product.packageInfos.length > 0}
                                                >
                                                    Usuń
                                                </Button>
                                            </ButtonWrapper>
                                        </ProductHeader>
                                        {product.filterRoomEquipment &&
                                            product.filterRoomEquipment.map((addon, index) => {
                                                return (
                                                    <ProductAddon key={index}>{`${addon.productSchema.name}${
                                                        addon.provider ? ` (${addon.provider.name})` : ''
                                                    } (szt. ${addon.amount}) ${
                                                        addon.description ? `- ${addon.description}` : ''
                                                    }`}</ProductAddon>
                                                );
                                            })}
                                        {product.poolEquipment &&
                                            product.poolEquipment.map((addon, index) => {
                                                return (
                                                    <ProductAddon key={index}>{`${addon.productSchema.name}${
                                                        addon.provider ? ` (${addon.provider.name})` : ''
                                                    } (szt. ${addon.amount}) ${
                                                        addon.description ? `- ${addon.description}` : ''
                                                    }`}</ProductAddon>
                                                );
                                            })}
                                        {product.packageInfos.length > 0 && (
                                            <PackageInfosWrapper>
                                                Paczki, w których występuje ten produkt:
                                                {product.packageInfos.map((pack, i) => {
                                                    const text = `Paczka #${i + 1} (${pack.amount} szt.)${
                                                        i + 1 !== product.packageInfos.length ? ',' : ''
                                                    }`;
                                                    return (
                                                        <PackageLink onClick={() => handleOnPackClick(pack.packageId, product.id)}>
                                                            {text}
                                                        </PackageLink>
                                                    );
                                                })}
                                            </PackageInfosWrapper>
                                        )}
                                    </ProductItem>
                                );
                            })
                        ) : (
                            <EmptyMessage>Do zamówienia nie zostały jeszcze dodane produkty.</EmptyMessage>
                        )}
                    </ProductsWrapper>
                </>
            )}
        </>
    );
};

export default ProductList;
