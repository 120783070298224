import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Form, Modal } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { HeaderWrapper } from '../OrderConfigurator/styles';
import { toRem } from '../../Styles/theme';
import Picklist from './picklist';
import { API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { AVAILABLE_STAGES } from '../../Constants/common';

const PackageConfigurator = props => {
    const { reservationId, packId, productId, showArchiveTasks } = props;

    const dispatch = useDispatch();

    const [availableProducts, setAvailableProducts] = useState([]);
    const [assignedProducts, setAssignedProducts] = useState(
        props.editedPack
            ? props.editedPack.products.map(p => ({
                  ...p.product,
                  amount: p.amount,
                  amountId: p.id,
                  updatable: p.updatable,
                  blockedAmount: p.amount,
                  processStage: p.processStage,
              }))
            : []
    );
    const [pickedProduct, setPickedProduct] = useState(undefined);
    const [assignAmount, setAssignAmount] = useState(1);
    const [saveLoading, setSaveLoading] = useState(false);
    const [availableLoading, setAvailableLoading] = useState(reservationId);
    const [assignedLoading, setAssignedLoading] = useState(packId);
    const [pack, setPack] = useState(undefined);
    const [packName, setPackName] = useState(props.editedPack ? props.editedPack.name : '');
    const [showAssignWithDateModal, setShowAssignWithDateModal] = useState(false);
    const [productionTerm, setProductionTerm] = useState(new Date());

    const initialRun = useRef(true);

    const reservation = useSelector(store => store.reservations.reservations.find(r => r.id === reservationId));

    useEffect(() => {
        if (reservationId) {
            getAvailableProducts();
        }
        if (packId) {
            getPack();
        }
    }, []);

    useEffect(() => {
        if (initialRun.current) {
            initialRun.current = false;
        } else {
            if (!saveLoading) {
                props.handleOnBackClick();
            }
        }
    }, [saveLoading]);

    const getOrdersIds = () => reservation.orderIds.map(orderId => orderId.value).join(',');

    const ifReservationHasOrders = () => reservation.orderIds.length > 0;

    const getAvailableProducts = async () => {
        try {
            if (ifReservationHasOrders()) {
                const res = await axios.get(`${API.productAvailable}?orderIds=${getOrdersIds()}`);
                setAvailableProducts(res.data.filter(product => product.amount > 0));
            }
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }

        setAvailableLoading(false);
    };

    const getPack = async () => {
        try {
            const { data } = await axios.get(`${API.productPackage}?packageId=${packId}`);
            setPack(data);
            setPackName(data.name);

            setAssignedProducts(
                data.products.map(p => ({
                    ...p.product,
                    amount: p.amount,
                    amountId: p.id,
                    updatable: p.updatable,
                }))
            );
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }

        setAssignedLoading(false);
    };

    const handleAddPackage = async () => {
        setSaveLoading(true);

        const body = {
            name: packName,
            products: assignedProducts.map(product => ({
                product: { id: product.id },
                amount: product.amount,
            })),
        };

        try {
            await axios.post(`${API.productPackage}?reservationId=${reservationId}`, body);
            NotificationManager.success('Paczka została zapisana');
        } catch (e) {
            console.log(e.response);
            NotificationManager.error('Błąd podczas zapisywania paczki');
            if (e.response) checkStatus(e.response, dispatch);
        }
        setSaveLoading(false);
    };

    const handleEditPackage = async () => {
        setSaveLoading(true);

        const body = {
            id: props.editedPack ? props.editedPack.id : packId,
            name: packName,
            products: assignedProducts.map(product => ({
                id: product.amountId,
                product: { id: product.id },
                amount: product.amount,
                productionTerm: product.productionTerm,
            })),
        };

        try {
            await axios.patch(`${API.productPackage}?reservationId=${reservationId}`, body);
            NotificationManager.success('Paczka została zapisana');
        } catch (e) {
            console.log(e.response);
            NotificationManager.error('Błąd podczas zapisywania paczki');
            if (e.response) checkStatus(e.response, dispatch);
        }
        setSaveLoading(false);
    };

    const handleAssignAll = () => {
        const updatedAssignedProducts = [...assignedProducts];
        availableProducts.forEach(availableProduct => {
            const alreadyAssigned = assignedProducts.find(assignedProduct => assignedProduct.id === availableProduct.id);
            if (alreadyAssigned) {
                const index = assignedProducts.indexOf(alreadyAssigned);
                updatedAssignedProducts[index] = {
                    ...alreadyAssigned,
                    amount: alreadyAssigned.amount + availableProduct.amount,
                };
            } else {
                updatedAssignedProducts.push(availableProduct);
            }
        });

        setAssignedProducts(updatedAssignedProducts);
        setAvailableProducts([]);
        setPickedProduct(undefined);
        setAssignAmount(1);
    };

    const handleDeleteAll = () => {
        const updatedAvailableProducts = [...availableProducts];
        assignedProducts.forEach(assignedProduct => {
            const alreadyAvailable = availableProducts.find(availableProduct => assignedProduct.id === availableProduct.id);
            if (alreadyAvailable) {
                const index = availableProducts.indexOf(alreadyAvailable);
                updatedAvailableProducts[index] = {
                    ...alreadyAvailable,
                    amount: alreadyAvailable.amount + assignedProduct.amount,
                };
            } else {
                updatedAvailableProducts.push(assignedProduct);
            }
        });

        setAvailableProducts(updatedAvailableProducts);
        setAssignedProducts([]);
        setPickedProduct(undefined);
        setAssignAmount(1);
    };

    const getPickedItem = () => {
        const [column, pickedId] = pickedProduct.split('-');
        return column === 'available'
            ? { ...availableProducts.find(product => product.id == pickedId) }
            : { ...assignedProducts.find(product => product.id == pickedId) };
    };

    const getPickedIndex = () => {
        const [column, pickedId] = pickedProduct.split('-');
        return column === 'available'
            ? availableProducts.findIndex(product => product.id == pickedId)
            : assignedProducts.findIndex(product => product.id == pickedId);
    };

    const handleOnProductClick = index => {
        setPickedProduct(index);
        setAssignAmount(1);
    };

    const handleAmountChange = e => setAssignAmount(parseInt(e.target.value));

    const areAssignedInProgress = () => {
        const productsStages = assignedProducts.filter(x => x.processStage).map(x => x.processStage.stage);
        const allAssignedAreOnBoard = productsStages.every(x => x === AVAILABLE_STAGES.BOARD);
        const allAssignedAreOnPlanning = productsStages.every(x => x === AVAILABLE_STAGES.PLANNING);
        return !(allAssignedAreOnBoard || allAssignedAreOnPlanning);
    };
    const isAssignWithDateModal = () => isPool() && areAssignedInProgress();

    const handleAssignSingle = () => (isAssignWithDateModal() ? handleShowAssignWithDateModal() : proceedWithAssign());

    const handleAcceptAssignWithDateModel = () => {
        proceedWithAssign(productionTerm);
        setShowAssignWithDateModal(false);
    };

    const handleShowAssignWithDateModal = () => {
        setShowAssignWithDateModal(true);
        setProductionTerm(new Date());
    };
    const hideAssignWithDateModal = () => setShowAssignWithDateModal(false);

    const proceedWithAssign = productionTerm => {
        const pickedItem = getPickedItem();
        const pickedIndex = getPickedIndex();
        let updatedAssignedProducts;
        let updatedAvailableProducts;
        const alreadyAssigned = assignedProducts.find(product => product.id === pickedItem.id);
        const movedAll = pickedItem.amount === assignAmount;

        if (movedAll) {
            updatedAvailableProducts = [...availableProducts.filter(product => product.id !== pickedItem.id)];
        } else {
            updatedAvailableProducts = [...availableProducts];
            pickedItem.amount -= assignAmount;
            updatedAvailableProducts[pickedIndex] = pickedItem;
        }

        if (alreadyAssigned) {
            const index = assignedProducts.indexOf(alreadyAssigned);
            const updatedItem = { ...assignedProducts[index], productionTerm };
            updatedItem.amount += assignAmount;
            updatedAssignedProducts = [...assignedProducts];
            updatedAssignedProducts[index] = updatedItem;
        } else {
            const newItem = { ...pickedItem, productionTerm };
            newItem.amount = assignAmount;
            updatedAssignedProducts = [newItem, ...assignedProducts];
        }

        setAvailableProducts(updatedAvailableProducts);
        setAssignedProducts(updatedAssignedProducts);
        if (movedAll) {
            setPickedProduct(undefined);
        }
    };

    const handleDeleteSingle = () => {
        const pickedItem = getPickedItem();
        const pickedIndex = getPickedIndex();
        let updatedAssignedProducts;
        let updatedAvailableProducts;
        const alreadyAvailable = availableProducts.find(product => product.id === pickedItem.id);
        const movedAll = pickedItem.amount === assignAmount;

        if (movedAll) {
            updatedAssignedProducts = [...assignedProducts.filter(product => product.id !== pickedItem.id)];
        } else {
            updatedAssignedProducts = [...assignedProducts];
            pickedItem.amount -= assignAmount;
            updatedAssignedProducts[pickedIndex] = pickedItem;
        }

        if (alreadyAvailable) {
            const index = availableProducts.indexOf(alreadyAvailable);
            const updatedItem = { ...availableProducts[index] };
            updatedItem.amount += assignAmount;
            updatedAvailableProducts = [...availableProducts];
            updatedAvailableProducts[index] = updatedItem;
        } else {
            const newItem = { ...pickedItem };
            newItem.amount = assignAmount;
            updatedAvailableProducts = [newItem, ...availableProducts];
        }

        setAvailableProducts(updatedAvailableProducts);
        setAssignedProducts(updatedAssignedProducts);
        if (movedAll) {
            setPickedProduct(undefined);
        }
    };

    const maxAmount = pickedProduct ? getPickedItem().amount : 1;

    const isEditWithDeadline = props.editedPack && props.editedPack.deadline && !props.editedPack.deadlineProposition;

    const isPool = () => {
        if (isEditWithDeadline && pickedProduct) {
            const pickedItem = getPickedItem();
            return pickedItem.productSchema.category.id === 'POOL';
        }
        return false;
    };

    return (
        <>
            <HeaderWrapper>
                <Button onClick={props.handleOnBackClick} variant='secondary' style={{ width: toRem(100) }}>
                    Powrót
                </Button>
                <div style={{ width: toRem(400) }} />
                Konfigurator paczki
                <Form.Control
                    placeholder='Wprowadź nazwę paczki'
                    value={packName}
                    onChange={e => setPackName(e.target.value)}
                    style={{ width: toRem(400) }}
                    disabled={packId}
                />
                {saveLoading ? (
                    <div style={{ width: toRem(100) }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Button
                        onClick={props.editedPack || packId ? handleEditPackage : handleAddPackage}
                        style={{ width: toRem(100) }}
                        disabled={assignedProducts.length === 0 || packName === ''}
                    >
                        Zapisz
                    </Button>
                )}
            </HeaderWrapper>
            <Picklist
                {...{ availableProducts }}
                {...{ assignedProducts }}
                {...{ handleAssignAll }}
                {...{ handleDeleteAll }}
                {...{ handleOnProductClick }}
                {...{ pickedProduct }}
                {...{ handleAssignSingle }}
                {...{ handleDeleteSingle }}
                {...{ assignAmount }}
                {...{ handleAmountChange }}
                {...{ maxAmount }}
                {...{ availableLoading }}
                {...{ assignedLoading }}
                {...{ reservationId }}
                {...{ pack }}
                {...{ productId }}
                {...{ isEditWithDeadline }}
                isPool={isPool()}
                showArchiveTasks={showArchiveTasks}
            />
            <Modal show={showAssignWithDateModal} onHide={hideAssignWithDateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdź operację</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label htmlFor='propositionDate'>Przypisz termin dla basenu</label>

                        <DateTimePicker
                            name='productionTerm'
                            value={productionTerm}
                            messages={{ dateButton: 'Wybierz termin' }}
                            culture='pl'
                            placeholder='Wybierz termin'
                            time={false}
                            onChange={e => {
                                setProductionTerm(e);
                            }}
                            min={new Date()}
                            inputProps={{
                                component: props => <input {...props} readOnly />,
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={hideAssignWithDateModal}>
                        Anuluj
                    </Button>
                    <Button variant='success' onClick={handleAcceptAssignWithDateModel}>
                        Przypisz
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PackageConfigurator;
