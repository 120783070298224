import { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { API } from '../Constants';
import { getPackageInfo, removeProductAmount, updateProductAmount } from '../Redux/Actions/package.actions';
import { getStageNameFromPathname, haveRole } from '../Utils/commons';
import { ROLES } from '../Constants/roles';
import { AVAILABLE_STAGES } from '../Constants/common';
import useNotificationsFactory, {
    AVAILABLE_TOPICS,
} from '../Components/navigation-bar/notifications/notificationsFactory/useNotificationsFactory';

const useIncomingNotifications = () => {
    const [incomingNotifications, setIncomingNotifications] = useState([]);

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { createNotification } = useNotificationsFactory();
    const setIncomingNotificationRef = useRef(null);

    const { user } = useSelector(store => store.auth);

    const clearIncomingNotifications = () => setIncomingNotifications([]);

    const removeIncomingNotification = id => setIncomingNotifications(prev => prev.filter(x => x.id !== id));

    const shouldSubscribeForNotifications = () =>
        !(pathname.includes('login') || pathname.slice(-1) === '/' || haveRole(user, ROLES.ADMIN) || haveRole(user, ROLES.USER));

    const shouldUpdatePackageInfo = notification =>
        notification.stage === AVAILABLE_STAGES.PACKING && notification.notificationTopic === AVAILABLE_TOPICS.CHANGE;

    const updateNotifications = (notification, notifications) => {
        const alreadyExists = notifications.findIndex(x => x.id === notification.id);

        if (alreadyExists !== -1) {
            const updatedNotifications = [...notifications];
            updatedNotifications[alreadyExists] = notification;
            return updatedNotifications;
        }

        NotificationManager.warning(notification.alert);

        if (shouldUpdatePackageInfo(notification)) {
            dispatch(getPackageInfo(notification.packageId));
        }

        return [...notifications, notification];
    };

    setIncomingNotificationRef.current = async (response, stage) => {
        const responseBody = JSON.parse(response.body);
        const { productAmountId, packageId, notificationTopic } = responseBody;

        if (productAmountId) {
            if (notificationTopic === AVAILABLE_TOPICS.REMOVE) {
                dispatch(removeProductAmount(productAmountId, packageId));
            } else {
                await dispatch(updateProductAmount(productAmountId, packageId));
            }
        }

        const notification = createNotification({ ...responseBody, stage });
        if (notification) {
            setIncomingNotifications(notifications => updateNotifications(notification, notifications));
        }
    };

    const initializeConnection = () => {
        if (shouldSubscribeForNotifications()) {
            const socket = new SockJS(getSocketURL());
            const stompClient = Stomp.over(socket);

            stompClient.connect({}, () => {
                if (haveRole(user, ROLES.PRODUCTION)) {
                    if (getStageNameFromPathname(pathname) === AVAILABLE_STAGES.PLANNING) {
                        stompClient.subscribe('/sockets/planning', response => {
                            setIncomingNotificationRef.current(response, AVAILABLE_STAGES.PLANNING);
                        });
                    }

                    if (getStageNameFromPathname(pathname) === AVAILABLE_STAGES.PRODUCTION)
                        stompClient.subscribe('/sockets/production', response => {
                            setIncomingNotificationRef.current(response, AVAILABLE_STAGES.PRODUCTION);
                        });
                }

                if (haveRole(user, ROLES.TOOLING)) {
                    stompClient.subscribe('/sockets/tooling', response => {
                        setIncomingNotificationRef.current(response, AVAILABLE_STAGES.TOOLING);
                    });
                }

                if (haveRole(user, ROLES.TECH)) {
                    stompClient.subscribe('/sockets/tech', response => {
                        setIncomingNotificationRef.current(response, AVAILABLE_STAGES.TECH);
                    });
                }

                if (haveRole(user, ROLES.PACKING)) {
                    stompClient.subscribe('/sockets/packing', response => {
                        setIncomingNotificationRef.current(response, AVAILABLE_STAGES.PACKING);
                    });
                }
            });

            return stompClient;
        }

        return null;
    };

    useEffect(() => {
        const socket = initializeConnection();

        return () => {
            socket && socket.disconnect();
        };
    }, [pathname]);

    const getSocketURL = () => {
        const protocol = API.prefix === '' ? 'http' : 'https';
        const address = API.prefix === '' ? 'localhost:8080' : 'europoolerp.pl';
        return `${protocol}://${address}${API.prefix}/websocket/register`;
    };

    return {
        incomingNotifications,
        clearIncomingNotifications,
        removeIncomingNotification,
    };
};

export default useIncomingNotifications;
