import { REPORT_TYPES } from '../report.constants';
import ProductsFrom from './report-forms/products-form/products-form';

export const getForm = reportType => {
    switch (reportType) {
        case REPORT_TYPES.PRODUCTS:
            return ProductsFrom;
        default:
            break;
    }
};
