import styled from 'styled-components';
import { toRem } from '../../../Styles/theme';
import { Button } from 'react-bootstrap';

export const SchemaTitle = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;

    font-weight: bold;
`;

export const PrintButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${toRem(40)};
    height: ${toRem(30)};
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;

    font-weight: bold;
`;

export const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${toRem(10)};

    width: 30vw;
    height: 86vh;

    overflow-y: auto;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30vw;
    height: 86vh;
`;

export const LegendWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${toRem(10)};

    width: 30vw;
    height: 86vh;

    overflow-y: auto;
`;

export const LegendName = styled.text`
    font-weight: 600;
`;

export const CanvasImage = styled.img`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

export const OtherCanvasImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: ${props => props.size}px;

    width: ${props => props.size}px;
    height: ${props => props.size}px;

    border: solid 2px black;

    background: white;

    font-size: 25px;
    font-weight: 600;
`;

export const LegendHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const LegendItem = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px lightgrey;

    cursor: pointer;

    ${props => props.picked && 'background: whitesmoke'};

    &:hover {
        background: whitesmoke;
    }
`;

export const LegendDetails = styled.div`
    margin-left: ${toRem(10)};
`;

export const PrintWrapper = styled.div`
    display: none;
`;
