import React from 'react';
import { Redirect } from 'react-router-dom';
import { getRootPath } from './routing-utils';
import { useSelector } from 'react-redux';

const RootPage = () => {
    const { user, view } = useSelector(store => store.auth);

    return <Redirect to={{ pathname: getRootPath(user, view) }} />;
};

export default RootPage;
