import React, { Component } from 'react';
import CustomTable from '../Components/CustomTable';
import TrucksPanel from '../Components/ControlPanels/TrucksPanel';
import { ColumnsConfig } from '../Utils/ColumnsConfig';

import { getTrucks, addTruck, deleteTruck, editTruck } from '../Redux/Actions/TrucksActions';
import { connect } from 'react-redux';
import DeletionModal from '../Components/DeletionModal';

class TrucksPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registrationNumber: '',
            model: '',
            id: -1,
            isRowClicked: false,
            showModal: false,
        };
        this.columns = ColumnsConfig.TrucksColumns;
    }

    refresh = () => {
        this.props.getTrucks();
    };

    handleSubmit = fields => {
        this.props.addTruck(fields, this.refresh);
        this.clearFields();
    };

    handleOnRowClick = rowInfo => {
        if (typeof rowInfo === 'undefined') {
            return;
        }
        this.setState({
            registrationNumber: rowInfo.original.registrationNumber,
            model: rowInfo.original.model,
            id: rowInfo.original.id,
            isRowClicked: true,
        });
    };

    clearFields = () => {
        this.setState({
            registrationNumber: '',
            model: '',
        });
    };

    handleFormChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    };

    handleCancelClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    handleDeleteClick = () => {
        this.setState({ showModal: true });
    };

    handleRemoveClick = () => {
        this.setState({ isRowClicked: false, showModal: false });
        this.clearFields();
        const { id } = this.state;
        this.props.deleteTruck({ id }, this.refresh);
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    handleEditClick = fields => {
        this.setState({ isRowClicked: false });
        this.clearFields();
        const { id } = this.state;
        this.props.editTruck({ id, ...fields }, this.refresh);
    };

    render() {
        return (
            <div style={{ padding: 10 }}>
                <DeletionModal
                    show={this.state.showModal}
                    onHide={this.handleModalClose}
                    handleRemoveClick={this.handleRemoveClick}
                    text='Czy na pewno chcesz usunąć ten pojazd?'
                />
                <CustomTable
                    content={this.props.trucks}
                    columns={this.columns}
                    className='otherTables'
                    handleOnRowClick={this.handleOnRowClick}
                />
                <TrucksPanel
                    handleSubmit={this.handleSubmit}
                    registrationNumber={this.state.registrationNumber}
                    model={this.state.model}
                    handleFormChange={this.handleFormChange}
                    isRowClicked={this.state.isRowClicked}
                    handleCancelClick={this.handleCancelClick}
                    handleDeleteClick={this.handleDeleteClick}
                    handleEditClick={this.handleEditClick}
                    handleClearClick={this.clearFields}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    trucks: state.trucks.trucks,
});

const connected = connect(mapStateToProps, { getTrucks, addTruck, deleteTruck, editTruck })(TrucksPage);

export default connected;
