import axios from 'axios';
import { API, availableResourcesConstants } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getAvailableWorkers = (date_from, date_to, reservationId, refreshAssignedWorkers) => dispatch => {
    axios
        .get(API.availableWorkers, {
            params: { date_from, date_to, reservationId },
        })
        .then(res => {
            dispatch({
                type: availableResourcesConstants.GET_WORKERS,
                payload: res.data,
            });
            refreshAssignedWorkers(res.data);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getAvailableTrucks = (date_from, date_to, reservationId, refreshAssignedTruck) => dispatch => {
    axios
        .get(API.availableTrucks, {
            params: { date_from, date_to, reservationId },
        })
        .then(res => {
            dispatch({
                type: availableResourcesConstants.GET_TRUCKS,
                payload: res.data,
            });
            refreshAssignedTruck(res.data);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getAvailableTrailers = (date_from, date_to, reservationId, refreshAssignedTrailer) => dispatch => {
    axios
        .get(API.availableTrailers, {
            params: { date_from, date_to, reservationId },
        })
        .then(res => {
            dispatch({
                type: availableResourcesConstants.GET_TRAILERS,
                payload: res.data,
            });
            refreshAssignedTrailer(res.data);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
