import axios from 'axios';
import { trailerConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';

export const getTrailers = () => async dispatch => {
    try {
        const res = await axios.get(API.trailers);
        return dispatch({
            type: trailerConstants.GET,
            payload: res.data,
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const addTrailer = (trailer, refresh) => dispatch => {
    axios
        .post(API.trailers, trailer)
        .then(res => {
            refresh();
            NotificationManager.success('Przyczepa została dodana');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteTrailer = (trailer, refresh) => dispatch => {
    axios
        .delete(API.trailers, { data: trailer })
        .then(res => {
            refresh();
            NotificationManager.success('Przyczepa została usunięta');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editTrailer = (trailer, refresh) => dispatch => {
    axios
        .patch(API.trailers, trailer)
        .then(res => {
            refresh();
            NotificationManager.success('Przyczepa została zapisana');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
