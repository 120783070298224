import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { prepareUrl } from './routing-utils';

export const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
    const { user } = useSelector(state => state.auth);

    const authorized = user && user.roles && user.roles.some(role => roles.includes(role.name));

    return (
        <Route
            {...rest}
            render={props =>
                user && user.roles ? (
                    authorized ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={{ pathname: prepareUrl('/') }} />
                    )
                ) : (
                    <Redirect to={{ pathname: prepareUrl('/login'), state: { from: props.location } }} />
                )
            }
        />
    );
};
