import React, { useEffect, useRef, useState } from 'react';

import { API } from '../../../Constants';
import { CanvasImage, OtherCanvasImage } from './styles';
import { categorySymbols, getIconOffset } from './utlis';

const Canvas = ({
    productId,
    handleAddJsonData,
    handleChangeJsonData,
    jsonData,
    handleDeleteJsonData,
    handleAdjustCounterAdd,
    handleAdjustCounterDelete,
    readOnly,
    scale,
    disableEditing,
    printMode,
}) => {
    const canvasWrapper = useRef(null);

    const [xOffset, setXOffset] = useState(0);
    const [yOffset, setYOffset] = useState(0);

    useEffect(() => {
        calculateIconsPosition();

        if (!printMode) {
            window.addEventListener('resize', calculateIconsPosition);
        }
    }, []);

    const calculateIconsPosition = () => {
        if (canvasWrapper.current !== null) {
            const { x, y } = canvasWrapper.current.getBoundingClientRect();
            setXOffset(x);
            setYOffset(y);
        }
    };

    const width = scale ? scale * 400 : 400;
    const height = scale ? scale * 600 : 600;
    const iconSize = scale ? scale * 50 : 50;

    const onDrop = e => {
        e.preventDefault();
        const category = e.dataTransfer.getData('category');
        const counter = e.dataTransfer.getData('counter');
        const edit = e.dataTransfer.getData('edit');
        const index = e.dataTransfer.getData('index');
        const iconOffsetX = e.dataTransfer.getData('iconOffsetX');
        const iconOffsetY = e.dataTransfer.getData('iconOffsetY');
        const { pageX, pageY } = e;
        const { x, y, width, height } = canvasWrapper.current.getBoundingClientRect();

        const xCord = Math.round(((pageX - x - iconOffsetX) / width) * 100) / 100;
        const yCord = Math.round(((pageY - y - iconOffsetY - window.scrollY) / height) * 100) / 100;

        if (category === 'POOL_OTHER' && !edit) {
            handleAdjustCounterAdd(jsonData, { counter });
        }
        if (index) {
            handleChangeJsonData(index, {
                x: xCord,
                y: yCord,
                category,
                counter: category === 'POOL_OTHER' ? counter : null,
            });
        } else {
            handleAddJsonData({
                x: xCord,
                y: yCord,
                category,
                counter: category === 'POOL_OTHER' ? counter : null,
            });
        }
    };

    const onDragOver = e => {
        e.preventDefault();
    };

    const url = `${API.productSchema}/${productId}`;

    const onContextMenu = (e, index, category) => {
        e.preventDefault();
        if (category === 'POOL_OTHER') {
            handleAdjustCounterDelete(jsonData, index, category);
        }
        handleDeleteJsonData(index);
    };

    const dragStart = (e, category, index, counter) => {
        const [iconOffsetX, iconOffsetY] = getIconOffset(e);

        e.dataTransfer.setData('iconOffsetX', iconOffsetX);
        e.dataTransfer.setData('iconOffsetY', iconOffsetY);
        e.dataTransfer.setData('category', category);
        e.dataTransfer.setData('counter', counter);
        e.dataTransfer.setData('edit', true);
        e.dataTransfer.setData('index', index);
    };

    return (
        <>
            {canvasWrapper.current !== null &&
                jsonData.map((addon, index) => {
                    const x = xOffset + width * addon.x;
                    const y = readOnly ? yOffset + height * addon.y : yOffset + height * addon.y - 65;
                    return addon.category === 'POOL_OTHER' ? (
                        <OtherCanvasImage
                            readOnly={readOnly}
                            size={iconSize}
                            key={index}
                            draggable={!readOnly && !disableEditing}
                            top={y}
                            left={x}
                            onContextMenu={e => (readOnly || disableEditing ? () => {} : onContextMenu(e, index, addon.category))}
                            onDragStart={e => (readOnly || disableEditing ? () => {} : dragStart(e, addon.category, index, addon.counter))}
                        >
                            {addon.counter}
                        </OtherCanvasImage>
                    ) : (
                        <CanvasImage
                            size={iconSize}
                            readOnly={readOnly}
                            key={index}
                            draggable={!readOnly && !disableEditing}
                            src={categorySymbols[addon.category]}
                            top={y}
                            left={x}
                            onContextMenu={e => (readOnly || disableEditing ? () => {} : onContextMenu(e, index))}
                            onDragStart={e => (readOnly || disableEditing ? () => {} : dragStart(e, addon.category, index))}
                        />
                    );
                })}

            <div
                ref={canvasWrapper}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: `${width}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                    border: '1px solid lightgrey',
                    backgroundImage: `url(${url})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
                {...{ url }}
                onDrop={readOnly || disableEditing ? () => {} : onDrop}
                onDragOver={readOnly || disableEditing ? () => {} : onDragOver}
            />
        </>
    );
};

export default Canvas;
