import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { prepareUrl } from './routing/routing-utils';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.auth.user);

    return (
        <Route
            {...rest}
            render={props =>
                user && user.roles ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: prepareUrl('/login'), state: { from: props.location } }} />
                )
            }
        />
    );
};
