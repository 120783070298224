import { combineReducers } from 'redux';
import report from '../../pages/report/redux/report.reducer';
import auth from './AuthReducer';
import availableResources from './AvailableResourcesReducer';
import client from './client';
import licenseTypes from './LicenseTypesReducer';
import notifications from './NotificationsReducer';
import orders from './OrdersReducer';
import packages from './package.reducer';
import product from './product';
import provider from './provider';
import reservations from './ReservationsReducer';
import roles from './RolesReducer';
import trailers from './TrailersReducer';
import trucks from './TrucksReducer';
import users from './UsersReducer';
import workers from './WorkersReducer';

const RootReducer = combineReducers({
    auth,
    trucks,
    trailers,
    licenseTypes,
    workers,
    reservations,
    users,
    roles,
    orders,
    availableResources,
    notifications,
    provider,
    client,
    product,
    packages,
    report,
});

export default RootReducer;
