import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { PageWrapper } from './tech.styles';
import { FixedPanel, PackageHeader, PackagesList, PackageTitle, PackageWrapper, TitleWrapper } from './tech.styles';
import { haveRole, isDateBetween } from '../../../Utils/commons';
import { API } from '../../../Constants';
import { LoaderWrapper } from '../../../Components/packages/packages.styles';
import Loader from '../../../Components/UI/Loader/loader';
import WeekPicker from '../../../Components/week-picker/week-picker';
import { AVAILABLE_STAGES, CONTENT_REFRESH_INTERVAL, PRODUCT_HIGHLIGHT_TIMEOUT } from '../../../Constants/common';
import {
    activateLoadingPackages,
    getPackageInfo,
    getPackages,
    pickPackage,
    resetPackagesReducer,
    unhighlightProduct,
    updatePackages,
} from '../../../Redux/Actions/package.actions';
import PackageDetails from './package-details/package-details.component';

const Tech = () => {
    const [startDate, setStartDate] = useState(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));

    const dispatch = useDispatch();

    const { user } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.roles);
    const { loadingPackages, highlightedProductId, stage } = useSelector(store => store.packages);
    const pickedPackage = useSelector(store => store.packages.packages.find(pack => pack.id === store.packages.pickedPackageId));
    const packages = useSelector(store => store.packages.packages.sort((a, b) => new Date(a.deadline) - new Date(b.deadline)));

    useEffect(() => {
        dispatch(activateLoadingPackages());
        dispatchGetPackages();

        const interval = setInterval(() => dispatchGetPackages(), CONTENT_REFRESH_INTERVAL);

        return () => {
            dispatch(resetPackagesReducer());
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (pickedPackage && stage === AVAILABLE_STAGES.TECH) {
            dispatch(getPackageInfo(pickedPackage.id));
            if (pickedPackageIsFromAnotherWeek()) {
                adjustStartDate();
            }
        }
    }, [pickedPackage && pickedPackage.id]);

    useEffect(() => {
        if (highlightedProductId) {
            const packageEl = document.getElementById(`package-${pickedPackage.id}`);
            if (packageEl) {
                packageEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTimeout(() => {
                const el = document.getElementById(`product-item-${highlightedProductId}`);
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 300);

            setTimeout(() => {
                dispatch(unhighlightProduct());
            }, PRODUCT_HIGHLIGHT_TIMEOUT);
        }
    }, [highlightedProductId]);

    useEffect(() => {
        if (pickedPackage) {
            const packageEl = document.getElementById(`package-${pickedPackage.id}`);
            if (packageEl) {
                packageEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [startDate]);

    const pickedPackageIsFromAnotherWeek = () => !isDateBetween(new Date(pickedPackage.deadline), startDate, dateFns.addDays(startDate, 7));

    const adjustStartDate = () => setStartDate(dateFns.startOfWeek(new Date(pickedPackage.deadline), { weekStartsOn: 1 }));

    const resetStartDate = () => setStartDate(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));

    const dispatchGetPackages = () => {
        const role = roles.find(role => role.name === 'ROLE_TECH');
        const configIfAdmin = haveRole(user, 'ROLE_ADMIN') ? { params: { roleId: role.id } } : null;
        dispatch(getPackages(configIfAdmin, AVAILABLE_STAGES.TECH));
    };

    const patchProduct = async body => {
        try {
            await axios.patch(`${API.processStatus}`, body);
            return true;
        } catch (e) {
            console.log(e);
            NotificationManager.error('Błąd podczas odhaczania produtku');
            return false;
        }
    };

    const updateProduct = updatedProduct => {
        const productIndex = pickedPackage.products.findIndex(p => p.id === updatedProduct.id);
        const updatedProducts = [...pickedPackage.products];
        updatedProducts[productIndex] = updatedProduct;

        const updatedPackage = { ...pickedPackage, products: updatedProducts };
        const updatedPackages = [...packages];

        const packageIndex = packages.findIndex(pack => pack.id === pickedPackage.id);
        updatedPackages[packageIndex] = updatedPackage;

        dispatch(updatePackages(updatedPackages));
    };

    const changeProductStatus = async (product, status) => {
        const body = { productId: product.id, processStatus: status };
        const updated = await patchProduct(body);
        if (updated) {
            const updatedProduct = { ...product, status: status };
            updateProduct(updatedProduct);
        }
    };

    const handleOnCheckboxClick = product =>
        product.status === 'PENDING' ? changeProductStatus(product, 'DONE') : changeProductStatus(product, 'PENDING');

    // const getProductIds = () =>
    //     pickedPackage.products
    //         .filter(product => product.processStage.stage === 'TECH')
    //         .map(product => product.id)
    //         .join(',');

    const onNextClick = () => {
        dispatch(pickPackage(null));
        setStartDate(dateFns.addDays(startDate, 7));
    };
    const onBackClick = () => {
        dispatch(pickPackage(null));
        setStartDate(dateFns.subDays(startDate, 7));
    };

    const isDeadlineInShownWeek = orderDeadline => isDateBetween(orderDeadline, startDate, dateFns.addDays(startDate, 7));

    const onPackageClick = packageId => {
        dispatch(pickPackage(packageId));
        dispatch(getPackageInfo(packageId));
    };

    const getPackagesList = () => {
        const packagesInWeek = packages.filter(pack => isDeadlineInShownWeek(new Date(pack.deadline)));

        return packagesInWeek.length > 0 ? (
            packagesInWeek.map((pack, index) => (
                <PackageWrapper
                    id={`package-${pack.id}`}
                    key={index}
                    picked={pickedPackage && pickedPackage.id === pack.id}
                    onClick={() => onPackageClick(pack.id)}
                >
                    <PackageHeader>
                        <PackageTitle>
                            {pack.name} ({pack.deadline})
                        </PackageTitle>
                    </PackageHeader>
                </PackageWrapper>
            ))
        ) : (
            <LoaderWrapper>Brak paczek</LoaderWrapper>
        );
    };

    return (
        <PageWrapper>
            <FixedPanel>
                <TitleWrapper>Lista paczek</TitleWrapper>
                <WeekPicker {...{ startDate }} {...{ onBackClick }} {...{ onNextClick }} />
                <PackagesList>
                    {loadingPackages ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        getPackagesList()
                    )}
                </PackagesList>
            </FixedPanel>
            <PackageDetails {...{ handleOnCheckboxClick }} />
        </PageWrapper>
    );
};

export default Tech;
