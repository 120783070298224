import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomTable from '../Components/CustomTable';
import WorkersPanel from '../Components/ControlPanels/WorkersPanel';
import { ColumnsConfig } from '../Utils/ColumnsConfig';
import { getWorkers, addWorker, deleteWorker, editWorker } from '../Redux/Actions/WorkersActions';
import DeletionModal from '../Components/DeletionModal';

class WorkersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            workers: [],
            name: '',
            surname: '',
            language: '',
            license: -1,
            isRowClicked: false,
            id: -1,
        };
        this.columns = ColumnsConfig.WorkersColumns;
    }

    refresh = () => {
        this.props.getWorkers();
    };

    handleSubmit = fields => {
        fields.license = fields.license.value;
        this.props.addWorker(fields, this.refresh);
        this.clearFields();
    };

    clearFields = () => {
        this.setState({
            name: '',
            surname: '',
            language: '',
            license: -1,
        });
    };

    handleFormChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    };

    handlePickLicenseType = e => {
        this.setState({ license: e });
    };

    handleOnRowClick = rowInfo => {
        if (typeof rowInfo !== 'undefined') {
            this.setState({
                name: rowInfo.original.name,
                surname: rowInfo.original.surname,
                language: rowInfo.original.language,
                license: rowInfo.original.license
                    ? {
                          value: rowInfo.original.license.id,
                          label: rowInfo.original.license.type,
                      }
                    : -1,
                id: rowInfo.original.id,
                isRowClicked: true,
            });
        }
    };

    handleCancelClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    handleDeleteClick = () => {
        this.setState({ showModal: true });
    };

    handleRemoveClick = () => {
        this.setState({ isRowClicked: false, showModal: false });
        this.clearFields();
        const { id } = this.state;
        this.props.deleteWorker({ id }, this.refresh);
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    handleEditClick = fields => {
        this.setState({ isRowClicked: false });
        this.clearFields();
        const { id } = this.state;
        fields.license = fields.license.value;
        this.props.editWorker({ id, ...fields }, this.refresh);
    };

    render() {
        return (
            <div style={{ padding: 10 }}>
                <DeletionModal
                    show={this.state.showModal}
                    onHide={this.handleModalClose}
                    handleRemoveClick={this.handleRemoveClick}
                    text='Czy na pewno chcesz usunąć tego pracownika?'
                />
                <CustomTable
                    content={this.props.workers}
                    columns={this.columns}
                    className='otherTables'
                    handleOnRowClick={this.handleOnRowClick}
                />
                <WorkersPanel
                    name={this.state.name}
                    surname={this.state.surname}
                    language={this.state.language}
                    license={this.state.license}
                    handleSubmit={this.handleSubmit}
                    handleFormChange={this.handleFormChange}
                    handleCancelClick={this.handleCancelClick}
                    handleDeleteClick={this.handleDeleteClick}
                    isRowClicked={this.state.isRowClicked}
                    licenseTypes={this.props.licenseTypes}
                    handlePickLicenseType={this.handlePickLicenseType}
                    handleEditClick={this.handleEditClick}
                    handleClearClick={this.clearFields}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    licenseTypes: state.licenseTypes.licenseTypes,
    workers: state.workers.workers,
});

const connected = connect(mapStateToProps, { getWorkers, addWorker, deleteWorker, editWorker })(WorkersPage);

export default connected;
