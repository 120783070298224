import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import * as dateFns from 'date-fns';

import {
    DatePickers,
    DatePickerWrapper,
    DateTimePickerWrapper,
    IconButton,
    ItemsWrapper,
    PackHeader,
    PageWrapper,
    Panel,
    PlanningList,
    ProductName,
    TitleWrapper,
} from './planning.styles';
import { LoaderWrapper, OrderTitle, PackageTitle, ProductItem } from '../../../Components/packages/packages.styles';
import { API } from '../../../Constants';
import Loader from '../../../Components/UI/Loader/loader';
import { ButtonWrapper } from '../../../Components/OrderConfigurator/ProductList/styles';
import { NotificationManager } from 'react-notifications';
import { getClientFromOrder, haveRole, removeDuplicates } from '../../../Utils/commons';
import Collapsible from './components/collapsible/collapsible';
import { toRem } from '../../../Styles/theme';
import { AVAILABLE_STAGES, CONTENT_REFRESH_INTERVAL, PRODUCT_HIGHLIGHT_TIMEOUT } from '../../../Constants/common';
import {
    activateLoadingPackages,
    getPackages,
    hideCalendarModal,
    resetPackagesReducer,
    showCalendarModal,
    unhighlightProduct,
} from '../../../Redux/Actions/package.actions';
import { checkStatus } from '../../../Utils/CheckStatus';
import { Calendar } from './components/calendar/calendar';

const Planning = () => {
    const [id, setId] = useState(-1);
    const [showAcceptanceModal, setShowAcceptanceModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [propositionDate, setPropositionDate] = useState(new Date());
    const [processLoading, setProcessLoading] = useState(false);
    const [withdrawLoading, setWithdrawLoading] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [planningProducts, setPlanningProducts] = useState({});
    const [dropUp, setDropUp] = useState(false);
    const [openedCalendar, setOpenedCalendar] = useState(undefined);

    const dispatch = useDispatch();

    const { user } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.roles);
    const { loadingPackages, ifShowCalendarModal, today, todayProducts, highlightedProductId } = useSelector(store => store.packages);
    const packages = useSelector(store =>
        store.packages.packages.filter(pack => pack.products.some(product => product.processStage.stage === 'PLANNING'))
    );
    const plannedProducts = useSelector(store =>
        store.packages.packages
            .map(pack => pack.products)
            .reduce((result, current) => [...result, ...current], [])
            .filter(product => product.processStage.stage === 'PRODUCTION')
    );

    useEffect(() => {
        dispatch(activateLoadingPackages());
        dispatchGetPackages();

        const interval = setInterval(() => dispatchGetPackages(), CONTENT_REFRESH_INTERVAL);

        return () => {
            clearInterval(interval);
            dispatch(resetPackagesReducer());
        };
    }, []);

    useEffect(() => {
        if (highlightedProductId && ifShowCalendarModal) {
            const el = document.getElementById(`product-item-${highlightedProductId}`);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTimeout(() => {
                dispatch(unhighlightProduct());
            }, PRODUCT_HIGHLIGHT_TIMEOUT);
        }
    }, [highlightedProductId, ifShowCalendarModal]);

    const dispatchGetPackages = () => {
        const role = roles.find(role => role.name === 'ROLE_PRODUCTION');
        const configIfAdmin = haveRole(user, 'ROLE_ADMIN') ? { params: { roleId: role.id } } : null;
        dispatch(getPackages(configIfAdmin, AVAILABLE_STAGES.PLANNING));
    };

    const processPackage = async () => {
        setProcessLoading(true);
        try {
            await axios.post(`${API.processPlanning}?packageId=${id}`, planningProducts[id]);
            NotificationManager.success('Paczka została zaakceptowana');
            dispatchGetPackages();
            setShowAcceptanceModal(false);
            setPlanningProducts({ ...planningProducts, [id]: undefined });
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }
        setProcessLoading(false);
    };

    const withdrawPackage = async () => {
        setWithdrawLoading(true);
        try {
            await axios.post(`${API.processWithdraw}/${id}`, null, {
                params: { propositionDate: propositionDate.toISOString().split('T')[0] },
            });
            NotificationManager.success('Paczka została odrzucona');
            dispatchGetPackages();
            setPlanningProducts({ ...planningProducts, [id]: undefined });
            setShowRejectionModal(false);
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }
        setWithdrawLoading(false);
    };

    const onAcceptClick = id => {
        setId(id);
        setShowAcceptanceModal(true);
    };

    const onRejectClick = id => {
        setId(id);
        setShowRejectionModal(true);
    };

    const closeAcceptanceModal = () => {
        setShowAcceptanceModal(false);
    };

    const closeCalendarModal = () => {
        dispatch(hideCalendarModal());
        dispatchGetPackages();
    };

    const closeRejectionModal = () => {
        setShowRejectionModal(false);
        setPropositionDate(new Date());
    };

    const renderCollapsibleHeader = pack => {
        const planningProductsFromPackage = planningProducts[pack.id] || [];

        const disabled = planningProductsFromPackage.length !== pack.products.length;

        return (
            <PackHeader>
                <PackageTitle>
                    {pack.name} - {pack.reservationDateTo} ({pack.deadline})
                </PackageTitle>
                <ButtonWrapper>
                    <IconButton variant='success' onClick={() => onAcceptClick(pack.id)} {...{ disabled }}>
                        <CheckIcon />
                    </IconButton>
                    <IconButton variant='danger' onClick={() => onRejectClick(pack.id)}>
                        <CloseIcon />
                    </IconButton>
                </ButtonWrapper>
            </PackHeader>
        );
    };

    const updateDropUp = event => {
        if (openedCalendar) return;

        if (event.pageY > window.innerHeight * 0.55) {
            setDropUp(true);
        } else {
            setDropUp(false);
        }
    };

    const renderCollapsibleContent = (pack, ordersInPackage) => {
        return (
            <ItemsWrapper>
                {ordersInPackage.map((order, i) => (
                    <>
                        <OrderTitle>
                            Zamówienie #{i + 1}: {getClientFromOrder(order)} - {order.description}
                        </OrderTitle>

                        {pack.products
                            .filter(product => product.product.order.id === order.id)
                            .map((product, index) => {
                                let value = undefined;

                                if (planningProducts[pack.id]) {
                                    const found = planningProducts[pack.id].find(x => x.productAmountId === product.id);
                                    if (found) {
                                        value = new Date(
                                            planningProducts[pack.id].find(x => x.productAmountId === product.id).productionTerm
                                        );
                                    }
                                }

                                const disabled = openedCalendar && openedCalendar !== `date-picker-${pack.id}-${product.id}`;

                                return (
                                    <ProductItem key={index}>
                                        <ProductName>
                                            {product.product.productSchema.name}
                                            <DateTimePickerWrapper
                                                onClick={event => {
                                                    updateDropUp(event);
                                                }}
                                            >
                                                <DateTimePicker
                                                    messages={{ dateButton: 'Termin' }}
                                                    culture='pl'
                                                    placeholder='Termin'
                                                    time={false}
                                                    min={new Date()}
                                                    onSelect={date => {
                                                        addPoolToPlanned(product.id, date, pack.id);
                                                        setOpenedCalendar(undefined);
                                                    }}
                                                    {...{ dropUp }}
                                                    {...{ value }}
                                                    {...{ disabled }}
                                                    containerClassName='rw-calendar-wrapper-custom-classname'
                                                    onToggle={open =>
                                                        setOpenedCalendar(open ? `date-picker-${pack.id}-${product.id}` : undefined)
                                                    }
                                                    inputProps={{
                                                        component: props => <input {...props} readOnly />,
                                                    }}
                                                />
                                            </DateTimePickerWrapper>
                                        </ProductName>
                                        {product.product.description}
                                    </ProductItem>
                                );
                            })}
                    </>
                ))}
            </ItemsWrapper>
        );
    };

    const nextMonthClick = () => {
        setCurrentMonth(dateFns.addMonths(currentMonth, 1));
    };

    const prevMonthClick = () => {
        setCurrentMonth(dateFns.subMonths(currentMonth, 1));
    };

    const setProductionDate = (productAmountId, date) => {
        const productionTerm = dateFns.format(date, 'yyyy-MM-dd', { awareOfUnicodeTokens: true });
        try {
            axios.patch(API.productProductionDate, { productAmountId, productionTerm });
        } catch (e) {
            console.log(e.response);
            if (e.response) checkStatus(e.response, dispatch);
        }
    };

    const onCellClick = todayProducts => {
        dispatch(showCalendarModal(todayProducts));
    };

    const addPoolToPlanned = (productAmountId, date, packageId) => {
        const productionTerm = dateFns.format(date, 'yyyy-MM-dd', { awareOfUnicodeTokens: true });

        const planningProductsFromPackage = planningProducts[packageId] || [];

        const alreadyPlannedIndex = planningProductsFromPackage.findIndex(x => x.productAmountId === productAmountId);
        if (alreadyPlannedIndex === -1) {
            setPlanningProducts({
                ...planningProducts,
                [packageId]: [...planningProductsFromPackage, { productAmountId, productionTerm }],
            });
        } else {
            const updated = [...planningProductsFromPackage];
            updated[alreadyPlannedIndex] = { productAmountId, productionTerm };
            setPlanningProducts({ ...planningProducts, [packageId]: updated });
        }
    };

    const renderAcceptanceModal = () => {
        return (
            <Modal show={showAcceptanceModal} onHide={closeAcceptanceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Zaplanuj baseny i zaakceptuj termin</Modal.Title>
                </Modal.Header>
                <Modal.Body>Czy na pewno chcesz zaakceptować paczkę i zaplanować baseny?</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={closeAcceptanceModal}>
                        Anuluj
                    </Button>
                    {processLoading ? (
                        <Loader size={toRem(40)} />
                    ) : (
                        <Button
                            variant='success'
                            onClick={() => {
                                processPackage();
                            }}
                        >
                            Akceptuj
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    };

    const renderCalendarModal = () => {
        const ordersInPackage = removeDuplicates(
            todayProducts.map(product => product.product.order),
            'id'
        );

        return (
            <Modal show={ifShowCalendarModal} onHide={closeCalendarModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Baseny zaplanowane na {dateFns.format(today, 'dd-MM-yyyy', { awareOfUnicodeTokens: true })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePickers>
                        {ordersInPackage.map((order, i) => (
                            <>
                                <OrderTitle>
                                    Zamówienie #{i + 1}: {getClientFromOrder(order)} - {order.description}
                                </OrderTitle>

                                {todayProducts
                                    .filter(product => product.product.order.id === order.id)
                                    .map(product => {
                                        const disabled = today < new Date() && !dateFns.isSameDay(today, new Date());
                                        return (
                                            <DatePickerWrapper
                                                id={`product-item-${product.id}`}
                                                highlighted={product.id === highlightedProductId}
                                            >
                                                {`${product.product.productSchema.name} ${
                                                    product.product.description ? `- ${product.product.description}` : ''
                                                }`}
                                                <div style={{ width: '300px', minWidth: '300px' }}>
                                                    <DateTimePicker
                                                        messages={{ dateButton: 'Wybierz termin' }}
                                                        culture='pl'
                                                        placeholder='Wybierz termin'
                                                        time={false}
                                                        min={new Date()}
                                                        defaultValue={today}
                                                        disabled={disabled}
                                                        onSelect={date => setProductionDate(product.id, date)}
                                                        inputProps={{
                                                            component: props => <input {...props} readOnly />,
                                                        }}
                                                    />
                                                </div>
                                            </DatePickerWrapper>
                                        );
                                    })}
                            </>
                        ))}
                    </DatePickers>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={closeCalendarModal}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <PageWrapper>
            {renderAcceptanceModal()}
            {renderCalendarModal()}
            <Modal show={showRejectionModal} onHide={closeRejectionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdź operację</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label htmlFor='propositionDate'>Odrzuć i zaproponuj nowy termin</label>

                        <DateTimePicker
                            name='propositionDate'
                            value={propositionDate}
                            messages={{ dateButton: 'Wybierz termin' }}
                            culture='pl'
                            placeholder='Wybierz termin'
                            time={false}
                            onChange={e => {
                                setPropositionDate(e);
                            }}
                            min={new Date()}
                            inputProps={{
                                component: props => <input {...props} readOnly />,
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={closeRejectionModal}>
                        Anuluj
                    </Button>
                    {withdrawLoading ? (
                        <Loader size={toRem(40)} />
                    ) : (
                        <Button variant='danger' onClick={withdrawPackage}>
                            Odrzuć
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <Panel>
                <TitleWrapper>Paczki do zaplanowania</TitleWrapper>
                <PlanningList>
                    {loadingPackages ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        <>
                            {packages.length > 0 ? (
                                packages.map((pack, i) => {
                                    const ordersInPackage = removeDuplicates(
                                        pack.products.map(product => product.product.order),
                                        'id'
                                    );

                                    return (
                                        <Collapsible
                                            key={i}
                                            renderHeader={() => renderCollapsibleHeader(pack)}
                                            renderContent={() => renderCollapsibleContent(pack, ordersInPackage)}
                                        />
                                    );
                                })
                            ) : (
                                <LoaderWrapper>Brak paczek do zaplanowania</LoaderWrapper>
                            )}
                        </>
                    )}
                </PlanningList>
            </Panel>
            <Calendar {...{ currentMonth }} {...{ prevMonthClick }} {...{ nextMonthClick }} {...{ plannedProducts }} {...{ onCellClick }} />
        </PageWrapper>
    );
};

export default Planning;
