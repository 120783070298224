import React from 'react';
import { ProductAddon, ProductHeader } from '../OrderConfigurator/ProductList/styles';
import { Button, Form } from 'react-bootstrap';
import {
    PicklistButtonsWrapper,
    PicklistColumnWrapper,
    PicklistContentWrapper,
    ProductItem,
    AmountWrapper,
    LoaderWrapper,
    OrderTitle,
} from './packages.styles';
import Loader from '../UI/Loader/loader';
import { useSelector } from 'react-redux';
import { getClientFromOrder, haveRole, removeDuplicates } from '../../Utils/commons';
import { ROLES } from '../../Constants/roles';

export const renderAddons = product => (
    <>
        {product.filterRoomEquipment &&
            product.filterRoomEquipment.map((addon, index) => {
                return (
                    <ProductAddon key={index}>{`${addon.productSchema.name}${addon.provider ? ` (${addon.provider.name})` : ''} (szt. ${
                        addon.amount
                    }) ${addon.description ? `- ${addon.description}` : ''}`}</ProductAddon>
                );
            })}

        {product.poolEquipment &&
            product.poolEquipment.map((addon, index) => {
                return (
                    <ProductAddon key={index}>{`${addon.productSchema.name}${addon.provider ? ` (${addon.provider.name})` : ''} (szt. ${
                        addon.amount
                    }) ${addon.description ? `- ${addon.description}` : ''}`}</ProductAddon>
                );
            })}
    </>
);

const Picklist = props => {
    const { user } = useSelector(store => store.auth);
    const isAdmin = haveRole(user, ROLES.ADMIN);

    const reservation = useSelector(store => {
        const task = store.reservations.reservations.find(r => r.id === props.reservationId);
        return task || store.reservations.archiveTasks.find(r => r.id === props.reservationId);
    });

    const ordersInPackage = reservation
        ? reservation.orderIds.map(orderId => orderId.value)
        : props.pack
        ? removeDuplicates(
              props.pack.products.map(product => product.product.order),
              'id'
          ).map(order => order.id)
        : [];

    const disableRemoveButton = () => {
        if (props.pickedProduct === undefined || props.pickedProduct.includes('available') || props.showArchiveTasks) {
            return true;
        }

        const [, pickedId] = props.pickedProduct.split('-');
        const product = { ...props.assignedProducts.find(product => product.id == pickedId) };

        return product.updatable === false && product.amount <= product.blockedAmount;
    };

    const disableAssignSingle = props.pickedProduct === undefined || props.pickedProduct.includes('assigned') || (props.isPool && !isAdmin);

    return (
        <PicklistContentWrapper>
            <PicklistColumnWrapper>
                {props.availableLoading ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : props.availableProducts.length > 0 ? (
                    ordersInPackage.map((orderId, i) =>
                        props.availableProducts
                            .filter(product => product.order.id === orderId)
                            .map((product, index) => (
                                <>
                                    {index === 0 && (
                                        <OrderTitle>
                                            Zamówienie #{i + 1}: {getClientFromOrder(product.order)} - {product.order.description}
                                        </OrderTitle>
                                    )}
                                    <ProductItem
                                        key={index}
                                        onClick={() => props.handleOnProductClick(`available-${product.id}`)}
                                        picked={props.pickedProduct === `available-${product.id}`}
                                    >
                                        <ProductHeader>
                                            {`${product.productSchema.name}${
                                                product.providerName ? ` (${product.providerName})` : ''
                                            } (szt. ${product.amount}) ${product.description ? `- ${product.description}` : ''}`}
                                        </ProductHeader>
                                        {renderAddons(product)}
                                    </ProductItem>
                                </>
                            ))
                    )
                ) : (
                    <LoaderWrapper>Brak dostępnych produktów</LoaderWrapper>
                )}
            </PicklistColumnWrapper>
            <PicklistButtonsWrapper>
                <AmountWrapper>
                    <label>Ilość</label>
                    <Form.Control
                        placeholder='Wprowadź Ilość'
                        type='number'
                        min='1'
                        max={props.maxAmount}
                        value={props.assignAmount}
                        onChange={props.handleAmountChange}
                        disabled={props.pickedProduct === undefined}
                        onKeyDown={e => {
                            e.preventDefault();
                        }}
                    />
                </AmountWrapper>
                <Button disabled={disableAssignSingle} onClick={props.handleAssignSingle}>
                    Przydziel
                </Button>
                <Button disabled={props.availableProducts.length === 0 || props.isEditWithDeadline} onClick={props.handleAssignAll}>
                    Przydziel wszystkie
                </Button>
                <Button disabled={disableRemoveButton()} onClick={props.handleDeleteSingle}>
                    Usuń
                </Button>
                <Button
                    disabled={props.assignedProducts.length === 0 || props.productId || props.isEditWithDeadline}
                    onClick={props.handleDeleteAll}
                >
                    Usuń wszystkie
                </Button>
            </PicklistButtonsWrapper>
            <PicklistColumnWrapper>
                {props.assignedLoading ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : props.assignedProducts.length > 0 ? (
                    ordersInPackage.map((orderId, i) => {
                        return props.assignedProducts
                            .filter(product => product.order.id === orderId)
                            .map((product, index) => {
                                const providerName = product.provider
                                    ? ` (${product.provider.name})`
                                    : product.providerName
                                    ? ` (${product.providerName})`
                                    : '';
                                return (
                                    <>
                                        {index === 0 && (
                                            <OrderTitle>
                                                Zamówienie #{i + 1}: {getClientFromOrder(product.order)} - {product.order.description}
                                            </OrderTitle>
                                        )}
                                        <ProductItem
                                            key={index}
                                            onClick={() => props.handleOnProductClick(`assigned-${product.id}`)}
                                            picked={props.pickedProduct === `assigned-${product.id}`}
                                            disabled={
                                                (props.productId > 0 && props.productId !== product.id) ||
                                                (product.updatable === false && product.amount <= product.blockedAmount)
                                            }
                                        >
                                            <ProductHeader>
                                                {`${product.productSchema.name}${providerName} (szt. ${product.amount}) ${
                                                    product.description ? `- ${product.description}` : ''
                                                }`}
                                            </ProductHeader>
                                            {renderAddons(product)}
                                        </ProductItem>
                                    </>
                                );
                            });
                    })
                ) : (
                    <LoaderWrapper>Brak przypisanych produktów</LoaderWrapper>
                )}
            </PicklistColumnWrapper>
        </PicklistContentWrapper>
    );
};

export default Picklist;
