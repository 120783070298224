import axios from 'axios';
import { ordersConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';
import { preprocessOrderResponse } from '../../Utils/PreprocessResponse';

export const getActiveOrders = () => dispatch => {
    axios
        .get(API.orders, { params: { active: true } })
        .then(res => {
            dispatch({
                type: ordersConstants.GET,
                payload: preprocessOrderResponse(
                    res.data.sort((a, b) => {
                        if (a.dueDate && b.dueDate) {
                            const a_parts = a.dueDate.split('-');
                            const b_parts = b.dueDate.split('-');

                            const a_date = new Date(a_parts[2], a_parts[1] - 1, a_parts[0]);
                            const b_date = new Date(b_parts[2], b_parts[1] - 1, b_parts[0]);

                            return a_date > b_date ? 1 : b_date > a_date ? -1 : 0;
                        } else if (a.dueDate && !b.dueDate) {
                            return -1;
                        } else if (!a.dueDate && b.dueDate) {
                            return 1;
                        } else {
                            return 1;
                        }
                    })
                ),
            });
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getNotHiddenOrders = () => dispatch => {
    axios
        .get(API.orders, { params: { hidden: false, active: true } })
        .then(res => {
            dispatch({
                type: ordersConstants.GET_NOT_HIDDEN,
                payload: preprocessOrderResponse(res.data.sort((a, b) => (a.dueDate > b.dueDate ? 1 : b.dueDate > a.dueDate ? -1 : 0))),
            });
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const addOrder = (order, refresh) => dispatch => {
    axios
        .post(API.orders, order)
        .then(res => {
            NotificationManager.success('Zamówienie zostało dodane');
            refresh();
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteOrder = (order, refresh) => dispatch => {
    axios
        .delete(API.orders, { data: order })
        .then(res => {
            refresh();
            NotificationManager.success('Zamówienie zostało zakończone');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editOrder = (order, refresh) => dispatch => {
    axios
        .patch(API.orders, order)
        .then(res => {
            refresh();
            NotificationManager.success('Zamówienie zostało zapisane');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const getProducts = orderId => async dispatch => {
    try {
        const result = await axios.get(API.orderProducts, {
            params: { orderId },
        });
        dispatch({
            type: ordersConstants.GET_ORDER_PRODUCTS,
            payload: result.data,
        });
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const addProduct = (product, handleSuccessfulSave, handleFailedSave) => async dispatch => {
    try {
        const _ = await axios.post(API.orderProducts, product);
        NotificationManager.success('Produkt został dodany do zamówienia');
        handleSuccessfulSave();
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
        handleFailedSave();
    }
};

export const deleteProduct = (id, handleSuccessfulDelete) => async dispatch => {
    try {
        const _ = await axios.delete(API.orderProducts, { data: { id } });
        NotificationManager.success('Produkt został usuniety z zamówienia');
        handleSuccessfulDelete();
    } catch (err) {
        console.log(err);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const editProduct = (product, handleSuccessfulSave, handleFailedSave) => async dispatch => {
    try {
        const _ = await axios.patch(API.orderProducts, product);
        NotificationManager.success('Zmiany zostały zapisane');
        handleSuccessfulSave();
    } catch (err) {
        console.log(err);
        handleFailedSave();
        if (err.response) checkStatus(err.response, dispatch);
    }
};
