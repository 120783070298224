import { ordersConstants } from '../../Constants';

const initialState = {
    orders: [],
    notHiddenOrders: [],
    products: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ordersConstants.GET:
            return {
                ...state,
                orders: action.payload,
            };
        case ordersConstants.GET_NOT_HIDDEN:
            return {
                ...state,
                notHiddenOrders: action.payload,
            };
        case ordersConstants.GET_ORDER_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            };
        default:
            return state;
    }
}
