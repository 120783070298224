import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import * as dateFns from 'date-fns';

import { haveRole } from '../../../Utils/commons';
import { API } from '../../../Constants';
import { PageWrapper } from '../planning/planning.styles';
import Loader from '../../../Components/UI/Loader/loader';
import { toRem } from '../../../Styles/theme';
import { AVAILABLE_STAGES, CONTENT_REFRESH_INTERVAL, PRODUCT_HIGHLIGHT_TIMEOUT } from '../../../Constants/common';
import { activateLoadingPackages, getPackages, resetPackagesReducer, unhighlightProduct } from '../../../Redux/Actions/package.actions';
import { checkStatus } from '../../../Utils/CheckStatus';
import WeekView from './week-view/week-view';

const Production = () => {
    const [id, setId] = useState(-1);
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [processLoading, setProcessLoading] = useState(false);
    const [startDate, setStartDate] = useState(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));

    const dispatch = useDispatch();

    const { user } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.roles);
    const { loadingPackages, highlightedProductId } = useSelector(store => store.packages);
    const { notifications } = useSelector(store => store.notifications);

    const products = useSelector(store =>
        store.packages.packages
            .map(pack => pack.products)
            .reduce((result, current) => [...result, ...current], [])
            .filter(product => product.processStage.stage === 'PRODUCTION')
    );

    useEffect(() => {
        dispatch(activateLoadingPackages());
        dispatchGetPackages();

        const interval = setInterval(() => dispatchGetPackages(), CONTENT_REFRESH_INTERVAL);

        return () => {
            clearInterval(interval);
            dispatch(resetPackagesReducer());
        };
    }, []);

    useEffect(() => {
        if (highlightedProductId) {
            const el = document.getElementById(`product-item-${highlightedProductId}`);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                const highlightedProduct = products.find(x => x.id === highlightedProductId);
                adjustStartDate(highlightedProduct);
            }
            setTimeout(() => {
                dispatch(unhighlightProduct());
            }, PRODUCT_HIGHLIGHT_TIMEOUT);
        }
    }, [highlightedProductId]);

    useEffect(() => {
        if (highlightedProductId) {
            const el = document.getElementById(`product-item-${highlightedProductId}`);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [startDate]);

    const adjustStartDate = product => setStartDate(dateFns.startOfWeek(new Date(product.productionTerm), { weekStartsOn: 1 }));

    const dispatchGetPackages = () => {
        const role = roles.find(role => role.name === 'ROLE_PRODUCTION');
        const configIfAdmin = haveRole(user, 'ROLE_ADMIN') ? { params: { roleId: role.id } } : null;
        dispatch(getPackages(configIfAdmin, AVAILABLE_STAGES.PRODUCTION));
    };

    const finishedClick = id => {
        setId(id);
        setShowFinishModal(true);
    };

    const closeFinishModal = () => setShowFinishModal(false);

    const processPackage = async () => {
        setProcessLoading(true);

        try {
            await axios.post(`${API.process}?productIds=${id}`);
            NotificationManager.success('Produkt został zakończony');
            dispatchGetPackages();
            setShowFinishModal(false);
        } catch (e) {
            NotificationManager.error('Błąd podczas zakończenia produktu');
            if (e.response) checkStatus(e.response, dispatch);
        }

        setProcessLoading(false);
    };

    const disableProcessPackage = productId => notifications.some(x => x.productAmountId === productId);

    return (
        <PageWrapper>
            <Modal show={showFinishModal} onHide={closeFinishModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdź operację</Modal.Title>
                </Modal.Header>
                <Modal.Body>Czy ten basen został ukończony?</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={closeFinishModal}>
                        Anuluj
                    </Button>
                    {processLoading ? (
                        <Loader size={toRem(40)} />
                    ) : (
                        <Button
                            variant='success'
                            onClick={() => {
                                processPackage();
                            }}
                        >
                            Zakończ
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <WeekView
                {...{ startDate }}
                {...{ setStartDate }}
                {...{ disableProcessPackage }}
                {...{ finishedClick }}
                {...{ highlightedProductId }}
                {...{ loadingPackages }}
                {...{ products }}
            />
        </PageWrapper>
    );
};

export default Production;
