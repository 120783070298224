import { rolesConstants } from '../../Constants';

const initialState = {
    roles: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case rolesConstants.GET:
            return {
                ...state,
                roles: action.payload,
            };
        default:
            return state;
    }
}
