import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import RequiredFieldIndicator from '../RequiredFieldIndicator';
import Select from 'react-select';
import FileUploader from '../FileUploader';
import ImagePreview from '../ImagePreview';
import { API } from '../../Constants';
import { useSelector } from 'react-redux';
import HistoryIcon from "@material-ui/icons/History";
import Divider from "@material-ui/core/Divider";

const ProductsPanel = props => {
    const originalCategories = useSelector(store => store.product.categories);

    return (
        <div className='panel-div'>
            <Formik
                enableReinitialize
                initialValues={{
                    name: props.name,
                    category: props.category,
                    subCategory: props.subCategory,
                    assignedProviders: props.assignedProviders,
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('To pole jest wymagane'),
                    category: Yup.mixed().notOneOf([-1], 'To pole jest wymagane'),
                    subCategory: Yup.mixed().notOneOf([-1], 'To pole jest wymagane'),
                    assignedProviders: Yup.array().min(1, 'To pole jest wymagane').nullable().required('To pole jest wymagane'),
                })}
                onSubmit={(fields, { resetForm }) => {
                    if (props.isRowClicked) {
                        props.handleEditClick(fields);
                    } else {
                        props.handleSubmit(fields);
                    }
                    resetForm();
                }}
                render={({ errors, status, touched, fields, values, setFieldValue }) => (
                    <Form>
                        <div style={{display: "flex"}}>
                            <Button onClick={props.handleArchiveClick} style={{flex: 1}} variant={props.showArchive ? "secondary" : "primary"}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px"}}>
                                    <HistoryIcon />Produkty zarchiwizowane
                                </div>
                            </Button>
                        </div>
                        <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
                        <div className='form-group'>
                            <label htmlFor='name'>Nazwa</label>
                            <RequiredFieldIndicator />
                            <Field
                                name='name'
                                placeholder='Wprowadź nazwę'
                                type='text'
                                className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name='name' component='div' className='invalid-feedback' />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='category'>Kategoria</label>
                            <RequiredFieldIndicator />
                            <Select
                                name='category'
                                value={values['category']}
                                onChange={e => {
                                    setFieldValue('category', e);
                                    if (originalCategories.some(originalCategory => originalCategory.id === e.value)) {
                                        setFieldValue('subCategory', e);
                                    }
                                }}
                                placeholder='Wybierz kategorię'
                                isSearchable={true}
                                isDisabled={props.isRowClicked}
                                styles={
                                    errors.category && touched.category
                                        ? {
                                              control: styles => ({
                                                  ...styles,
                                                  borderColor: 'red',
                                                  '&:hover': {
                                                      borderColor: 'red',
                                                  },
                                              }),
                                          }
                                        : {}
                                }
                                options={props.mainCategories.map(category => ({
                                    value: category.id,
                                    label: category.label,
                                }))}
                            />
                            <ErrorMessage name='category' component='div' style={{ color: 'red', fontSize: '80%' }} />
                        </div>

                        {values['category'] !== -1 && props.subCategories[values['category'].value] && (
                            <div className='form-group'>
                                <label htmlFor='subCategory'>Podkategoria</label>
                                <RequiredFieldIndicator />
                                <Select
                                    name='subCategory'
                                    value={values['subCategory']}
                                    onChange={e => setFieldValue('subCategory', e)}
                                    placeholder='Wybierz podkategorię'
                                    isSearchable={true}
                                    isDisabled={props.isRowClicked}
                                    styles={
                                        errors.subCategory && touched.subCategory
                                            ? {
                                                  control: styles => ({
                                                      ...styles,
                                                      borderColor: 'red',
                                                      '&:hover': {
                                                          borderColor: 'red',
                                                      },
                                                  }),
                                              }
                                            : {}
                                    }
                                    options={props.subCategories[values['category'].value].map(category => ({
                                        value: category.id,
                                        label: category.label,
                                    }))}
                                />
                                <ErrorMessage name='subCategory' component='div' style={{ color: 'red', fontSize: '80%' }} />
                            </div>
                        )}

                        <div className='form-group'>
                            <label htmlFor='providers'>Dostawcy</label>
                            <RequiredFieldIndicator />
                            <Select
                                isSearchable={true}
                                value={values['assignedProviders']}
                                isMulti={true}
                                onChange={e => {
                                    setFieldValue('assignedProviders', e);
                                }}
                                placeholder='Wybierz dostawców'
                                styles={
                                    errors.assignedProviders && touched.assignedProviders
                                        ? {
                                              control: styles => ({
                                                  ...styles,
                                                  borderColor: 'red',
                                                  '&:hover': {
                                                      borderColor: 'red',
                                                  },
                                              }),
                                          }
                                        : {}
                                }
                                options={props.providers.map(provider => ({
                                    value: provider.id,
                                    label: provider.name,
                                }))}
                            />

                            <ErrorMessage name='assignedProviders' component='div' style={{ color: 'red', fontSize: '80%' }} />
                        </div>

                        {values['category'].value === 'POOL' && (
                            <div className='form-group'>
                                <label htmlFor='image'>Schemat</label>
                                <FileUploader handleFileChanged={props.handleFileChanged} />

                                {props.isRowClicked && <ImagePreview imageUrl={`${API.productSchema}/${props.id}`} />}
                            </div>
                        )}

                        {!props.isRowClicked && (
                            <>
                                <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                    Dodaj
                                </button>
                                <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                    Wyczyść
                                </button>
                            </>
                        )}
                        {props.isRowClicked && (
                            <>
                                <button type='submit' className='btn btn-primary' style={{ marginRight: '5%', width: '30%' }}>
                                    Zapisz
                                </button>
                                <Button onClick={props.handleCancelClick} variant='secondary' style={{ marginRight: '5%', width: '30%' }}>
                                    Anuluj
                                </Button>
                                <Button
                                    onClick={props.handleDeleteClick}
                                    variant={props.showArchive ? "success" : "danger"}
                                    style={{ width: '30%' }}
                                    disabled={!props.isRowClicked}
                                >
                                    {props.showArchive ? "Przywróć" : "Archiwizuj"}
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            />
        </div>
    );
};

export default ProductsPanel;
