import styled, { css } from 'styled-components';
import { Icon } from '../../../../icons/utils';
import { COLORS, toRem } from '../../../../Styles/theme';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${toRem(10)};

    ${Icon} {
        color: lightgrey;

        transition: color 0.3s, transform 0.3s;

        ${({ active, desc }) =>
            desc &&
            active &&
            css`
                transform: rotate(180deg);
            `}

        ${({ active }) =>
            active &&
            css`
                color: ${COLORS.PRIMARY};
            `}

        &:first-of-type {
            opacity: 0;
        }
    }
`;
