import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    z-index: 100;

    position: fixed;

    top: 65px;
    left: -100%;

    width: 80%;
    height: 90vh;

    padding: 10px;

    -webkit-transition: 0.75s ease-in-out;
    -moz-transition: 0.75s ease-in-out;
    -o-transition: 0.75s ease-in-out;
    transition: 0.75s ease-in-out;

    ${props =>
        props.show &&
        `-webkit-transform: translate(125%, 0);
    -moz-transform: translate(125%, 0);
    -o-transform: translate(125%, 0);
    -ms-transform: translate(125%, 0);
    transform: translate(125%, 0);`};
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: 90vh;

    padding: 10px;
    border-radius: 15px;
    border: 3px solid #a8a8a88c;
    box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);

    background: white;
`;

const SlidableModal = ({ show, children }) => {
    return (
        <Container {...{ show }}>
            <ContentWrapper>{children}</ContentWrapper>
        </Container>
    );
};

export default SlidableModal;
