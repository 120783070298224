import React, { useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
    width: 100%;
`;

const HiddenFileInput = styled.input.attrs({
    type: 'file',
})`
    display: none;
`;

const FileUploader = props => {
    const hiddenFileInput = React.useRef(null);

    const [buttonText, setButtonText] = useState('Wybierz plik');

    const handleClick = event => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setButtonText(fileUploaded.name);
        props.handleFileChanged(fileUploaded);
    };

    return (
        <>
            <Button className='btn btn-primary' onClick={handleClick}>
                {buttonText}
            </Button>
            <HiddenFileInput ref={hiddenFileInput} onChange={handleChange} />
        </>
    );
};

export default FileUploader;
