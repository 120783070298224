import React from 'react';
import { Icon } from '../utils';
import { ReactComponent as ArrowUpIcon } from './arrow-up-solid.svg';

const ArrowDown = () => (
    <Icon>
        <ArrowUpIcon />
    </Icon>
);

export default ArrowDown;
