import { trucksConstants } from '../../Constants';

const initialState = {
    trucks: [],
    selectedId: -1,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case trucksConstants.GET:
            return {
                ...state,
                trucks: action.payload,
            };
        case trucksConstants.ADD:
            return {
                ...state,
                trucks: [...state.trucks, action.payload],
            };
        case trucksConstants.SELECT:
            return {
                ...state,
                selectedId: action.payload,
            };
        default:
            return state;
    }
}
