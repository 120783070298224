import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';

import DeletionModal from '../Components/DeletionModal';
import { ColumnsConfig } from '../Utils/ColumnsConfig';
import ProductsPanel from '../Components/ControlPanels/ProductsPanel';
import { getProducts, addProduct, deleteProduct, editProduct } from '../Redux/Actions/product';
import { filterCaseInsensitive } from '../Utils/commons';

const ProductsView = () => {
    const [id, setId] = useState(-1);
    const [isRowClicked, setIsRowClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showArchive, setShowArchive] = useState(false);

    const [name, setName] = useState('');
    const [category, setCategory] = useState(-1);
    const [subCategory, setSubCategory] = useState(-1);
    const [assignedProviders, setAssignedProviders] = useState([]);
    const [schema, setSchema] = useState(null);

    const dispatch = useDispatch();

    const providers = useSelector(store => store.provider.providers);
    const categories = useSelector(store => store.product.categories);
    const mainCategories = useSelector(store => store.product.mainCategories);
    const subCategories = useSelector(store => store.product.subCategories);

    const { products, archiveProducts } = useSelector(store => store.product);

    const clearFields = () => {
        setName('');
        setCategory(-1);
        setSubCategory(-1);
        setAssignedProviders([]);
        setSchema(null);
    };

    const refresh = () => {
        dispatch(getProducts());
    };

    const refreshAfterArchiving = () => {
        dispatch(getProducts());
        dispatch(getProducts(true));
    };

    const handleSubmit = fields => {
        dispatch(addProduct(preprocessFields(fields), refresh, schema));
        clearFields();
    };

    const preprocessFields = fields => {
        return {
            ...fields,
            providers: fields.assignedProviders.map(provider => {
                return {
                    id: provider.value,
                };
            }),
            category: { id: fields.subCategory === -1 ? fields.category.value : fields.subCategory.value },
        };
    };

    const handleOnRowClick = rowInfo => {
        if (typeof rowInfo === 'undefined') {
            return;
        }

        let category;

        if (mainCategories.some(mainCategory => mainCategory.id === rowInfo.original.category.id)) {
            category = {
                value: rowInfo.original.category.id,
                label: rowInfo.original.category.label,
            };
            setSubCategory({
                value: rowInfo.original.category.id,
                label: rowInfo.original.category.label,
            });
        } else {
            setSubCategory({
                value: rowInfo.original.category.id,
                label: rowInfo.original.category.label,
            });
            const found = categories.find(c => c.id === rowInfo.original.category.id);
            category = {
                value: found.overcategory.id,
                label: found.overcategory.label,
            };
        }

        setId(rowInfo.original.id);
        setName(rowInfo.original.name);
        setCategory(category);
        setAssignedProviders(
            rowInfo.original.providers.map(provider => {
                return {
                    value: provider.id,
                    label: provider.name,
                };
            })
        );
        setIsRowClicked(true);
    };

    const handleCancelClick = () => {
        setIsRowClicked(false);
        clearFields();
    };

    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleRemoveClick = () => {
        setIsRowClicked(false);
        setShowModal(false);
        clearFields();
        dispatch(deleteProduct({ id }, refreshAfterArchiving, showArchive ? 'przywrócony' : 'zarchiwizowany'));
    };

    const handleEditClick = fields => {
        setIsRowClicked(false);
        clearFields();
        dispatch(editProduct({ id, ...preprocessFields(fields), category: null }, refresh, schema));
    };

    const handleFileChanged = file => {
        setSchema(file);
    };

    const handleArchiveClick = () => {
        clearFields();
        setIsRowClicked(false);
        setShowArchive(current => !current);
    };

    const productTable = (
        <div className='mediumTable'>
            <div className='table-div'>
                <ReactTable
                    data={showArchive ? archiveProducts : products}
                    columns={ColumnsConfig.ProductsColumns}
                    noDataText='Brak wpisów w tabeli'
                    className='-striped -highlight'
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    pageSizeOptions={[15, 30, 50, 100]}
                    pageText='Strona'
                    ofText='z'
                    rowsText='na stronie'
                    previousText='Poprzednia'
                    nextText='Następna'
                    defaultPageSize={15}
                    style={{ border: 'none' }}
                    sortable={false}
                    resizable={false}
                    getTdProps={(state, rowInfo) => {
                        return {
                            onClick: e => {
                                handleOnRowClick(rowInfo);
                            },
                        };
                    }}
                />
            </div>
        </div>
    );

    return (
        <div style={{ padding: 10 }}>
            <DeletionModal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                {...{ handleRemoveClick }}
                text={showArchive ? 'Czy na pewno chcesz przywrócić ten produkt?' : 'Czy na pewno chcesz zarchiwizować ten produkt?'}
                deletionText={showArchive ? 'Przywróć' : 'Zarchiwizuj'}
                variant={showArchive ? 'success' : 'danger'}
            />
            {productTable}
            <ProductsPanel
                {...{ providers }}
                {...{ mainCategories }}
                {...{ subCategories }}
                {...{ handleSubmit }}
                {...{ isRowClicked }}
                {...{ handleCancelClick }}
                {...{ handleDeleteClick }}
                {...{ handleEditClick }}
                {...{ showArchive }}
                {...{ handleArchiveClick }}
                {...{ handleFileChanged }}
                {...{ id }}
                {...{ name }}
                {...{ category }}
                {...{ subCategory }}
                {...{ assignedProviders }}
                {...{ schema }}
            />
        </div>
    );
};

export default ProductsView;
