import { authConstants } from '../../Constants';

export const availableViews = {
    MANAGEMENT: 'Zarządzanie',
    PRODUCTION: 'Produkcja',
};

const initialState = {
    user: undefined,
    didTryAutoLogin: false,
    view: undefined,
    initLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case authConstants.AUTO_LOGIN_SUCCESS:
            return {
                ...state,
                didTryAutoLogin: true,
                user: action.payload,
                initLoading: true,
            };
        case authConstants.AUTO_LOGIN_FAIL:
            return {
                ...state,
                didTryAutoLogin: true,
            };
        case authConstants.LOGIN:
            return {
                ...state,
                user: action.payload,
                initLoading: true,
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                user: undefined,
                view: undefined,
                didTryAutoLogin: true,
            };
        case authConstants.CHANGE_VIEW:
            return {
                ...state,
                view: action.payload,
                initLoading: false,
            };
        default:
            return state;
    }
}
