import styled from 'styled-components';
import { toRem } from '../../../Styles/theme';

export const Container = styled.div`
    width: 70%;
    padding: ${toRem(10)};
    border-radius: ${toRem(15)} 0 0 ${toRem(15)};
    border: ${toRem(3)} solid #a8a8a88c;
    border-right-width: 0;

    background-color: #fff;
    box-shadow: ${toRem(12)} ${toRem(12)} ${toRem(12)} 0 rgba(0, 0, 0, 0.575);
    text-align: center;

    & > .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-th.-cursor-pointer {
        box-shadow: none;
    }
`;
