import React, { useState } from 'react';
import styled from 'styled-components';
import { toRem } from '../../../../../Styles/theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${toRem(10)};
    width: 100%;
`;

const CollapseIcon = styled(ExpandMoreIcon)`
    cursor: pointer;
    transition: transform 0.2s ease-out !important;
    transform: ${props => props.rotate};
`;

export const PackageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.gap};

    width: 100%;

    border-radius: 5px;
    border: solid 1px lightgrey;
    padding: ${toRem(5)};
`;

const Collapsible = ({ renderHeader, renderContent }) => {
    const [showContent, setShowContent] = useState(false);

    const toggle = () => setShowContent(!showContent);

    return (
        <PackageWrapper gap={`gap: ${toRem(showContent ? 10 : 0)}`}>
            <HeaderWrapper>
                {renderHeader()}
                <CollapseIcon onClick={toggle} rotate={`rotate(${showContent ? '180deg' : '0'})`} />
            </HeaderWrapper>
            {showContent && renderContent()}
        </PackageWrapper>
    );
};

export default Collapsible;
