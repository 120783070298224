import React from 'react';
import * as dateFns from 'date-fns';
import CheckIcon from '@material-ui/icons/Check';

import { LoaderWrapper } from '../../../../Components/packages/packages.styles';
import Loader from '../../../../Components/UI/Loader/loader';
import { IconButton, ProductHeader } from '../../planning/planning.styles';
import { Container, Day, DayColumn, Days, ProductItem, StyledWeekPicker, WeekContainer } from './week-view.styles';
import WeekPicker from '../../../../Components/week-picker/week-picker';
import { getClientFromOrder } from '../../../../Utils/commons';

const WeekView = ({ startDate, setStartDate, loadingPackages, products, disableProcessPackage, highlightedProductId, finishedClick }) => {
    const onNextClick = () => {
        setStartDate(dateFns.addDays(startDate, 7));
    };

    const onBackClick = () => {
        setStartDate(dateFns.subDays(startDate, 7));
    };

    const renderDays = () => {
        const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

        return (
            <Days>
                {days.map(day => (
                    <Day>{day}</Day>
                ))}
            </Days>
        );
    };

    const renderColumns = () => {
        let day = startDate;
        let days = [];

        for (let i = 0; i < 6; i++) {
            days.push(day);
            day = dateFns.addDays(day, 1);
        }

        return days.map(day => {
            const todayProducts = products.filter(product => dateFns.isSameDay(new Date(product.productionTerm), day));

            return (
                <DayColumn>
                    {todayProducts.length > 0 ? (
                        todayProducts.map((product, index) => {
                            const disabled = disableProcessPackage(product.id);
                            return (
                                <ProductItem
                                    key={index}
                                    highlighted={product.id === highlightedProductId}
                                    id={`product-item-${product.id}`}
                                >
                                    <ProductHeader>
                                        <div style={{ fontWeight: 600 }}>
                                            {`${product.product.productSchema.name}  ${
                                                product.product.order ? `- ${getClientFromOrder(product.product.order)}` : ''
                                            }`}
                                        </div>
                                        <IconButton variant='success' onClick={() => finishedClick(product.id)} disabled={disabled}>
                                            <CheckIcon />
                                        </IconButton>
                                    </ProductHeader>
                                    {product.product.description ? `${product.product.description}` : ''}
                                </ProductItem>
                            );
                        })
                    ) : (
                        <LoaderWrapper>Brak basenów do wyprodukowania</LoaderWrapper>
                    )}
                </DayColumn>
            );
        });
    };

    return (
        <Container>
            <StyledWeekPicker>
                <WeekPicker {...{ startDate }} {...{ onBackClick }} {...{ onNextClick }} />
            </StyledWeekPicker>
            {renderDays()}
            <WeekContainer>
                {loadingPackages ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : (
                    renderColumns()
                )}
            </WeekContainer>
        </Container>
    );
};

export default WeekView;
