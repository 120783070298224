import React from 'react';
import ArrowUp from '../../../../icons/arrow-up/arrow-up';
import { Container } from './sortable-header.styles';

const SortableHeader = ({ id, name, sortId, desc }) => {
    const active = id === sortId;

    return (
        <Container active={active} desc={desc}>
            <ArrowUp />
            {name}
            <ArrowUp />
        </Container>
    );
};

export default SortableHeader;
