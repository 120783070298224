import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { trucksConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getTrucks = () => async dispatch => {
    try {
        const res = await axios.get(API.trucks);
        return dispatch({
            type: trucksConstants.GET,
            payload: res.data,
        });
    } catch (err) {
        console.log(err.response);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const addTruck = (truck, refresh) => dispatch => {
    axios
        .post(API.trucks, truck)
        .then(res => {
            refresh();
            NotificationManager.success('Pojazd został dodany');
        })
        .catch(err => {
            console.log(err.response);

            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteTruck = (truck, refresh) => dispatch => {
    axios
        .delete(API.trucks, { data: truck })
        .then(res => {
            refresh();
            NotificationManager.success('Pojazd został usunięty');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editTruck = (truck, refresh) => dispatch => {
    axios
        .patch(API.trucks, truck)
        .then(res => {
            refresh();
            NotificationManager.success('Pojazd został zapisany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const selectTruck = id => dispatch => {
    dispatch({ type: trucksConstants.SELECT, payload: id });
};
