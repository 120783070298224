import styled from 'styled-components';
import { toRem } from '../../../../Styles/theme';
import { productItemDisabledStyles, productItemHighlightedStyles } from '../production.styles';
import { Row } from '../../planning/components/calendar/calendar.styles';
import { Wrapper } from '../../../../Components/week-picker/week-picker.styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    width: 99vw;
    height: 92vh;

    border-radius: 15px;
    border: 3px solid #a8a8a88c;
    padding-top: ${toRem(5)};

    background-color: white;
    box-shadow: 12px 12px 12px 0 rgba(0, 0, 0, 0.575);
`;

export const ProductItem = styled.div`
    width: 100%;

    padding: ${toRem(10)};
    border-radius: 5px;
    border: solid 1px ${props => props.borderColor || 'lightgrey'};

    cursor: pointer;

    ${props => props.picked && 'background: whitesmoke'};

    &:hover {
        background: whitesmoke;
    }

    ${props => props.disabled && productItemDisabledStyles};

    ${props => props.highlighted && productItemHighlightedStyles};
`;

export const WeekContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${toRem(5)};

    height: 100%;

    padding-right: ${toRem(5)};
    padding-bottom: ${toRem(5)};

    overflow: auto;
`;

export const DayColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: ${toRem(10)};

    height: 100%;

    border-left: 1px solid #f1e8e8;
    border-right: 1px solid #f1e8e8;

    overflow-y: auto;
`;

export const Days = styled(Row)`
    padding: 0.75em 0;
    border-bottom: 1px solid #f1e8e8;
    border-top: 1px solid #f1e8e8;

    text-transform: uppercase;
    font-weight: 400;
    font-size: 80%;
`;

export const Day = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const StyledWeekPicker = styled.div`
    ${Wrapper} {
        gap: ${toRem(30)};
    }
`;
