import { FAILED_RECEIVED_REPORT, REQUEST_REPORT, RESET_REPORT, SUCCESSFUL_RECEIVED_REPORT } from '../report.constants';

const initialState = {
    reportData: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_REPORT:
            return {
                ...state,
                loading: true,
            };
        case SUCCESSFUL_RECEIVED_REPORT:
            return {
                ...state,
                reportData: action.payload.reportData,
                loading: false,
            };
        case FAILED_RECEIVED_REPORT:
            return {
                ...state,
                loading: false,
            };
        case RESET_REPORT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
