import { availableViews } from '../../Redux/Reducers/AuthReducer';

export const getViewFromPath = from => {
    switch (from) {
        case '/tasks':
        case '/orders':
        case '/workers':
        case '/trucks':
        case '/trailers':
        case '/users':
        case '/providers':
        case '/clients':
        case '/products':
        case '/changePassword':
            return availableViews.MANAGEMENT;
        case '/planning':
        case '/production':
        case '/tooling':
        case '/tech':
        case '/packing':
            return availableViews.PRODUCTION;
        default:
            return availableViews.MANAGEMENT;
    }
};
