import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeletionModal from '../Components/DeletionModal';
import { ColumnsConfig } from '../Utils/ColumnsConfig';
import CustomTable from '../Components/CustomTable';
import ClientsPanel from '../Components/ControlPanels/ClientsPanel';
import { getClients, addClient, deleteClient, editClient } from '../Redux/Actions/client';

const ClientsPage = () => {
    const [id, setId] = useState(-1);
    const [isRowClicked, setIsRowClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showArchive, setShowArchive] = useState(false);

    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mail, setMail] = useState('');

    const dispatch = useDispatch();

    const { clients, archiveClients } = useSelector(store => store.client);

    const clearFields = () => {
        setName('');
        setShortName('');
        setAddress('');
        setPhoneNumber('');
        setMail('');
    };

    const refresh = () => {
        dispatch(getClients());
    };

    const refreshAfterArchiving = () => {
        dispatch(getClients());
        dispatch(getClients(true));
    };

    const handleSubmit = fields => {
        dispatch(addClient(fields, refresh));
        clearFields();
    };

    const handleOnRowClick = rowInfo => {
        if (typeof rowInfo === 'undefined') {
            return;
        }
        setId(rowInfo.original.id);
        setName(rowInfo.original.name);
        setShortName(rowInfo.original.shortName);
        setAddress(rowInfo.original.address);
        setPhoneNumber(rowInfo.original.phoneNumber);
        setMail(rowInfo.original.mail);
        setIsRowClicked(true);
    };

    const handleCancelClick = () => {
        setIsRowClicked(false);
        clearFields();
    };

    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleRemoveClick = () => {
        setIsRowClicked(false);
        setShowModal(false);
        clearFields();
        dispatch(deleteClient({ id }, refreshAfterArchiving, showArchive ? "przywrócony" : "zarchiwizowany"));
    };

    const handleEditClick = fields => {
        setIsRowClicked(false);
        clearFields();
        dispatch(editClient({ id, ...fields }, refresh));
    };

    const handleArchiveClick = () => {
        clearFields();
        setIsRowClicked(false);
        setShowArchive(current => !current);
    };

    return (
        <div style={{ padding: 10 }}>
            <DeletionModal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                {...{ handleRemoveClick }}
                text={showArchive ? 'Czy na pewno chcesz przywrócić tego odbiorcę?' : 'Czy na pewno chcesz zarchiwizować tego odbiorcę?'}
                deletionText={showArchive ? 'Przywróć' : 'Zarchiwizuj'}
                variant={showArchive ? "success" : "danger"}
            />
            <CustomTable content={showArchive ? archiveClients : clients} columns={ColumnsConfig.ClientsColumns} className='reservationTable' {...{ handleOnRowClick }} />
            <ClientsPanel
                {...{ handleSubmit }}
                {...{ isRowClicked }}
                {...{ handleCancelClick }}
                {...{ handleDeleteClick }}
                {...{ handleEditClick }}
                {...{ showArchive }}
                {...{ handleArchiveClick }}
                {...{ name }}
                {...{ shortName }}
                {...{ address }}
                {...{ phoneNumber }}
                {...{ mail }}
            />
        </div>
    );
};

export default ClientsPage;
