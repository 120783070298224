import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import RequiredFieldIndicator from '../RequiredFieldIndicator';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class TrailersPanel extends Component {
    render() {
        return (
            <div className='panel-div'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        registrationNumber: this.props.registrationNumber,
                        description: this.props.description,
                    }}
                    validationSchema={Yup.object().shape({
                        registrationNumber: Yup.string().required('To pole jest wymagane'),
                        description: Yup.string(),
                    })}
                    onSubmit={(fields, { resetForm }) => {
                        if (this.props.isRowClicked) {
                            this.props.handleEditClick(fields);
                        } else {
                            this.props.handleSubmit(fields);
                        }
                        resetForm();
                    }}
                    render={({ errors, status, touched, fields }) => (
                        <Form>
                            <div className='form-group'>
                                <label htmlFor='registrationNumber'>Rejestracja</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='registrationNumber'
                                    placeholder='Wprowadź rejestrację'
                                    type='text'
                                    className={
                                        'form-control' + (errors.registrationNumber && touched.registrationNumber ? ' is-invalid' : '')
                                    }
                                />
                                <ErrorMessage name='registrationNumber' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='description'>Opis</label>
                                <Field
                                    name='description'
                                    placeholder='Wprowadź opis'
                                    component='textarea'
                                    rows='3'
                                    className='form-control'
                                    style={{ resize: 'none' }}
                                />
                            </div>

                            {!this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                        Dodaj
                                    </button>
                                    <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                        Wyczyść
                                    </button>
                                </>
                            )}
                            {this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-primary' style={{ marginRight: '5%', width: '30%' }}>
                                        Zapisz
                                    </button>
                                    <Button
                                        onClick={this.props.handleCancelClick}
                                        variant='secondary'
                                        style={{ marginRight: '5%', width: '30%' }}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        onClick={this.props.handleDeleteClick}
                                        variant='danger'
                                        style={{ width: '30%' }}
                                        disabled={!this.props.isRowClicked}
                                    >
                                        Usuń
                                    </Button>
                                </>
                            )}
                        </Form>
                    )}
                />
            </div>
        );
    }
}

export default TrailersPanel;
