import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-day-picker/lib/style.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import 'react-table/react-table.css';
import * as serviceWorker from './serviceWorker';
import 'react-notifications/lib/notifications.css';
import { store } from './Redux/ConfigureStore';
import { Provider as ReduxProvider } from 'react-redux';

document.body.classList.add('body');

ReactDOM.render(
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
