import axios from 'axios';
import { authConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const tryAutoLogin = redirect => async dispatch => {
    try {
        const res = await axios.get(API.autoLogin);
        if (res.data.roles) {
            dispatch({ type: authConstants.AUTO_LOGIN_SUCCESS, payload: res.data });
            redirect(res.data.roles);
        } else {
            dispatch({ type: authConstants.AUTO_LOGIN_FAIL });
        }
    } catch (e) {
        dispatch({ type: authConstants.AUTO_LOGIN_FAIL });
    }
};

export const totalFuckedUpFunction = (username, password, redirect, turnOffSpinner) => dispatch => {
    axios
        .get(API.csrf)
        .then(res => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const requestOptions = {
                method: 'post',
                url: API.login,
                data: formData,
                headers: { 'X-CSRF-TOKEN': res.data.token },
            };

            axios(requestOptions)
                .then(res => {
                    axios
                        .get(API.csrf)
                        .then(res => {
                            axios.interceptors.request.use(function (config) {
                                config.headers[res.data.headerName] = res.data.token;
                                return config;
                            });
                        })
                        .catch(err => {
                            if (err.response) checkStatus(err.response.status, dispatch);
                        });

                    dispatch({ type: authConstants.LOGIN, payload: res.data });
                    turnOffSpinner();
                    redirect(res.data.roles);
                })
                .catch(err => {
                    turnOffSpinner();
                    if (err.response) checkStatus(err.response.status, dispatch);
                });
        })
        .catch(err => {
            turnOffSpinner();
            if (err.response) checkStatus(err.response.status, dispatch);
        });
};

// export const addUser = (
//   username,
//   password,
//   redirect,
//   tokenName,
//   token
// ) => dispatch => {
//   console.log(authConstants.LOGIN);
//   const formData = new FormData();
//   formData.append("username", username);
//   formData.append("password", password);

//   const requestOptions = {
//     method: "post",
//     url: API.login,
//     data: formData,
//     config: {
//       headers: { "Content-Type": "multipart/form-data" }
//     }
//   };

//   axios(requestOptions)
//     .then(res => {
//       dispatch({ type: authConstants.LOGIN, payload: res.data });
//       redirect(res.data.roles);
//     })
//     .catch(err => {
//       if (err.response) checkStatus(err.response.status, dispatch);
//     });
// };

export const deleteUser = () => async dispatch => {
    try {
        await axios.post(API.logout);
        return dispatch({ type: authConstants.LOGOUT });
    } catch (err) {
        if (err.response) checkStatus(err.response.status, dispatch);
    }
};

export const changePassword = (user, redirect) => (dispatch, getState) => {
    axios
        .patch(API.changePassword, user)
        .then(res => {
            const formData = new FormData();
            formData.append('username', getState().auth.user.login);
            formData.append('password', user.password);

            const requestOptions = {
                method: 'post',
                url: API.login,
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
            };

            axios(requestOptions)
                .then(res => {
                    dispatch({ type: authConstants.LOGIN, payload: res.data });
                    redirect(res.data.roles);
                })
                .catch(err => {
                    if (err.response) checkStatus(err.response.status, dispatch);
                });
        })
        .catch(err => {
            if (err.response) checkStatus(err.response.status, dispatch);
        });
};

export const changeView = newView => (dispatch, getState) => {
    if (newView !== getState().auth.view) {
        dispatch({ type: authConstants.CHANGE_VIEW, payload: newView });
    }
};
