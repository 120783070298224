import { REPORT_TYPES } from '../report.constants';
import { getProductColumns } from './report-columns/product.columns';

export const getColumns = (reportType, sort) => {
    switch (reportType) {
        case REPORT_TYPES.PRODUCTS:
            return getProductColumns(sort);
        default:
            break;
    }
};
