import { getClientFromOrder } from './commons';

export const preprocessProductResponse = response => {
    if (typeof response === 'undefined') {
        return;
    }
    return response.map(row => {
        return {
            ...row,
            categoryText: row.category.overcategory ? row.category.overcategory.label : row.category.label,
            providersText: row.providers.map(provider => provider.name).join(', '),
        };
    });
};

export const preprocessOrderResponse = response => {
    if (typeof response === 'undefined') {
        return;
    }
    response.forEach(row => {
        row.createdById = row.createdBy.id;
        row.createdBy = row.createdBy.name[0] + '. ' + row.createdBy.surname;
        row.planned = row.hidden ? 'tak' : 'nie';
    });

    return response;
};

const PreprocessReservationResponse = response => {
    if (typeof response === 'undefined') {
        return;
    }
    response.forEach(row => {
        if (row.assignedTruck !== null) {
            row.assignedTruckId = {
                value: row.assignedTruck.id,
                label: `${row.assignedTruck.registrationNumber} - ${row.assignedTruck.model}`,
            };
            row.assignedTruck = row.assignedTruck.registrationNumber;
        }
        if (row.assignedTrailer !== null) {
            row.assignedTrailerId = {
                value: row.assignedTrailer.id,
                label: `${row.assignedTrailer.registrationNumber} - ${row.assignedTrailer.description}`,
            };
            row.assignedTrailer = row.assignedTrailer.registrationNumber;
        }
        const toDateSplit = row.toDate.split('-');
        const fromDateSplit = row.fromDate.split('-');
        row.toDate = new Date(toDateSplit[2], toDateSplit[1] - 1, toDateSplit[0]).toLocaleDateString('en-GB');
        row.fromDate = new Date(fromDateSplit[2], fromDateSplit[1] - 1, fromDateSplit[0]).toLocaleDateString('en-GB');

        row.assignedWorkerIds = row.assignedWorkers.map(worker => ({
            value: worker.id,
            label: worker.name.concat(' ', worker.surname),
        }));

        row.assignedWorkersObj = row.assignedWorkers;
        row.assignedWorkers = row.assignedWorkers
            .map(worker => {
                return worker.name[0] + '. ' + worker.surname;
            })
            .join(', ');

        row.createdById = row.createdBy.id;
        row.createdBy = row.createdBy.name[0] + '. ' + row.createdBy.surname;

        row.orderIds = row.orders.map(order => {
            const client = getClientFromOrder(order);
            return {
                value: order.id,
                label: `${client} - ${order.description}`,
            };
        });
        var i = 1;
        row.orders = row.orders
            .map(order => {
                const client = getClientFromOrder(order);
                return i++ + '. ' + client + '-' + order.description + '\n';
            })
            .join('');

        row.packageCount = row.packageCount.toString();
    });
    return response;
};

export const PreprocessUtils = {
    PreprocessReservationResponse,
};
