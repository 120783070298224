export const packageConstants = {
    REQUEST_PACKAGES: 'REQUEST_PACKAGES',
    SUCCESS_RECEIVE_PACKAGES: 'SUCCESS_RECEIVE_PACKAGES',
    FAIL_RECEIVE_PACKAGES: 'FAIL_RECEIVE_PACKAGES',
    PICK_PACKAGE: 'PICK_PACKAGE',
    PICK_PRODUCT: 'PICK_PRODUCT',
    HIGHLIGHT_PRODUCT: 'HIGHLIGHT_PRODUCT',
    UNHIGHLIGHT_PRODUCT: 'UNHIGHLIGHT_PRODUCT',
    UPDATE_PACKAGES: 'UPDATE_PACKAGES',
    UPDATE_PRODUCT_AMOUNT: 'UPDATE_PRODUCT_AMOUNT',
    REMOVE_PRODUCT_AMOUNT: 'REMOVE_PRODUCT_AMOUNT',
    RECEIVE_PACKAGE_INFO: 'RECEIVE_PACKAGE_INFO',
    RESET: 'RESET_PACKAGE_REDUCER',
    SHOW_CALENDAR_MODAL: 'SHOW_CALENDAR_MODAL',
    HIDE_CALENDAR_MODAL: 'HIDE_CALENDAR_MODAL',
    TOGGLE_PRINTED: 'TOGGLE_PRINTED',
};
