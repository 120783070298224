import React, { useEffect, useRef, useState } from 'react';
import Canvas from '../OrderConfigurator/PoolBuilder/Canvas';
import {
    CanvasImage,
    LegendDetails,
    LegendHeader,
    LegendItem,
    LegendName,
    OtherCanvasImage,
} from '../../pages/production/tooling/tooling.styles';
import { categorySymbols } from '../OrderConfigurator/PoolBuilder/utlis';
import { Columns, Legend, MetadataRow, NoTech, PoolMetadata, PrintWrapper, TitleWrapper } from './printable-pool-schema.styles';

const PrintablePoolSchema = ({ pool }) => {
    const [initFinished, setInitFinished] = useState(false);
    const printableRef = useRef(null);

    useEffect(() => {
        if (pool && printableRef.current) {
            setInitFinished(true);
        }
    }, [pool, printableRef.current]);

    const getCanvasProps = () => {
        return pool
            ? {
                  readOnly: true,
                  productId: pool.id,
                  addons: pool.poolEquipment,
                  jsonData: pool.poolStructure,
                  scale: 1.4,
                  printMode: true,
              }
            : null;
    };

    const renderLegendImage = (addon, index, jsonData) => {
        return addon.category === 'POOL_OTHER' ? (
            <OtherCanvasImage size={40}>{jsonData[index].counter}</OtherCanvasImage>
        ) : (
            <CanvasImage size={40} src={categorySymbols[addon.category]} />
        );
    };

    const renderCanvasImages = (addons, jsonData) =>
        addons.map((addon, index) => (
            <LegendItem key={index}>
                <LegendHeader>
                    {renderLegendImage(addon, index, jsonData)}
                    <LegendDetails>
                        <LegendName>{addon.name}</LegendName> ({addon.provider}) (szt. {addon.amount})
                    </LegendDetails>
                </LegendHeader>
                {addon.description && `${addon.description}`}
            </LegendItem>
        ));

    const renderLegend = () => {
        const otherJsonData = pool.poolStructure.filter(record => record.category === 'POOL_OTHER');

        const addons = pool.poolEquipment.filter(addon => addon.category !== 'POOL_OTHER');

        const otherAddons = pool.poolEquipment.filter(addon => addon.category === 'POOL_OTHER');

        const noAddons = addons.length === 0 && otherAddons.length === 0;

        return noAddons ? (
            <NoTech>Brak techniki basenowej</NoTech>
        ) : (
            <Legend>
                {renderCanvasImages(addons)}
                {renderCanvasImages(otherAddons, otherJsonData)}
            </Legend>
        );
    };

    return pool ? (
        <PrintWrapper ref={printableRef}>
            <TitleWrapper>Schemat basenu</TitleWrapper>
            <PoolMetadata>
                <MetadataRow>
                    <b>Nazwa:</b> {pool.name}
                </MetadataRow>
                <MetadataRow>
                    <b>Klient:</b> {pool.client}
                </MetadataRow>
                <MetadataRow>
                    <b>Termin:</b> {pool.deadline}
                </MetadataRow>
                <MetadataRow>
                    <b>Opis:</b> {pool.description}
                </MetadataRow>
            </PoolMetadata>
            <Columns>
                <Canvas {...getCanvasProps()} />
                {renderLegend()}
            </Columns>
        </PrintWrapper>
    ) : (
        <></>
    );
};

export default PrintablePoolSchema;
