import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { toRem } from '../../Styles/theme';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${toRem(8)};
`;

export const DateWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: ${toRem(150)};
    text-align: center;

    @media (min-width: 1000px) {
        width: ${toRem(250)};
    }
`;

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${toRem(30)};
    height: ${toRem(30)};
`;
