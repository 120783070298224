import { workersConstants } from '../../Constants';

const initialState = {
    workers: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case workersConstants.GET:
            return {
                ...state,
                workers: action.payload,
            };
        default:
            return state;
    }
}
