import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { workersConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';

export const getWorkers = () => async dispatch => {
    try {
        const res = await axios.get(API.workers);
        return dispatch({
            type: workersConstants.GET,
            payload: res.data.sort((a, b) =>
                a.surname.localeCompare(b.surname) === 0 ? a.name.localeCompare(b.name) : a.surname.localeCompare(b.surname)
            ),
        });
    } catch (err) {
        console.log(err.response);
        if (err.response) checkStatus(err.response, dispatch);
    }
};

export const addWorker = (worker, refresh) => dispatch => {
    axios
        .post(API.workers, worker)
        .then(res => {
            refresh();
            NotificationManager.success('Pracownik został dodany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteWorker = (worker, refresh) => dispatch => {
    axios
        .delete(API.workers, { data: worker })
        .then(res => {
            refresh();
            NotificationManager.success('Pracownik został usunięty');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editWorker = (worker, refresh) => dispatch => {
    axios
        .patch(API.workers, worker)
        .then(res => {
            refresh();
            NotificationManager.success('Pracownik został zapisany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
