import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColumnsConfig } from '../Utils/ColumnsConfig';
import CustomTable from '../Components/CustomTable';
import DeletionModal from '../Components/DeletionModal';
import ProvidersPanel from '../Components/ControlPanels/ProvidersPanel';
import { getProviders, addProvider, deleteProvider, editProvider } from '../Redux/Actions/provider';

const ProvidersPage = () => {
    const [id, setId] = useState(-1);
    const [isRowClicked, setIsRowClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showArchive, setShowArchive] = useState(false);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');

    const dispatch = useDispatch();

    const { providers, archiveProviders } = useSelector(store => store.provider);

    const clearFields = () => {
        setName('');
        setAddress('');
    };

    const refresh = () => {
        dispatch(getProviders());
    };

    const refreshAfterArchiving = () => {
        dispatch(getProviders());
        dispatch(getProviders(true));
    };

    const handleSubmit = fields => {
        dispatch(addProvider(fields, refresh));
        clearFields();
    };

    const handleOnRowClick = rowInfo => {
        if (typeof rowInfo === 'undefined') {
            return;
        }
        setId(rowInfo.original.id);
        setName(rowInfo.original.name);
        setAddress(rowInfo.original.address);
        setIsRowClicked(true);
    };

    const handleCancelClick = () => {
        setIsRowClicked(false);
        clearFields();
    };

    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleRemoveClick = () => {
        setIsRowClicked(false);
        setShowModal(false);
        clearFields();
        dispatch(deleteProvider({ id }, refreshAfterArchiving, showArchive ? "przywrócony" : "zarchiwizowany"));
    };

    const handleEditClick = fields => {
        setIsRowClicked(false);
        clearFields();
        dispatch(editProvider({ id, ...fields }, refresh));
    };

    const handleArchiveClick = () => {
        clearFields();
        setIsRowClicked(false);
        setShowArchive(current => !current)
    };

    return (
        <div style={{ padding: 10 }}>
            <DeletionModal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                {...{ handleRemoveClick }}
                text={showArchive ? 'Czy na pewno chcesz przywrócić tego dostawcę?' : 'Czy na pewno chcesz zarchiwizować tego dostawcę?'}
                deletionText={showArchive ? 'Przywróć' : 'Zarchiwizuj'}
                variant={showArchive ? "success" : "danger"}
            />
            <CustomTable content={showArchive ? archiveProviders : providers} columns={ColumnsConfig.ProvidersColumns} className='mediumTable' {...{ handleOnRowClick }} />
            <ProvidersPanel
                {...{ handleSubmit }}
                {...{ isRowClicked }}
                {...{ handleCancelClick }}
                {...{ handleDeleteClick }}
                {...{ handleEditClick }}
                {...{ showArchive }}
                {...{ handleArchiveClick }}
                {...{ name }}
                {...{ address }}
            />
        </div>
    );
};

export default ProvidersPage;
