import axios from 'axios';
import { providerConstants, API } from '../../Constants';
import { checkStatus } from '../../Utils/CheckStatus';
import { NotificationManager } from 'react-notifications';

export const getProviders = archive => async dispatch => {
    try {
        const url = archive ? `${API.providers}?active=false` : API.providers;
        const { data } = await axios.get(url);
        const type = archive ? providerConstants.GET_ARCHIVE : providerConstants.GET;
        return dispatch({
            type: type,
            payload: data.sort((a, b) => a.name.localeCompare(b.name)),
        });
    } catch (e) {
        console.log(e.response);
        if (e.response) checkStatus(e.response, dispatch);
    }
};

export const addProvider = (provider, refresh) => dispatch => {
    axios
        .post(API.providers, provider)
        .then(_ => {
            refresh();
            NotificationManager.success('Dostawca został dodany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const deleteProvider = (provider, refresh, literal) => dispatch => {
    axios
        .patch(API.toggleProvider, provider)
        .then(_ => {
            refresh();
            NotificationManager.success(`Dostawca został ${literal}`);
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};

export const editProvider = (provider, refresh) => dispatch => {
    axios
        .patch(API.providers, provider)
        .then(_ => {
            refresh();
            NotificationManager.success('Dostawca został zapisany');
        })
        .catch(err => {
            console.log(err.response);
            if (err.response) checkStatus(err.response, dispatch);
        });
};
