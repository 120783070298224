import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomTable from '../Components/CustomTable';
import UsersPanel from '../Components/ControlPanels/UsersPanel';
import { ColumnsConfig } from '../Utils/ColumnsConfig';
import { getUsers, addUser, editUser } from '../Redux/Actions/UsersActions';

class UsersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            surname: '',
            login: '',
            password: '',
            repeatPassword: '',
            id: -1,
            role: -1,
            isRowClicked: false,
        };
        this.columns = ColumnsConfig.UsersColumns;
    }

    refresh = () => {
        this.props.getUsers();
    };

    handleSubmit = fields => {
        const { name, surname, login, password, repeatPassword } = fields;
        const roles = [
            {
                id: fields.role.value,
            },
        ];
        this.props.addUser({ name, surname, login, password, repeatPassword, roles }, this.refresh);
        this.clearFields();
    };

    handleOnRowClick = rowInfo => {
        if (typeof rowInfo !== 'undefined') {
            this.setState({
                name: rowInfo.original.name,
                surname: rowInfo.original.surname,
                login: rowInfo.original.login,
                id: rowInfo.original.id,
                role: {
                    value: rowInfo.original.roles[0].id,
                    label: rowInfo.original.roles[0].label,
                },
                isRowClicked: true,
            });
        }
    };

    handleCancelClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    handleDeleteClick = () => {
        this.setState({ isRowClicked: false });
        this.clearFields();
        const id = this.state.id;
        this.props.deleteUser({ id });
    };

    handleFormChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    };

    handlePickRole = e => {
        this.setState({ role: e });
    };

    clearFields = () => {
        this.setState({
            name: '',
            surname: '',
            login: '',
            password: '',
            repeatPassword: '',
            role: -1,
            validated: false,
        });
    };

    handleEditClick = fields => {
        const { id } = this.state;
        let { name, surname, login, password, repeatPassword, role } = fields;
        if (password === '') {
            password = null;
            repeatPassword = null;
        }
        const roles = [
            {
                id: role.value,
            },
        ];
        this.props.editUser({ id, name, surname, login, password, repeatPassword, roles }, this.refresh);

        this.setState({ isRowClicked: false });
        this.clearFields();
    };

    render() {
        return (
            <div style={{ padding: 10 }}>
                <CustomTable
                    content={this.props.users}
                    columns={this.columns}
                    className='otherTables'
                    handleOnRowClick={this.handleOnRowClick}
                />
                <UsersPanel
                    handleSubmit={this.handleSubmit}
                    roles={this.props.roles}
                    name={this.state.name}
                    surname={this.state.surname}
                    login={this.state.login}
                    password={this.state.password}
                    repeatPassword={this.state.repeatPassword}
                    handleFormChange={this.handleFormChange}
                    role={this.state.role}
                    handleCancelClick={this.handleCancelClick}
                    handleDeleteClick={this.handleDeleteClick}
                    isRowClicked={this.state.isRowClicked}
                    handleEditClick={this.handleEditClick}
                    handlePickRole={this.handlePickRole}
                    handleClearClick={this.clearFields}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users.users,
    roles: state.roles.roles,
});

const connected = connect(mapStateToProps, { getUsers, addUser, editUser })(UsersPage);

export default connected;
