import React from 'react';
import { LoaderWrapper, OrderTitle } from '../../../../Components/packages/packages.styles';
import { getClientFromOrder, removeDuplicates } from '../../../../Utils/commons';
import { Addons, Divider, InfoWrapper, OrderWrapper, ProductItem, StyledText } from '../tech.styles';
import { ProductHeader } from '../../planning/planning.styles';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toRem } from '../../../../Styles/theme';
import PrintIcon from '@material-ui/icons/Print';
import { DetailsWrapper, FixedPanel, PrintButton, TitleWrapper } from './package-details.styles';
import { useReactToPrint } from 'react-to-print';
import PrintableWrapper from '../../../../Components/printable-wrapper/printable-wrapper';

const PackageDetails = ({ handleOnCheckboxClick }) => {
    const pickedPackage = useSelector(store => store.packages.packages.find(pack => pack.id === store.packages.pickedPackageId));
    const { highlightedProductId, packageInfo } = useSelector(store => store.packages);

    const printableComponent = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printableComponent.current,
    });

    const preprocessWorkersNames = () =>
        packageInfo.workerNames
            .map(worker => {
                const [name, surname] = worker.split(' ');
                return `${name[0]}. ${surname}`;
            })
            .join(', ');

    const getInfoContent = () => (
        <>
            <InfoWrapper>
                <div>
                    Kierowca: <StyledText>{packageInfo && preprocessWorkersNames()}</StyledText>
                </div>
                <div>
                    Pojazd: <StyledText>{packageInfo && packageInfo.truckRegistration}</StyledText>
                </div>
                <div>
                    Laweta: <StyledText>{packageInfo && packageInfo.trailerRegistration}</StyledText>
                </div>
            </InfoWrapper>
            <Divider />
        </>
    );

    const renderDetails = () => {
        const ordersInPackage = removeDuplicates(
            pickedPackage.products.map(product => product.product.order),
            'id'
        );

        return (
            <OrderWrapper>
                {ordersInPackage.map((order, i) => (
                    <>
                        <OrderTitle>
                            Zamówienie #{i + 1}: {getClientFromOrder(order)} - {order.description}
                        </OrderTitle>

                        {pickedPackage.products
                            .filter(product => product.product.order.id === order.id)
                            .map((product, index) => {
                                return (
                                    <ProductItem
                                        key={index}
                                        highlighted={product.id === highlightedProductId}
                                        id={`product-item-${product.id}`}
                                    >
                                        <ProductHeader>
                                            {`${product.product.productSchema.name}${
                                                product.product.provider ? ` (${product.product.provider.name})` : ''
                                            } (szt. ${product.amount})`}

                                            <Form.Check
                                                inline
                                                type='checkbox'
                                                checked={product.status === 'DONE'}
                                                onChange={() => handleOnCheckboxClick(product)}
                                            />
                                        </ProductHeader>
                                        {product.product.description || ''}
                                        {product.product.filterRoomEquipment.length > 0 && (
                                            <Addons>
                                                {product.product.filterRoomEquipment.map(addon => {
                                                    return (
                                                        <>
                                                            <li>
                                                                {`${addon.productSchema.name}${
                                                                    addon.provider ? ` (${addon.provider.name})` : ''
                                                                } (szt. ${addon.amount}) ${
                                                                    addon.description ? `- ${addon.description}` : ''
                                                                }`}
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </Addons>
                                        )}
                                    </ProductItem>
                                );
                            })}
                    </>
                ))}
            </OrderWrapper>
        );
    };

    return (
        <PrintableWrapper ref={printableComponent}>
            <FixedPanel>
                <TitleWrapper>
                    <div style={{ width: toRem(40) }} />
                    {pickedPackage ? pickedPackage.name : 'Szczegóły paczki'}
                    <PrintButton disabled={!pickedPackage} onClick={handlePrint}>
                        <PrintIcon />
                    </PrintButton>
                </TitleWrapper>
                {pickedPackage && getInfoContent()}
                <DetailsWrapper>{pickedPackage ? renderDetails() : <LoaderWrapper>Wybierz paczkę</LoaderWrapper>}</DetailsWrapper>
            </FixedPanel>
        </PrintableWrapper>
    );
};

export default PackageDetails;
