import React, { Component } from 'react';
import ReactTable from 'react-table';

import '../Styles/CustomTable.css';
import { filterCaseInsensitive } from '../Utils/commons';

class CustomTable extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <div className='table-div'>
                    <ReactTable
                        data={this.props.content}
                        minRows={20}
                        columns={this.props.columns}
                        showPagination={false}
                        noDataText='Brak wpisów w tabeli'
                        className='-striped -highlight'
                        pageSize={this.props.content.length}
                        filterable={true}
                        defaultFilterMethod={filterCaseInsensitive}
                        style={{ border: 'none' }}
                        sortable={false}
                        resizable={false}
                        getTdProps={(state, rowInfo) => {
                            return {
                                onClick: e => {
                                    this.props.handleOnRowClick(rowInfo);
                                },
                            };
                        }}
                        getTrProps={this.props.getTrProps}
                    />
                </div>
            </div>
        );
    }
}

export default CustomTable;
