import { providerConstants } from '../../Constants';

const initialState = {
    providers: [],
    archiveProviders: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case providerConstants.GET:
            return {
                ...state,
                providers: action.payload,
            };
        case providerConstants.GET_ARCHIVE:
            return {
                ...state,
                archiveProviders: action.payload,
            };
        default:
            return state;
    }
}
