import React from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

import { toRem } from '../../../Styles/theme';
import { HeaderWrapper } from '../styles';
import { FormItem } from '../ProductConfigurator/styles';
import { ContentWrapper, BodyWrapper, AddonRow } from './filter-builder.styles';

const FilterBuilder = ({
    addons,
    products,
    handleAddAddonClick,
    handleAddonProductChange,
    handleAddonDescriptionChange,
    handleAddonClearClick,
    handleAddonDeleteClick,
    handleAddonProviderChange,
    disableEditing,
}) => {
    return (
        <ContentWrapper>
            <HeaderWrapper>
                <Button onClick={handleAddonClearClick} variant='secondary' style={{ width: toRem(100) }} disabled={disableEditing}>
                    Wyczyść
                </Button>
                Technika basenowa
                <Button onClick={handleAddAddonClick} style={{ width: toRem(100) }} disabled={disableEditing}>
                    Dodaj
                </Button>
            </HeaderWrapper>
            <BodyWrapper>
                {addons.map((addon, index) => {
                    return (
                        <AddonRow key={index}>
                            <FormItem>
                                <Select
                                    value={addon.product}
                                    onChange={e => handleAddonProductChange(index, e)}
                                    placeholder='Wybierz produkt'
                                    isSearchable={true}
                                    isDisabled={disableEditing}
                                    options={products.map(product => ({
                                        value: product.id,
                                        label: product.name,
                                    }))}
                                />
                            </FormItem>
                            <FormItem>
                                <Select
                                    value={addon.provider}
                                    onChange={e => handleAddonProviderChange(index, e)}
                                    placeholder='Wybierz dostawce'
                                    isSearchable={true}
                                    isDisabled={addon.providers.length < 2 || disableEditing}
                                    options={addon.providers
                                        .filter(x => x.active)
                                        .map(provider => ({
                                            value: provider.id,
                                            label: provider.name,
                                        }))}
                                />
                            </FormItem>
                            <FormItem>
                                <Form.Control
                                    placeholder='Wprowadź opis'
                                    type='text'
                                    value={addon.description}
                                    disabled={disableEditing}
                                    onChange={e => handleAddonDescriptionChange(index, e)}
                                />
                            </FormItem>
                            <Button
                                disabled={disableEditing}
                                style={{ width: toRem(100) }}
                                onClick={() => handleAddonDeleteClick(index)}
                                variant='danger'
                            >
                                Usuń
                            </Button>
                        </AddonRow>
                    );
                })}
            </BodyWrapper>
        </ContentWrapper>
    );
};

export default FilterBuilder;
