import React from 'react';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import Notification from './notification/notification';
import { NotificationsWrapper } from './notifications.styles';

const useStyles = makeStyles(() => ({
    customHoverFocus: {
        '&': { color: 'white', float: 'right' },
        '&:focus': { outline: 'none' },
    },
}));

const Notifications = ({ notifications, deactivateNotification, anchorEl, setAnchorEl }) => {
    const classes = useStyles();

    const handleOpenNotifications = event => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const openNotifications = Boolean(anchorEl);

    return (
        <>
            <IconButton className={classes.customHoverFocus} onClick={handleOpenNotifications}>
                <Badge badgeContent={notifications.length} color='secondary'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                open={openNotifications}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <NotificationsWrapper>
                    {notifications.length > 0
                        ? notifications.map((notification, index) => {
                              const { id, message } = notification;
                              return <Notification key={index} {...{ id }} {...{ message }} {...{ deactivateNotification }} />;
                          })
                        : 'Brak nowych powiadomień'}
                </NotificationsWrapper>
            </Popover>
        </>
    );
};

export default Notifications;
