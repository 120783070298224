const ReservationsColumns = [
    {
        Header: 'ID',
        accessor: 'id',
        show: false,
    },
    {
        accessor: 'assignedWorkerId',
        show: false,
    },
    {
        accessor: 'assignedTruckId',
        show: false,
    },
    {
        accessor: 'assignedTrailerId',
        show: false,
    },
    {
        accessor: 'ifNeedsVerification',
        show: false,
    },
    {
        ID: 'fromDate',
        Header: 'Od',
        accessor: 'fromDate',
        width: 100,
    },
    {
        ID: 'toDate',
        Header: 'Do',
        accessor: 'toDate',
        width: 100,
    },
    {
        ID: 'orders',
        Header: 'Zamówienia',
        accessor: 'orders',
        width: 200,
    },
    {
        ID: 'description',
        Header: 'Opis',
        accessor: 'description',
    },
    {
        ID: 'assignedWorkers',
        Header: 'Pracownicy',
        accessor: 'assignedWorkers',
        width: 200,
    },
    {
        ID: 'assignedTruck',
        Header: 'Pojazd',
        accessor: 'assignedTruck',
        width: 100,
    },
    {
        ID: 'assignedTrailer',
        Header: 'Przyczepa',
        accessor: 'assignedTrailer',
        width: 100,
    },
    {
        ID: 'createdBy',
        Header: 'Autor',
        accessor: 'createdBy',
        width: 150,
    },
    {
        ID: 'packageCount',
        Header: 'P',
        accessor: 'packageCount',
        width: 50,
    },
];

const OrdersColumns = [
    {
        accessor: 'id',
        show: false,
    },
    {
        ID: 'dueDate',
        width: 100,
        Header: 'Termin',
        accessor: 'dueDate',
    },
    {
        ID: 'client',
        Header: 'Klient',
        accessor: 'client.shortName',
        width: 150,
    },
    {
        ID: 'createdBy',
        Header: 'Autor',
        accessor: 'createdBy',
        width: 150,
    },
    {
        ID: 'description',
        Header: 'Opis',
        accessor: 'description',
    },
    {
        ID: 'planned',
        Header: 'Zaplanowane',
        accessor: 'planned',
        width: 120,
    },
];

const WorkersColumns = [
    {
        accessor: 'id',
        show: false,
    },

    {
        ID: 'surname',
        Header: 'Nazwisko',
        accessor: 'surname',
    },
    {
        ID: 'name',
        Header: 'Imię',
        accessor: 'name',
    },
    {
        ID: 'language',
        Header: 'Język',
        accessor: 'language',
    },
    {
        ID: 'licenseType',
        Header: 'Kat. prawa jazdy',
        accessor: 'license.type',
    },
];

const TrucksColumns = [
    {
        ID: 'registrationNumber',
        Header: 'Rejestracja',
        accessor: 'registrationNumber',
    },
    {
        ID: 'model',
        Header: 'Marka',
        accessor: 'model',
    },
];

const TrailerColumns = [
    {
        ID: 'registrationNumber',
        Header: 'Rejestracja',
        accessor: 'registrationNumber',
        width: 100,
    },
    {
        ID: 'description',
        Header: 'Opis',
        accessor: 'description',
    },
];

const UsersColumns = [
    {
        ID: 'surname',
        Header: 'Nazwisko',
        accessor: 'surname',
    },
    {
        ID: 'name',
        Header: 'Imię',
        accessor: 'name',
    },
    {
        ID: 'login',
        Header: 'Login',
        accessor: 'login',
    },
];

const ProvidersColumns = [
    {
        ID: 'name',
        Header: 'Nazwa',
        accessor: 'name',
        width: 300,
    },
    {
        ID: 'address',
        Header: 'Adres',
        accessor: 'address',
    },
];

const ClientsColumns = [
    {
        ID: 'name',
        Header: 'Nazwa',
        accessor: 'name',
        width: 300,
    },
    {
        ID: 'shortName',
        Header: 'Nazwa skrócona',
        accessor: 'shortName',
        width: 150,
    },
    {
        ID: 'address',
        Header: 'Adres',
        accessor: 'address',
    },
    {
        ID: 'phoneNumber',
        Header: 'Nr telefonu',
        accessor: 'phoneNumber',
        width: 150,
    },
    {
        ID: 'mail',
        Header: 'Adres e-mail',
        accessor: 'mail',
        width: 300,
    },
];

const ProductsColumns = [
    {
        ID: 'name',
        Header: 'Nazwa',
        accessor: 'name',
    },
    {
        ID: 'categoryText',
        Header: 'Kategoria',
        accessor: 'categoryText',
    },
    {
        ID: 'providersText',
        Header: 'Dostawcy',
        accessor: 'providersText',
    },
    {
        accessor: 'assignedProviders',
        show: false,
    },
    {
        show: false,
        accessor: 'category.label',
    },
];

export const ColumnsConfig = {
    ReservationsColumns,
    WorkersColumns,
    TrucksColumns,
    TrailerColumns,
    UsersColumns,
    OrdersColumns,
    ProvidersColumns,
    ClientsColumns,
    ProductsColumns,
};
