import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import PrintIcon from '@material-ui/icons/Print';
import { Button, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import { PageWrapper, Panel } from '../planning/planning.styles';
import {
    CanvasImage,
    DetailsWrapper,
    LegendDetails,
    LegendHeader,
    LegendItem,
    LegendName,
    LegendWrapper,
    OtherCanvasImage,
    PrintButton,
    PrintWrapper,
    ProductList,
    SchemaTitle,
    TitleWrapper,
} from './tooling.styles';
import { getClientFromOrder, haveRole } from '../../../Utils/commons';
import { API } from '../../../Constants';
import { LoaderWrapper } from '../../../Components/packages/packages.styles';
import Loader from '../../../Components/UI/Loader/loader';
import { toRem } from '../../../Styles/theme';
import { NotificationManager } from 'react-notifications';
import Canvas from '../../../Components/OrderConfigurator/PoolBuilder/Canvas';
import { categorySymbols } from '../../../Components/OrderConfigurator/PoolBuilder/utlis';
import { AVAILABLE_STAGES, CONTENT_REFRESH_INTERVAL, PRODUCT_HIGHLIGHT_TIMEOUT } from '../../../Constants/common';
import {
    activateLoadingPackages,
    getPackages,
    pickProduct,
    resetPackagesReducer,
    unhighlightProduct,
} from '../../../Redux/Actions/package.actions';
import Tabs from '../../../Components/UI/tabs/tabs';
import { Tab } from '../../../Components/UI/tabs/tabs.styles';
import { checkStatus } from '../../../Utils/CheckStatus';
import PrintablePoolSchema from '../../../Components/printable-pool-schema/printable-pool-schema';
import PrintableWrapper from '../../../Components/printable-wrapper/printable-wrapper';
import ToolingProduct from './tooling-product/tooling-product.component';

const Tooling = () => {
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [processLoading, setProcessLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const printableComponent = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printableComponent.current,
    });

    const dispatch = useDispatch();

    const { user } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.roles);
    const { loadingPackages, highlightedProductId } = useSelector(store => store.packages);
    const id = useSelector(store => store.packages.pickedProductId);
    const { notifications } = useSelector(store => store.notifications);

    const shownStage = activeTab === 0 ? 'TOOLING' : 'PRODUCTION';

    const products = useSelector(store =>
        store.packages.packages
            .map(pack => pack.products.map(product => ({ ...product, deadline: pack.deadline, packageId: pack.id })))
            .reduce((result, current) => [...result, ...current], [])
            .filter(product => product.processStage.stage === shownStage)
    );

    const allProducts = useSelector(store =>
        store.packages.packages
            .map(pack => pack.products.map(product => ({ ...product, deadline: pack.deadline, packageId: pack.id })))
            .reduce((result, current) => [...result, ...current], [])
    );

    useEffect(() => {
        dispatch(activateLoadingPackages());
        dispatchGetPackages();

        const interval = setInterval(() => dispatchGetPackages(), CONTENT_REFRESH_INTERVAL);

        return () => {
            dispatch(resetPackagesReducer());
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (highlightedProductId) {
            const isFromOtherTab =
                !products.find(product => product.id === highlightedProductId) &&
                allProducts.find(product => product.id === highlightedProductId);

            if (isFromOtherTab) {
                setActiveTab(prev => (prev === 1 ? 0 : 1));
            } else {
                highlightProduct();
            }
        }
    }, [highlightedProductId]);

    useEffect(() => {
        if (highlightedProductId) {
            highlightProduct();
        }
    }, [activeTab]);

    const highlightProduct = () => {
        const el = document.getElementById(`product-item-${highlightedProductId}`);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setTimeout(() => {
                dispatch(unhighlightProduct());
            }, PRODUCT_HIGHLIGHT_TIMEOUT);
        }
    };

    const dispatchGetPackages = () => {
        const role = roles.find(role => role.name === 'ROLE_TOOLING');
        const configIfAdmin = haveRole(user, 'ROLE_ADMIN') ? { params: { roleId: role.id } } : null;
        dispatch(getPackages(configIfAdmin, AVAILABLE_STAGES.TOOLING));
    };

    const dispatchPickProduct = id => dispatch(pickProduct(id));

    const closeFinishModal = () => setShowFinishModal(false);

    const finishedClick = id => {
        dispatchPickProduct(id);
        setShowFinishModal(true);
    };

    const handleChangeTab = value => {
        dispatchPickProduct(null);
        setActiveTab(value);
    };

    const processPackage = async () => {
        setProcessLoading(true);

        try {
            await axios.post(`${API.process}?productIds=${id}`);
            NotificationManager.success('Produkt został zakończony');
            dispatchPickProduct(null);
            setShowFinishModal(false);
            dispatchGetPackages();
        } catch (e) {
            NotificationManager.error('Błąd podczas zakończenia produktu');
            if (e.response) checkStatus(e.response, dispatch);
        }

        setProcessLoading(false);
    };

    const getCanvasProps = () => {
        const product = allProducts.find(product => product.id === id)?.product;

        return product
            ? {
                  readOnly: true,
                  productId: product.productSchema.id,
                  addons: product.poolEquipment,
                  jsonData: JSON.parse(product.poolStructure),
                  scale: 1.4,
              }
            : null;
    };

    const disableProcessPackage = productId => notifications.some(x => x.productAmountId === productId);

    const renderLegendImage = (addon, index, jsonData) => {
        return addon.productSchema.category.id === 'POOL_OTHER' ? (
            <OtherCanvasImage size={40}>{jsonData[index].counter}</OtherCanvasImage>
        ) : (
            <CanvasImage size={40} src={categorySymbols[addon.productSchema.category.id]} />
        );
    };

    const renderCanvasImages = (addons, jsonData) =>
        addons.map((addon, index) => (
            <LegendItem key={index}>
                <LegendHeader>
                    {renderLegendImage(addon, index, jsonData)}
                    <LegendDetails>
                        <LegendName>{addon.productSchema.name}</LegendName> ({addon.provider.name}) (szt. {addon.amount})
                    </LegendDetails>
                </LegendHeader>
                {addon.description && `${addon.description}`}
            </LegendItem>
        ));

    const renderLegend = () => {
        const { product } = allProducts.find(product => product.id === id);

        const otherJsonData = JSON.parse(product.poolStructure).filter(record => record.category === 'POOL_OTHER');

        const addons = product.poolEquipment.filter(addon => addon.productSchema.category.id !== 'POOL_OTHER');
        const otherAddons = product.poolEquipment.filter(addon => addon.productSchema.category.id === 'POOL_OTHER');

        const noAddons = addons.length === 0 && otherAddons.length === 0;

        return noAddons ? (
            <DetailsWrapper>Brak techniki basenowej</DetailsWrapper>
        ) : (
            <>
                {renderCanvasImages(addons)}
                {renderCanvasImages(otherAddons, otherJsonData)}{' '}
            </>
        );
    };

    const normalizePoolEquipment = poolEquipment =>
        poolEquipment.map(eq => ({
            category: eq.productSchema.category.id,
            name: eq.productSchema.name,
            provider: eq.provider.name,
            amount: eq.amount,
            description: eq.description,
        }));

    const normalizePool = () => {
        const product = allProducts.find(product => product.id === id);
        const poolEquipment = normalizePoolEquipment(product.product.poolEquipment);

        return {
            id: product.product.productSchema.id,
            name: product.product.productSchema.name,
            client: getClientFromOrder(product.product.order),
            deadline: product.deadline,
            description: product.product.description,
            poolStructure: JSON.parse(product.product.poolStructure),
            poolEquipment: poolEquipment,
        };
    };

    return (
        <>
            <PageWrapper>
                <Modal show={showFinishModal} onHide={closeFinishModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Potwierdź operację</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy ten basen został ukończony?</Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={closeFinishModal}>
                            Anuluj
                        </Button>
                        {processLoading ? (
                            <Loader size={toRem(40)} />
                        ) : (
                            <Button
                                variant='success'
                                onClick={() => {
                                    processPackage();
                                }}
                            >
                                Zakończ
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <Panel>
                    <Tabs activeTab={activeTab} onChange={handleChangeTab}>
                        <Tab>Wyprodukowane baseny</Tab>
                        <Tab>Produkcja</Tab>
                    </Tabs>
                    <ProductList>
                        {loadingPackages ? (
                            <LoaderWrapper>
                                <Loader />
                            </LoaderWrapper>
                        ) : (
                            <>
                                {products.length > 0 ? (
                                    products.map((productAmount, index) => {
                                        const disabled = disableProcessPackage(productAmount.id);
                                        return (
                                            <ToolingProduct
                                                key={index}
                                                {...{ productAmount }}
                                                {...{ activeTab }}
                                                {...{ disabled }}
                                                {...{ finishedClick }}
                                            />
                                        );
                                    })
                                ) : (
                                    <DetailsWrapper>Brak wyprodukowanych basenów</DetailsWrapper>
                                )}
                            </>
                        )}
                    </ProductList>
                </Panel>
                <Panel>
                    <SchemaTitle>
                        <div style={{ width: toRem(40) }} />
                        Schemat basenu
                        <PrintButton disabled={!id} onClick={handlePrint}>
                            <PrintIcon />
                        </PrintButton>
                    </SchemaTitle>
                    <DetailsWrapper>{id ? <Canvas {...getCanvasProps()} /> : 'Wybierz basen'}</DetailsWrapper>
                </Panel>
                <Panel>
                    <TitleWrapper>Technika basenowa</TitleWrapper>
                    <LegendWrapper>{id ? renderLegend() : <DetailsWrapper>Wybierz basen</DetailsWrapper>}</LegendWrapper>
                </Panel>
            </PageWrapper>
            {id && (
                <PrintWrapper>
                    <PrintableWrapper ref={printableComponent}>
                        <PrintablePoolSchema pool={normalizePool()} />
                    </PrintableWrapper>
                </PrintWrapper>
            )}
        </>
    );
};

export default Tooling;
