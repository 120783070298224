import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';

import { FieldsRow, FormItem, VerticalDivider } from '../ProductConfigurator/styles';
import Canvas from './Canvas';
import Pallet from './Pallet';
import { AddonsWrapper, ContentWrapper, OtherWrapper } from './styles';
import { getProvidersFromProduct } from './pool-builder.utils';
import { toRem } from '../../../Styles/theme';
import { PrintButton, PrintWrapper, SchemaTitle } from '../../../pages/production/tooling/tooling.styles';
import PrintableWrapper from '../../printable-wrapper/printable-wrapper';
import PrintablePoolSchema from '../../printable-pool-schema/printable-pool-schema';
import { getClientFromOrder } from '../../../Utils/commons';

const PoolBuilder = ({
    disableEditing,
    addons,
    handleChangeJsonData,
    jsonData,
    handleAddJsonData,
    handleDeleteJsonData,
    counter,
    ...props
}) => {
    const products = useSelector(store => store.product.products);
    const order = useSelector(store => store.orders.orders.find(x => x.id === props.orderId));

    const productId = props.product ? props.product.id : -1;

    const handleAdjustCounterAdd = (jsonData, newRecord) => {
        const updated = [...jsonData, newRecord];
        props.calculateCounter(updated);
    };

    const handleAdjustCounterDelete = (jsonData, index) => {
        const updated = jsonData.filter((_, i) => index !== i);
        props.calculateCounter(updated);
    };

    const printableComponent = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printableComponent.current,
    });

    const normalizeOtherAddons = poolEquipment => {
        return poolEquipment.map(eq => {
            return {
                category: eq.category,
                name: eq.product.label,
                provider: eq.provider.label,
                amount: 1,
                description: eq.description,
            };
        });
    };

    const normalizeStandardAddons = (poolEquipment, jsonData) =>
        poolEquipment.map(eq => ({
            category: eq.category,
            name: eq.product.label,
            provider: eq.provider.label,
            amount: jsonData.filter(x => x.category === eq.category).length,
            description: eq.description,
        }));

    const normalizePool = () => {
        const product = products.find(product => product.id === productId);

        const standardAddons = [
            {
                product: props.nozzle,
                provider: props.nozzleProvider,
                description: props.nozzleDescription,
                category: 'NOZZLE',
            },
            {
                product: props.lamp,
                provider: props.lampProvider,
                description: props.lampDescription,
                category: 'LAMP',
            },
            {
                product: props.outflow,
                provider: props.outflowProvider,
                description: props.outflowDescription,
                category: 'OUTFLOW',
            },
            {
                product: props.counterflow,
                provider: props.counterflowProvider,
                description: props.counterflowDescription,
                category: 'COUNTERFLOW',
            },
            {
                product: props.skimmer,
                provider: props.skimmerProvider,
                description: props.skimmerDescription,
                category: 'SKIMMER',
            },
        ].filter(addon => addon.product !== -1);

        const combinedAddons = [
            ...normalizeOtherAddons(addons.filter(x => x.product !== -1)),
            ...normalizeStandardAddons(standardAddons, jsonData),
        ];

        return {
            id: product.id,
            name: product.name,
            client: getClientFromOrder(order),
            description: props.description,
            poolStructure: jsonData,
            poolEquipment: combinedAddons,
        };
    };

    return (
        <ContentWrapper>
            <Canvas
                {...{ productId }}
                {...{ addons }}
                {...{ handleChangeJsonData }}
                {...{ jsonData }}
                {...{ handleAddJsonData }}
                {...{ handleDeleteJsonData }}
                {...{ counter }}
                {...{ handleAdjustCounterAdd }}
                {...{ handleAdjustCounterDelete }}
                {...{ disableEditing }}
            />
            <Pallet {...{ counter }} {...{ productId }} {...{ disableEditing }} />
            <AddonsWrapper>
                <SchemaTitle>
                    <PrintButton onClick={handlePrint} disabled={productId === -1}>
                        <PrintIcon />
                    </PrintButton>
                    Technika basenowa
                    <div style={{ width: toRem(40) }} />
                </SchemaTitle>
                <FieldsRow>
                    <FormItem>
                        <Select
                            value={props.nozzle}
                            onChange={props.handleNozzleChange}
                            placeholder='Wybierz dyszę'
                            isSearchable={true}
                            options={products
                                .filter(product => product.category.id === 'NOZZLE')
                                .map(product => ({
                                    value: product.id,
                                    label: product.name,
                                }))}
                            isDisabled={props.disableNozzle}
                        />
                    </FormItem>
                    <FormItem>
                        <Select
                            value={props.nozzleProvider}
                            onChange={props.handleNozzleProviderChange}
                            placeholder='Wybierz dostawcę'
                            isSearchable={true}
                            options={props.nozzleProviders}
                            isDisabled={props.disableNozzle || props.nozzle === -1}
                        />
                    </FormItem>
                    <FormItem flexGrow={2}>
                        <Form.Control
                            placeholder='Wprowadź opis'
                            type='text'
                            value={props.nozzleDescription}
                            disabled={props.disableNozzle}
                            onChange={props.handleNozzleDescriptionChange}
                        />
                    </FormItem>
                </FieldsRow>
                <FieldsRow>
                    <FormItem>
                        <Select
                            value={props.lamp}
                            onChange={props.handleLampChange}
                            placeholder='Wybierz lampę'
                            isSearchable={true}
                            options={products
                                .filter(product => product.category.id === 'LAMP')
                                .map(product => ({
                                    value: product.id,
                                    label: product.name,
                                }))}
                            isDisabled={props.disableLamp}
                        />
                    </FormItem>
                    <FormItem>
                        <Select
                            value={props.lampProvider}
                            onChange={props.handleLampProviderChange}
                            placeholder='Wybierz dostawcę'
                            isSearchable={true}
                            options={props.lampProviders}
                            isDisabled={props.disableLamp || props.lamp === -1}
                        />
                    </FormItem>
                    <FormItem flexGrow={2}>
                        <Form.Control
                            placeholder='Wprowadź opis'
                            type='text'
                            value={props.lampDescription}
                            disabled={props.disableLamp}
                            onChange={props.handleLampDescriptionChange}
                        />
                    </FormItem>
                </FieldsRow>
                <FieldsRow>
                    <FormItem>
                        <Select
                            value={props.skimmer}
                            onChange={props.handleSkimmerChange}
                            placeholder='Wybierz skimmer'
                            isSearchable={true}
                            options={products
                                .filter(product => product.category.id === 'SKIMMER')
                                .map(product => ({
                                    value: product.id,
                                    label: product.name,
                                }))}
                            isDisabled={props.disableSkimmer}
                        />
                    </FormItem>
                    <FormItem>
                        <Select
                            value={props.skimmerProvider}
                            onChange={props.handleSkimmerProviderChange}
                            placeholder='Wybierz dostawcę'
                            isSearchable={true}
                            options={props.skimmerProviders}
                            isDisabled={props.disableSkimmer || props.skimmer === -1}
                        />
                    </FormItem>
                    <FormItem flexGrow={2}>
                        <Form.Control
                            placeholder='Wprowadź opis'
                            type='text'
                            value={props.skimmerDescription}
                            onChange={props.handleSkimmerDescriptionChange}
                            disabled={props.disableSkimmer}
                        />
                    </FormItem>
                </FieldsRow>
                <FieldsRow>
                    <FormItem>
                        <Select
                            value={props.counterflow}
                            onChange={props.handleCounterflowChange}
                            placeholder='Wybierz przeciwprąd'
                            isSearchable={true}
                            options={products
                                .filter(product => product.category.id === 'COUNTERFLOW')
                                .map(product => ({
                                    value: product.id,
                                    label: product.name,
                                }))}
                            isDisabled={props.disableCounterflow}
                        />
                    </FormItem>
                    <FormItem>
                        <Select
                            value={props.counterflowProvider}
                            onChange={props.handleCounterflowProviderChange}
                            placeholder='Wybierz dostawcę'
                            isSearchable={true}
                            options={props.counterflowProviders}
                            isDisabled={props.disableCounterflow || props.counterflow === -1}
                        />
                    </FormItem>
                    <FormItem flexGrow={2}>
                        <Form.Control
                            placeholder='Wprowadź opis'
                            type='text'
                            value={props.counterflowDescription}
                            disabled={props.disableCounterflow}
                            onChange={props.handleCounterflowDescriptionChange}
                        />
                    </FormItem>
                </FieldsRow>
                <FieldsRow>
                    <FormItem>
                        <Select
                            value={props.outflow}
                            onChange={props.handleOutflowChange}
                            placeholder='Wybierz odpływ denny'
                            isSearchable={true}
                            options={products
                                .filter(product => product.category.id === 'OUTFLOW')
                                .map(product => ({
                                    value: product.id,
                                    label: product.name,
                                }))}
                            isDisabled={props.disableOutflow}
                        />
                    </FormItem>
                    <FormItem>
                        <Select
                            value={props.outflowProvider}
                            onChange={props.handleOutflowProviderChange}
                            placeholder='Wybierz dostawcę'
                            isSearchable={true}
                            options={props.outflowProviders}
                            isDisabled={props.disableOutflow || props.outflow === -1}
                        />
                    </FormItem>
                    <FormItem flexGrow={2}>
                        <Form.Control
                            placeholder='Wprowadź opis'
                            type='text'
                            value={props.outflowDescription}
                            disabled={props.disableOutflow}
                            onChange={props.handleOutflowDescriptionChange}
                        />
                    </FormItem>
                </FieldsRow>
                <VerticalDivider />
                <OtherWrapper>
                    {addons.map((addon, index) => (
                        <FieldsRow key={index}>
                            <FormItem>
                                <Select
                                    value={addon.product}
                                    onChange={e => props.handleAddonProductChange(index, e)}
                                    placeholder='Wybierz inne'
                                    isSearchable={true}
                                    isDisabled={disableEditing}
                                    options={products
                                        .filter(product => product.category.id === 'POOL_OTHER')
                                        .map(product => ({
                                            value: product.id,
                                            label: product.name,
                                        }))}
                                />
                            </FormItem>
                            <FormItem>
                                <Select
                                    value={addon.provider}
                                    onChange={e => props.handleAddonProviderChange(index, e)}
                                    placeholder='Wybierz dostawcę'
                                    isSearchable={true}
                                    options={getProvidersFromProduct(addon)}
                                    isDisabled={addon.product === -1 || disableEditing}
                                />
                            </FormItem>
                            <FormItem flexGrow={2}>
                                <Form.Control
                                    placeholder='Wprowadź opis'
                                    type='text'
                                    value={addon.description}
                                    disabled={disableEditing}
                                    onChange={e => props.handleAddonDescriptionChange(index, e)}
                                />
                            </FormItem>
                        </FieldsRow>
                    ))}
                </OtherWrapper>
            </AddonsWrapper>
            {productId > -1 && (
                <PrintWrapper>
                    <PrintableWrapper ref={printableComponent}>
                        <PrintablePoolSchema pool={normalizePool()} />
                    </PrintableWrapper>
                </PrintWrapper>
            )}
        </ContentWrapper>
    );
};

export default PoolBuilder;
