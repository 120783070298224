import React from 'react';
import Routing from './Components/routing/routing';
import { NotificationContainer } from 'react-notifications';
import { Helmet } from 'react-helmet';

const App = () => {
    return (
        <>
            <NotificationContainer />
            <Helmet>
                <title>EuroBoard</title>
            </Helmet>
            <Routing />
        </>
    );
};

export default App;
