import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import LoginForm from '../../Components/LoginForm.js';
import { tryAutoLogin, totalFuckedUpFunction } from '../../Redux/Actions/AuthActions';
import Loader from '../../Components/UI/Loader/loader';
import { LoaderWrapper } from './login.styles';
import { authConstants } from '../../Constants';
import { getViewFromPath } from './login.utils';
import { getClients } from '../../Redux/Actions/client';
import { getLicenseTypes } from '../../Redux/Actions/LicenseTypesActions';
import { getRoles } from '../../Redux/Actions/RolesActions';
import { getProductCategories, getProducts } from '../../Redux/Actions/product';
import { getProviders } from '../../Redux/Actions/provider';
import { getTrailers } from '../../Redux/Actions/TrailersActions';
import { getTrucks } from '../../Redux/Actions/TrucksActions';
import { getUsers } from '../../Redux/Actions/UsersActions';
import { getWorkers } from '../../Redux/Actions/WorkersActions';
import { prepareUrl } from '../../Components/routing/routing-utils';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { didTryAutoLogin, initLoading } = useSelector(store => store.auth);

    useEffect(() => {
        dispatch(tryAutoLogin(redirectBackToSource));
    }, []);

    const initLoadForAdmin = async () => {
        await Promise.all([
            dispatch(getClients()),
            dispatch(getClients(true)),
            dispatch(getLicenseTypes()),
            dispatch(getProductCategories()),
            dispatch(getProducts()),
            dispatch(getProducts(true)),
            dispatch(getProviders()),
            dispatch(getProviders(true)),
            dispatch(getRoles()),
            dispatch(getTrailers()),
            dispatch(getTrucks()),
            dispatch(getUsers()),
            dispatch(getWorkers()),
        ]);
    };

    const initLoadForUser = async () => {
        await Promise.all([
            dispatch(getClients()),
            dispatch(getClients(true)),
            dispatch(getLicenseTypes()),
            dispatch(getProductCategories()),
            dispatch(getProducts()),
            dispatch(getProducts(true)),
            dispatch(getProviders()),
            dispatch(getProviders(true)),
            dispatch(getRoles()),
            dispatch(getTrailers()),
            dispatch(getTrucks()),
            dispatch(getWorkers()),
        ]);
    };

    const initLoadForPlanningAndProduction = async () => {
        await Promise.all([dispatch(getProducts()), dispatch(getProducts(true))]);
    };

    const getRedirectUrl = roles =>
        roles.map(x => x.name).includes('ROLE_TEMP_PASSWORD')
            ? { pathname: prepareUrl('/changePassword') }
            : location.state && location.state.from
            ? { pathname: `${location.state.from.pathname}` }
            : { pathname: `/` };

    const redirectBackToSource = async userRoles => {
        const roles = userRoles.map(role => role.name);

        if (roles.includes('ROLE_ADMIN')) {
            await initLoadForAdmin();
        } else if (roles.includes('ROLE_USER')) {
            await initLoadForUser();
        } else if (roles.includes('ROLE_PRODUCTION')) {
            await initLoadForPlanningAndProduction();
        }

        const redirectUrl = getRedirectUrl(userRoles);

        dispatch({ type: authConstants.CHANGE_VIEW, payload: getViewFromPath(redirectUrl.pathname) });

        history.replace(redirectUrl);
    };

    const handleSubmit = async (username, password, turnOffSpinner) =>
        dispatch(totalFuckedUpFunction(username, password, redirectBackToSource, turnOffSpinner));

    return didTryAutoLogin && !initLoading ? (
        <LoginForm onSubmit={handleSubmit} />
    ) : (
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    );
};

export default Login;
