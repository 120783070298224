import { availableResourcesConstants } from '../../Constants';

const initialState = {
    workers: [],
    trucks: [],
    trailers: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case availableResourcesConstants.GET_WORKERS:
            return {
                ...state,
                workers: action.payload,
            };
        case availableResourcesConstants.GET_TRUCKS:
            return {
                ...state,
                trucks: action.payload,
            };
        case availableResourcesConstants.GET_TRAILERS:
            return {
                ...state,
                trailers: action.payload,
            };
        default:
            return state;
    }
}
