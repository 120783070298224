import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import RequiredFieldIndicator from '../RequiredFieldIndicator';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class TrailersPanel extends Component {
    render() {
        const validation = Yup.object().shape({
            surname: Yup.string().required('To pole jest wymagane'),
            name: Yup.string().required('To pole jest wymagane'),
            login: Yup.string()
                .required('To pole jest wymagane')
                .min(6, 'Login jest za krótki')
                .max(16, 'Login jest za długi')
                .matches(/^[a-zA-Z0-9]*$/, 'Login zawiera niedozwolone znaki'),
            password: this.props.isRowClicked
                ? null
                : Yup.string()
                      .required('To pole jest wymagane')
                      .min(8, 'Hasło jest za krótkie')
                      .max(30, 'Hasło jest za długie')
                      .matches(/(?=.*[a-z])/, 'Hasło musi zawierać co najmniej jedną małą literę')
                      .matches(/(?=.*[A-Z])/, 'Hasło musi zawierać co najmniej jedną dużą literę')
                      .matches(/(?=.*[0-9])/, 'Hasło musi zawierać co najmniej jedną liczbę')
                      .matches(/(?=.[!@#$%^&])/, 'Hasło musi zawierać co najmniej jeden znak specjalny (!,@,#,$,%,^,&)'),
            repeatPassword: this.props.isRowClicked
                ? null
                : Yup.string()
                      .required('To pole jest wymagane')
                      .oneOf([Yup.ref('password'), null], 'Wprowadzone hasła są różne'),
            role: Yup.mixed().notOneOf([-1], 'To pole jest wymagane'),
        });

        return (
            <div className='panel-div'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        surname: this.props.surname,
                        name: this.props.name,
                        login: this.props.login,
                        password: this.props.password,
                        repeatPassword: this.props.repeatPassword,
                        role: this.props.role,
                    }}
                    validationSchema={validation}
                    onSubmit={(fields, { resetForm }) => {
                        if (this.props.isRowClicked) {
                            this.props.handleEditClick(fields);
                        } else {
                            this.props.handleSubmit(fields);
                        }
                        resetForm();
                    }}
                    render={({ errors, status, touched, fields, values, setFieldValue }) => (
                        <Form>
                            <div className='form-group'>
                                <label htmlFor='surname'>Nazwisko</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='surname'
                                    placeholder='Wprowadź nazwisko'
                                    type='text'
                                    className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='surname' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='name'>Imię</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='name'
                                    placeholder='Wprowadź imię'
                                    type='text'
                                    className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='name' component='div' className='invalid-feedback' />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='login'>Login</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='login'
                                    placeholder='Wprowadź login'
                                    type='text'
                                    className={'form-control' + (errors.login && touched.login ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='login' component='div' className='invalid-feedback' />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='password'>Hasło</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='password'
                                    placeholder='Wprowadź hasło'
                                    type='password'
                                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='password' component='div' className='invalid-feedback' />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='repeatPassword'>Powtórz hasło</label>
                                <RequiredFieldIndicator />
                                <Field
                                    name='repeatPassword'
                                    placeholder='Powtórz hasło'
                                    type='password'
                                    className={'form-control' + (errors.repeatPassword && touched.repeatPassword ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name='repeatPassword' component='div' className='invalid-feedback' />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='role'>Uprawnienia</label>
                                <RequiredFieldIndicator />

                                <Select
                                    name='role'
                                    value={values['role']}
                                    onChange={e => setFieldValue('role', e)}
                                    placeholder='Wybierz uprawnienia'
                                    isSearchable={true}
                                    styles={
                                        errors.role && touched.role
                                            ? {
                                                  control: styles => ({
                                                      ...styles,
                                                      borderColor: 'red',
                                                      '&:hover': {
                                                          borderColor: 'red',
                                                      },
                                                  }),
                                              }
                                            : {}
                                    }
                                    options={this.props.roles.map(role => ({
                                        value: role.id,
                                        label: role.label,
                                    }))}
                                />

                                <ErrorMessage name='role' component='div' style={{ color: 'red', fontSize: '80%' }} />
                            </div>

                            {!this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-success' style={{ marginRight: '6%', width: '47%' }}>
                                        Dodaj
                                    </button>
                                    <button type='reset' className='btn btn-secondary' style={{ width: '47%' }}>
                                        Wyczyść
                                    </button>
                                </>
                            )}
                            {this.props.isRowClicked && (
                                <>
                                    <button type='submit' className='btn btn-primary' style={{ marginRight: '6%', width: '47%' }}>
                                        Zapisz
                                    </button>
                                    <Button onClick={this.props.handleCancelClick} variant='secondary' style={{ width: '47%' }}>
                                        Anuluj
                                    </Button>
                                    {/* <Button
                    onClick={this.props.handleDeleteClick}
                    variant="danger"
                    style={{ width: "30%" }}
                    disabled={!this.props.isRowClicked}
                  >
                    Usuń
                  </Button> */}
                                </>
                            )}
                        </Form>
                    )}
                />
            </div>
        );
    }
}

export default TrailersPanel;
