const prefix = process.env.REACT_APP_API_PREFIX;
const appPrefix = process.env.REACT_APP_APP_PREFIX;

const available = '/available';
const calendar = '/when-available';

const workers = prefix + '/worker';
const trailers = prefix + '/trailer';
const trucks = prefix + '/truck';
const reservations = prefix + '/reservation';
const users = prefix + '/user';
const providers = prefix + '/provider';
const client = prefix + '/client';
const product = prefix + '/productSchema';
const licenseTypes = prefix + '/license';
const roles = prefix + '/role';
const orders = prefix + '/order';
const orderProducts = prefix + '/product';
const processEndpoint = prefix + '/process';
const notification = prefix + '/notification';

const availableWorkers = workers + available;
const availableTrailers = trailers + available;
const availableTrucks = trucks + available;

const calendarWorkers = workers + calendar;
const calendarTrucks = trucks + calendar;
const calendarTrailers = trailers + calendar;

const productAmount = orderProducts + '/amount';
const productCategory = product + '/category';
const productSchema = product + '/poolSchema';
const productPackage = orderProducts + '/package';
const productAvailable = orderProducts + '/available';
const productProductionDate = orderProducts + '/amount/production-term';

const processStart = processEndpoint + '/start';
const processPackages = processEndpoint + '/packages';
const processWithdraw = processEndpoint + '/withdraw';
const processPlanning = processEndpoint + '/planning';
const processReservation = processEndpoint + '/reservation';
const processStatus = processEndpoint + '/status';

const notificationReservation = notification + '/reservation';
const notificationProduction = notification + '/production';
const notificationDeactivate = notification + '/deactivate';

const toggleProvider = providers + '/toggle-active';
const toggleClient = client + '/toggle-active';
const toggleProduct = product + '/toggle-active';
const togglePrinted = orderProducts + '/amount/toggle-printed';

const download = reservations + '/download';

const csrf = prefix + '/csrf';
const login = prefix + '/process-login';
const logout = prefix + '/logout';
const changePassword = users + '/change-password';
const autoLogin = users + '/whoami';

const socket = prefix + '/websocket/register';

const report = prefix + '/report';
const productReport = report + '/product';

export const API = {
    prefix,
    appPrefix,
    trucks,
    trailers,
    licenseTypes,
    workers,
    reservations,
    users,
    roles,
    orders,
    availableWorkers,
    availableTrailers,
    availableTrucks,
    csrf,
    login,
    logout,
    download,
    changePassword,
    calendarWorkers,
    calendarTrucks,
    calendarTrailers,
    providers,
    client,
    product,
    productCategory,
    productSchema,
    orderProducts,
    autoLogin,
    productPackage,
    productAvailable,
    processStart,
    process: processEndpoint,
    processPackages,
    processWithdraw,
    notificationReservation,
    processPlanning,
    processReservation,
    processStatus,
    notificationProduction,
    socket,
    notificationDeactivate,
    productProductionDate,
    toggleClient,
    toggleProduct,
    toggleProvider,
    togglePrinted,
    productAmount,
    productReport,
};
