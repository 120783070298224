import styled from 'styled-components';
import { toRem } from '../../Styles/theme';

export const PrintWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(20)};

    margin: ${toRem(30)};
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;

    font-weight: bold;
    font-size: ${toRem(22)};
`;

export const Columns = styled.div`
    display: flex;
    gap: ${toRem(10)};

    position: absolute;
    top: ${toRem(300)};
    left: ${toRem(30)};

    width: calc(100% - ${toRem(60)});
`;

export const PoolMetadata = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};
`;

export const Legend = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${toRem(10)};

    width: 100%;
`;

export const NoTech = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
`;

export const MetadataRow = styled.div`
    font-size: ${toRem(20)};
`;
