import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FieldsRow, FormItem } from '../../../Components/OrderConfigurator/ProductConfigurator/styles';
import RequiredFieldIndicator from '../../../Components/RequiredFieldIndicator';
import { REPORT_OPTIONS } from './report-panel.constants';
import { FormWrapper } from './report-panel.styles';
import { getForm } from './report-panel.utils';

const ReportPanel = ({ getReportPageForNewParams, getReportPageCustomParams, reportType, setReportType, setParams }) => {
    const reportData = useSelector(store => store.report.reportData);

    const Form = getForm(reportType.value);

    return (
        <FormWrapper withTable={reportData}>
            <FieldsRow>
                <FormItem>
                    <label>Typ raportu</label>
                    <RequiredFieldIndicator />
                    <Select
                        name='report-type'
                        value={reportType}
                        onChange={setReportType}
                        placeholder='Wybierz typ raportu'
                        isSearchable={true}
                        options={REPORT_OPTIONS}
                    />
                </FormItem>
            </FieldsRow>
            <Form
                getReportPageForNewParams={getReportPageForNewParams}
                getReportPageCustomParams={getReportPageCustomParams}
                setParams={setParams}
            />
        </FormWrapper>
    );
};

export default ReportPanel;
