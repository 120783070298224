import React, { useState, useEffect } from 'react';
import './CalendarPanel.css';
import Select from 'react-select';
import { API } from '../../Constants';
import { Button } from 'react-bootstrap';

const CalendarPanel = props => {
    const [workers, setWorkers] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [trailers, setTrailers] = useState([]);

    const fetchWorkers = async () => {
        const result = await fetch(API.workers);
        const resultJson = await result.json();
        setWorkers(resultJson);
    };

    const fetchTrucks = async () => {
        const result = await fetch(API.trucks);
        const resultJson = await result.json();
        setTrucks(resultJson);
    };

    const fetchTrailers = async () => {
        const result = await fetch(API.trailers);
        const resultJson = await result.json();
        setTrailers(resultJson);
    };

    const fetchResources = () => {
        fetchWorkers();
        fetchTrucks();
        fetchTrailers();
    };

    useEffect(() => {
        fetchResources();
    }, []);

    return (
        <div className='container'>
            <div className='selectionGroup'>
                Pracownicy
                <Select
                    isSearchable={true}
                    value={props.selectedWorkers}
                    isMulti={true}
                    onChange={e => {
                        props.handlePickWorkers(e);
                    }}
                    placeholder='Wybierz pracowników'
                    noOptionsMessage={() => 'Brak pracowników'}
                    options={workers.map(worker => ({
                        value: worker.id,
                        label: `${worker.name} ${worker.surname}`,
                    }))}
                />
            </div>
            <div className='selectionGroup'>
                Pojazd
                <Select
                    isSearchable={true}
                    value={props.selectedTruck}
                    onChange={e => {
                        props.handlePickTruck(e);
                    }}
                    placeholder='Wybierz pojazd'
                    noOptionsMessage={() => 'Brak pojazdów'}
                    options={trucks.map(truck => ({
                        value: truck.id,
                        label: `${truck.registrationNumber} - ${truck.model}`,
                    }))}
                />
            </div>
            <div className='selectionGroup'>
                Przyczepy
                <Select
                    isSearchable={true}
                    value={props.selectedTrailer}
                    onChange={e => {
                        props.handlePickTrailer(e);
                    }}
                    placeholder='Wybierz przyczepę'
                    noOptionsMessage={() => 'Brak przyczep'}
                    options={trailers.map(trailer => ({
                        value: trailer.id,
                        label: `${trailer.registrationNumber} - ${trailer.description}`,
                    }))}
                />
            </div>
            <div className='button-container'>
                <Button className='button' style={{ marginRight: '20px' }} onClick={() => props.handleSearchClick(props.currentMonth)}>
                    Pokaż
                </Button>
                <Button variant='secondary' className='button' onClick={props.handleClearClick}>
                    Wyczyść
                </Button>
            </div>
        </div>
    );
};

export default CalendarPanel;
