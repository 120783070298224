import { haveRole } from '../../Utils/commons';
import { availableViews } from '../../Redux/Reducers/AuthReducer';
import { API } from '../../Constants';

export const getRootPath = (user, view) => {
    if (haveRole(user, 'ROLE_ADMIN')) {
        switch (view) {
            case availableViews.MANAGEMENT:
                return 'tasks';
            case availableViews.PRODUCTION:
                return 'planning';
        }
    }

    if (haveRole(user, 'ROLE_USER')) {
        return 'tasks';
    }

    if (haveRole(user, 'ROLE_PRODUCTION')) {
        return 'planning';
    }

    if (haveRole(user, 'ROLE_TOOLING')) {
        return 'tooling';
    }

    if (haveRole(user, 'ROLE_TECH')) {
        return 'tech';
    }

    if (haveRole(user, 'ROLE_PACKING')) {
        return 'packing';
    }
};

export const prepareUrl = url => `${API.appPrefix}${url}`;
