import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavBar = styled.div`
    position: sticky;

    display: flex;
    align-items: center;
    justify-content: space-between;

    top: 0;

    margin: 0;
    padding: 0;

    background-image: linear-gradient(to bottom, #007bff, #007bff, #007bffd8);
    box-shadow: 0 12px 12px -6px rgba(0, 0, 0, 0.747);
    overflow: hidden;
    z-index: 999;
`;

const navBarItemStyles = css`
    padding: 14px 16px;

    color: white;
    cursor: pointer;
    font-size: 18px;

    -o-transition: 1s;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;

    &:hover {
        background-color: #0159b8;
        color: white;
        text-decoration: none;
        text-shadow: 0 0 10px #fff;
    }
`;

export const StyledNavLink = styled(NavLink)`
    ${navBarItemStyles};

    float: left;
    text-decoration: none;
`;

export const LogoutButton = styled.div`
    ${navBarItemStyles};

    float: right;
`;

export const LoggedUserInfo = styled.div`
    float: right;

    padding: 14px 16px;

    color: white;
    font-size: 18px;
`;

export const DropdownItem = styled.div`
    ${navBarItemStyles};
    text-align: center;

    ${props => props.active && 'background-color: #0159b8'};
`;

export const DropdownContent = styled.div`
    display: none;
    position: fixed;

    width: 130px;

    background-color: #007bff;
    box-shadow: 0 12px 12px -6px rgba(0, 0, 0, 0.747);
    z-index: 1;
`;

export const Dropdown = styled.div`
    width: 130px;
    float: right;

    overflow: hidden;

    &:hover ${DropdownContent} {
        display: block;
    }
`;

export const UtilsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
