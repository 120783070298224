import React from 'react';
import { IconButton, ProductHeader } from '../../planning/planning.styles';
import CheckIcon from '@material-ui/icons/Check';
import { ProductItem, ProductDetails } from './tooling-product.styles';
import { useDispatch, useSelector } from 'react-redux';
import { pickProduct, toggleProductPrint } from '../../../../Redux/Actions/package.actions';
import { getClientFromOrder } from '../../../../Utils/commons';
import { Form } from 'react-bootstrap';

const ToolingProduct = ({ productAmount, activeTab, disabled, finishedClick }) => {
    const dispatch = useDispatch();

    const { highlightedProductId } = useSelector(store => store.packages);
    const id = useSelector(store => store.packages.pickedProductId);

    const dispatchPickProduct = id => {
        dispatch(pickProduct(id));
    };

    const handleOnCheckboxClick = (e, productAmount) => {
        e.stopPropagation();
        dispatch(toggleProductPrint(productAmount.id, productAmount.packageId));
    };

    return (
        <ProductItem
            onClick={() => dispatchPickProduct(productAmount.id)}
            picked={id === productAmount.id}
            highlighted={productAmount.id === highlightedProductId}
            id={`product-item-${productAmount.id}`}
        >
            <ProductHeader>
                <ProductDetails>
                    {`${productAmount.product.productSchema.name} ${
                        productAmount.product.order ? `- ${getClientFromOrder(productAmount.product.order)}` : ''
                    } ${productAmount.deadline ? `(${productAmount.deadline})` : ''}`}
                </ProductDetails>
                {activeTab === 0 ? (
                    <IconButton variant='success' onClick={() => finishedClick(productAmount.id)} {...{ disabled }}>
                        <CheckIcon />
                    </IconButton>
                ) : (
                    <Form.Check
                        inline
                        type='checkbox'
                        onClick={e => handleOnCheckboxClick(e, productAmount)}
                        checked={productAmount.printed}
                    />
                )}
            </ProductHeader>
            {productAmount.product.description ? `${productAmount.product.description} ` : ''}
        </ProductItem>
    );
};

export default ToolingProduct;
